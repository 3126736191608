// import React from 'react'
// import { formatToCEP, formatToCNPJ, formatToCPF } from 'brazilian-values'

export const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'mother_name',
      label: 'Nome da Mãe',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'father_name',
      label: 'Nome do pai',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'gender',
      label: 'Sexo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'birth_date',
      label: 'Data de nascimento',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'occupation',
      label: 'Ocupação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'person_id',
      label: 'Identificação Pessoal',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'individual_number',
      label: 'Número individual',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'nacionality',
      label: 'Nacionalidade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'naturality',
      label: 'Naturalidade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'id_piece',
      label: 'Identificação da Peça',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'type_piece',
      label: 'Tipo da Peça',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'piece_number',
      label: 'Número da peça',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'process_number',
      label: 'Número do processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'shipping_date',
      label: 'Data de Envio',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'shipping_agency',
      label: 'Agência de Envio',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'magistrate',
      label: 'Juiz',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'valid_date',
      label: 'Data de Validade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'kind_arrest',
      label: 'Tipo de prisão',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'arrest_time',
      label: 'Tempo de prisão',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'arrest_regime',
      label: 'Regime da prisão',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'recapture',
      label: 'Recaptura',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'liberation_granted',
      label: 'Garantia de Liberdade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'previous_piece_number',
      label: 'Data da peça anterior',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'PDF',
      label: 'document_url',
      options: {
        filter: true,
        sort: false,
      },
    },
  ]


 export const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
   responsive: 'scroll',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}
