import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { formatToCEP } from 'brazilian-values'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  selectObjServicesCadastralExtension,
} from 'redux/servicesRequested/selectors'

import DataTable from 'components/DataTable'

import { TitleBold } from 'components/styledComponents'
import TimeCounter from 'components/TimeCounter'

import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds, generateObjService } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService, translateServiceName } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'

import {
  options,
  columnsCompanyJobDetails,
  columnsJobsHistory,
  columnsPartners,
  columnsPayments,
  columnsRais,
  columnsRelatives,
  columnsSocial,
} from './configDataTable'

import styles from './styles'

const ExpansionPanelCadastralExtension = ({ blockId }) => {
  const classes = styles()
  const theme = useTheme()

  const objServicesRequested = useSelector(selectObjServicesCadastralExtension)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const customBlockServices = [
    generateObjService('cnpj_infos_federal_revenue'),
    generateObjService('membership_board'),
  ]

  const block = findTemplateBlock(template.blocks, blockId)

  let blockServices = block.services

  blockServices = [
    ...blockServices,
    ..._.reject(customBlockServices, (custom) => _.find(blockServices, { service_id: custom.service_id })),
  ]

  blockServices = _
    .chain(blockServices)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(
    servicesList.data,
    (val) => _.map(customBlockServices, 'service_id')
              .concat(getDefaultBlockServicesIds(blockId))
              .includes(val.service_id)
  )

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  // reject cnpj revenue that not called
  servicesList.data = _.reject(
    servicesList.data,
    (service) => _.find(customBlockServices, { service_id: service.service_id }) && !_.has(service, 'status')
  )

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const serviceSearchInfos = _.find(objServicesRequested, { service_id: 'search_infos_person_complete_v2' })
                          || _.find(objServicesRequested, { service_id: 'search_infos_person_complete' })
  const person = _.get(serviceSearchInfos, 'data.person')
  const objPis = _.find(objServicesRequested, { service_id: 'pis_number' })

  const renderElectoralSituation = () => {
    const service = _.find(servicesList.data, { service_id: 'electoralsituation_by_name' })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (_.get(service, 'data.consists') === true) {
      return <strong>Com Pendências</strong>
    }
    if (!_.isBoolean(_.get(service, 'data.consists'))) {
      return ''
    }

    return <strong>Sem Pendências</strong>
  }

  const renderIrpf = () => {
    const irpfs = _.filter(servicesList.data, { service_id: 'irpf_refund_cpf' })

    return _.map(irpfs, (ir, index) => {
      const elem = (irpf) => {
        if (_.isEmpty(_.get(irpf, 'objId'))) {
          return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
        }

        if (_.get(irpf, 'status') === 'NEED_INPUTS') {
          return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
        }

        if (_.get(irpf, 'status') === 'PROCESSING') {
          if (_.get(irpf, 'slow_processing')) {
            const msgInfos = _.get(irpf, 'data.msg_infos')
            if (!_.isEmpty(msgInfos)) {
              return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
            }
          }

          return <TimeCounter responseTime={_.get(irpf, 'response_time')} />
        }

        const data = _.get(irpf, 'data')

        if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(irpf, 'status'))) {
          return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
        }

        const msgErrors = _.get(data, 'msg_errors')
        if (!_.isEmpty(msgErrors)) {
          return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
        }
        const msgInfos = _.get(data, 'msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
        }

        if (!_.isEmpty(_.get(irpf, 'data')) && !_.isEmpty(_.get(irpf, 'data.info'))) {
          return (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <span>Informações: </span>
                <strong>{irpf.data.info}</strong>
              </li>
            </ul>
          )
        }
        if (!_.isEmpty(_.get(irpf, 'data'))) {
          return (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <span>Agencia: </span>
                <strong>{`${irpf.data.agency} ${irpf.data.bank}`}</strong>
              </li>
              <li>
                <span>Lote: </span>
                <strong>{irpf.data.lot}</strong>
              </li>
            </ul>
          )
        }

        return ''
      }

      const year = _.get(ir, 'inputs.year.value')
      return (
        <div key={index} style={{ marginBottom: 5 }}>
          {
            year ? (
              <Fragment>
                <span>Ano: </span>
                <strong>{year}</strong>
              </Fragment>
            ) : null
          }
          {elem(ir)}
        </div>
      )
    })
  }

  // eslint-disable-next-line no-unused-vars
  const renderInsurance = () => {
    const service = _.find(servicesList.data, { service_id: 'unemployment_insurance_by_pis' })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (!_.isEmpty(objPis) && _.get(objPis, 'status') !== 'PROCESSED') {
        return <span style={{ color: theme.palette.primary.grayLight2 }}>buscando dados...</span>
      }

      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (!_.isEmpty(_.get(service, 'data'))) {
      return (
        <>
          {service.data.situation && (
            <p>
              <strong style={{ marginRight: '20px' }}>Situação:</strong>
              <span>{service.data.situation}</span>
            </p>
          )}
          {service.data.service_time_in_months && (
            <p>
              <strong style={{ marginRight: '20px' }}>Tempo decorrido:</strong>
              {service.data.service_time_in_months && <span>{`${service.data.service_time_in_months} meses`}</span>}
            </p>
          )}
          {service.data.motive && (
            <p>
              <strong style={{ marginRight: '20px' }}>Motivo:</strong>
              <span>{service.data.motive}</span>
            </p>
          )}
          {service.data.installments && service.data.installments.length > 0 && (
            <>
              <h4 style={{ marginTop: 15 }}>Pagamentos das parcelas</h4>
              <DataTable
                data={service.data.installments}
                columns={columnsPayments}
                options={options}
              />
            </>
          )}
        </>
      )
    }

    return ''
  }

  const renderRais = () => {
    const service = _.find(servicesList.data, { service_id: 'work_info_rais_base' })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (!_.isEmpty(objPis) && _.get(objPis, 'status') !== 'PROCESSED') {
        return <span style={{ color: theme.palette.primary.grayLight2 }}>buscando dados...</span>
      }

      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (_.isArray(_.get(service, 'data.companies_infos'))) {
      if (_.isEmpty(_.get(service, 'data.companies_infos'))) {
        return 'Não existem informações de empresas no RAIS.'
      }

      return (
        <>
          <h4 style={{ marginTop: 15 }}>Empresas</h4>
          <DataTable
            data={service.data.companies_infos}
            columns={columnsRais}
            options={options}
          />
        </>
      )
    }

    return ''
  }

  const renderSocialAssistance = () => {
    const service = _.find(servicesList.data, { service_id: 'social_assistance_person' })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (_.isArray(_.get(service, 'data.social_assistances'))) {
      if (_.isEmpty(_.get(service, 'data.social_assistances'))) {
        return 'Não existem informações de Benefícios Sociais.'
      }
      return (
        <DataTable
          data={service.data.social_assistances}
          columns={columnsSocial}
          options={options}
        />
      )
    }

    return ''
  }

  const renderService = (serviceId) => {
    const service = _.find(servicesList.data, { service_id: serviceId })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (_.get(data, 'consists')) {
      return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
    }

    return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
  }

  const renderRelatives = () => {
    let relatives = _.get(person, 'relatives')
    if (_.isEmpty(relatives)) return null

    relatives = _
    .chain(relatives)
    .filter((item) => !_.isEmpty(item.name))
    .map(item => ({ name: item.name, cpf: item.cpf, birth_date: item.birth_date }))
    .value()

    if (_.isEmpty(relatives)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Possiveis Parentes</TitleBold>
        <DataTable
          data={relatives}
          columns={columnsRelatives}
          options={options}
        />
      </div>
    )
  }

  const renderPartners = () => {
    const partners = _.get(person, 'partner_details')
    if (_.isEmpty(partners)) return null

    let cnpjsInfos = _.filter(servicesList.data, { service_id: 'membership_board' })

    // backward compatibility
    if (_.isEmpty(cnpjsInfos)) {
      cnpjsInfos = _.filter(servicesList.data, { service_id: 'cnpj_infos_federal_revenue' })
    }

    cnpjsInfos = _.reject(cnpjsInfos, { ignore: true })

    if (_.isEmpty(cnpjsInfos)) return null

    const cnpjsInfosData = _
    .chain(cnpjsInfos)
    .filter((cnpjInfo) => cnpjInfo.status === 'PROCESSED')
    .map(cnpjInfo => {
      const cnpjInfoData = _.get(cnpjInfo, 'data') || {}
      const zipcode = formatToCEP(_.get(cnpjInfoData, 'address.zipcode') || '')

      const newItem = {
        address: _.chain(cnpjInfoData).get('address').pick(['public_place', 'number', 'complement', 'neighborhood']).values().push(zipcode).without(null, undefined, '').join(', ').value(),
        city: _.chain(cnpjInfoData).get('address').pick(['city', 'uf']).values().without(null, undefined, '').join('/').value(),
        cnpj: _.chain(cnpjInfoData).get('cnpj').toLower().startCase().value(),
        name: _.chain(cnpjInfoData).get('name').toLower().startCase().value(),
        type: _.chain(cnpjInfoData).get('legal_nature.description').toLower().startCase().value(),
      }

      if (cnpjInfo.status === 'PROCESSING') {
        newItem.cadastral_situation = cnpjInfo.response_time
        newItem.members = cnpjInfo.response_time
      } else if (cnpjInfo.status === 'PROCESSED') {
        newItem.cadastral_situation = _.get(cnpjInfo, 'data.cadastral_situation')
        newItem.members = _.get(cnpjInfo, 'data.membership_board')
      }

      return newItem
    })
    .value()

    // TODO: remover quando o person tiver fazendo o filtro de empresas
    const processingQty = _.filter(cnpjsInfos, { status: 'PROCESSING'}).length

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Sociedades</TitleBold>
        {
          processingQty ? (
            <div className={classes.partnersMsgProcessingContainer}>
              <strong className={classes.partnersMsgProcessing}>
                Verificando sociedades...
              </strong>
            </div>
          ) : null
        }
        {
          !_.isEmpty(cnpjsInfosData) && (
            <DataTable
              data={cnpjsInfosData}
              columns={columnsPartners}
              options={options}
            />
          )
        }
      </div>
    )
  }

  // eslint-disable-next-line no-unused-vars
  const renderCompanies = () => {
    let companies = _.get(person, 'companies_job_details')
    if (_.isEmpty(companies)) return null

    companies = _
    .chain(companies)
    .filter((item) => !_.isEmpty(item.name))
    .map(item => ({
      name: item.name,
      cnpj: item.cnpj,
      type: item.partner_type,
      address: `${_.get(item, 'addresses.0.street') || ''}, ${_.get(item, 'addresses.0.number') || ''}, ${_.get(item, 'complement') || ''}`,
      neighborhood: _.get(item, 'addresses.0.neighborhood') || '',
      city: `${_.get(item, 'addresses.0.city') || ''}, ${_.get(item, 'addresses.0.state') || ''}`,
      zipcode: _.get(item, 'addresses.0.zipcode') || '',
    }))
    .value()

    if (_.isEmpty(companies)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Empregos</TitleBold>
        <DataTable
          data={companies}
          columns={columnsCompanyJobDetails}
          options={options}
        />
      </div>
    )
  }

  const renderJobsHistory = () => {
    let jobsHistory = _.get(person, 'jobs') || _.get(person, 'jobs_history')
    if (_.isEmpty(jobsHistory)) return null

    jobsHistory = _
    .chain(jobsHistory)
    .filter((item) => !_.isEmpty(item.name))
    .value()

    if (_.isEmpty(jobsHistory)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Empregos</TitleBold>
        <DataTable
          data={jobsHistory}
          columns={columnsJobsHistory}
          options={options}
        />
      </div>
    )
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('ceaf_infos_government_base')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('ceaf_infos_government_base')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('bacen_qgi_cpf')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('bacen_qgi_cpf')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('sanctions_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('sanctions_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('eu_sanction_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('eu_sanction_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('uk_sanction_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('uk_sanction_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('fr_sanction_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('fr_sanction_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('us_nonproliferation_sanction_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('us_nonproliferation_sanction_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('red_notice_interpol')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('red_notice_interpol')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('onu_sanction_lists')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('onu_sanction_lists')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('world_bank_ineligible_sanction_list')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderService('world_bank_ineligible_sanction_list')}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>Título Eleitoral</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            { _.get(person, 'elector_title_number')
              ? <strong style={{ marginRight: '20px' }}>{_.get(person, 'elector_title_number')}</strong>
              : ''
            }
            {renderElectoralSituation()}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>Banco indicado para Restituição do IR</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderIrpf()}
          </Grid>
        </Grid>
        {/*
        <div>
          <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Seguro Desemprego</TitleBold>
          {renderInsurance()}
        </div>
        */}
        <div>
          <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Benefícios Sociais</TitleBold>
          {renderSocialAssistance()}
        </div>
        <div>
          <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>RAIS</TitleBold>
          {renderRais()}
        </div>
        {renderJobsHistory()}
        {renderPartners()}
        {renderRelatives()}
      </div>
    </ExpansionPanel>
  )
}

export default ExpansionPanelCadastralExtension
