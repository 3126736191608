export const LIST_ITEMS_CLEAR = 'LIST_ITEMS_CLEAR'

export const FETCH_LIST_ITEMS = 'FETCH_LIST_ITEMS'
export const FETCH_LIST_ITEMS_FAILED = 'FETCH_LIST_ITEMS_FAILED'
export const FETCH_LIST_ITEMS_SUCCEEDED = 'FETCH_LIST_ITEMS_SUCCEEDED'

export const FETCH_ITEM = 'FETCH_ITEM'
export const FETCH_ITEM_FAILED = 'FETCH_ITEM_FAILED'
export const FETCH_ITEM_SUCCEEDED = 'FETCH_ITEM_SUCCEEDED'

export const UPDATE_LIST_ITEMS_SERVICE_INFO_STATUS = 'UPDATE_LIST_ITEMS_SERVICE_INFO_STATUS'

export const START_BACKGROUND_FETCH_LIST_ITEMS = 'START_BACKGROUND_FETCH_LIST_ITEMS'
export const STOP_BACKGROUND_FETCH_LIST_ITEMS = 'STOP_BACKGROUND_FETCH_LIST_ITEMS'

export const listItemsClear = () => ({
  type: LIST_ITEMS_CLEAR,
})

export const fetchListItems = (typeId, options= {}) => ({
  type: FETCH_LIST_ITEMS,
  payload: { typeId: typeId, options: options },
})

export const fetchItem = (docId, options = {}) => ({
  type: FETCH_ITEM,
  payload: { docId: docId, options: options },
})

export const updateListItemServiceInfoStatus = (docId, token, status) => ({
  type: UPDATE_LIST_ITEMS_SERVICE_INFO_STATUS,
  payload: { docId: docId, token: token, status: status },
})

export const startBgFetchListItems = () => ({
  type: START_BACKGROUND_FETCH_LIST_ITEMS,
})

export const stopBgFetchListItems = () => ({
  type: STOP_BACKGROUND_FETCH_LIST_ITEMS,
})
