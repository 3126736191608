import React from 'react'
import classNames from 'classnames'
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import ProcessAccordion from 'components/ProcessAccordion'
import _ from 'lodash'
import TimeCounter from 'components/TimeCounter'
import { maskProcess } from 'helpers/functions'

import styles from './styles'

const RowProcess = ({
  title,
  consists,
  docUrl,
  status,
  slowProcessing,
  loading,
  icon,
  info,
  onChangeCheckbox,
  checkboxChecked,
  checkboxDisabled,
  showCheckbox = false,
  msg_errors,
  msg_infos,
  response_time,
  total_processes,
}) => {
  const classes = styles()
  const theme = useTheme()

  function getColor() {
    if (status === 'PROCESSING') {
      return slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
    }
    if (status === 'PROCESSED') {
      if (consists) {
        return theme.palette.primary.red
      }
      if (msg_errors && msg_errors.length > 0) {
        return theme.palette.primary.grayLight2
      }
      // if (msg_infos && msg_infos.length > 0) {
      //   return theme.palette.primary.grayLight2
      // }
      return theme.palette.primary.green
    } if (status === 'ERROR') {
      return theme.palette.primary.red
    }

    return theme.palette.primary.grayLight
  }

  function renderLoading() {
    return loading && (
      <CircularProgress
        className={classes.progress}
        style={{
          width: 25,
          height: 25,
          marginTop: 5,
        }}
      />
    )
  }

  function renderSecondary() {
    if (msg_errors && msg_errors.length > 0) {
      return msg_errors.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    // if (msg_infos && msg_infos.length > 0) {
    //   return msg_infos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    // }
    if (status === 'PROCESSING') {
      if (msg_infos && msg_infos.length > 0) {
        return msg_infos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
      }

      return (
        <TimeCounter responseTime={response_time} />
      )
    }
    if (consists) {
      if (total_processes !== '0' && total_processes !== undefined && total_processes !== null){
        return (
          <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>{`Existem ${total_processes} ocorrências`}</span>
        )
      }
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Existe(m) ocorrência(s)</span>
      )
    }
    if (status === 'PROCESSED' && !consists) {
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Nenhuma ocorrência</span>
      )
    }
    return (
      <span />
    )
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  return (
    <>
      <ListItem className={classes.certiLine} style={{color: getColor(), fontSize: 17}}>
        { showCheckbox && (
          <Checkbox
            checked={checkboxChecked}
            onChange={handleChangeCheckbox()}
            onClick={(e) => e.stopPropagation()}
            disabled={checkboxDisabled}
          />
        )}
        {icon !== false && (
          <ListItemAvatar>
            <i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={<span style={{fontWeight: 600, fontSize: 17, display: 'block', color: getColor()}}>{title}</span>}
          secondary={renderSecondary()}
        />
        <ListItemSecondaryAction>
          {renderLoading()}

          {(docUrl && docUrl !== null && docUrl !== undefined) && (
          <IconButton edge="end" disabled={loading || !docUrl}>
            <a href={docUrl} target="_blank" rel="noopener noreferrer">
              <i
                className="fas fa-download"
                style={{ color: getColor() }}
              />
            </a>
          </IconButton>
          )
        }
        </ListItemSecondaryAction>
      </ListItem>
      {total_processes !== '0' && info && (
        <ProcessAccordion
          title="Processos Judiciais"
          subtitle={`${info.length} ocorrências`}
        >
          <div style={{ marginLeft: 30 }}>
            {info.map((item, index) => (
              <ProcessAccordion
                title={`Processo ${index + 1}`}
                subtitle={`Quantidade de Processos: ${item.count_processes}`}
                key={index.toString()}
                icon
              >
                <div style={{ marginLeft: 60 }}>
                  <p>
                    <strong>Parte do Processo: </strong>
                    {item.part_most_appeared}
                  </p>
                  <p>
                    <strong>Descrição: </strong>
                    {item.description}
                  </p>
                  <p>
                    <strong>Quantidade de Processos: </strong>
                    {item.count_processes}
                  </p>
                  <ul style={{ marginLeft: 20 }}>
                    {item.processes.map((p, index1) => (
                      <li key={index1.toString()}>
                        <p>
                          <strong>Número: </strong>
                          {' '}
                          {maskProcess(p.process_identifier)}
                        </p>
                        <p>
                          <strong>Tipo: </strong>
                          {' '}
                          {p.type}
                        </p>
                        <p>
                          <strong>Data da ultima movimentação: </strong>
                          {p.date_movements}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </ProcessAccordion>
            ))}
          </div>
        </ProcessAccordion>
      )}
    </>
  )
}

export default RowProcess
