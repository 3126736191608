import React, { Fragment } from 'react'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { formatToCEP, formatToCNPJ, formatToCPF } from 'brazilian-values'
import TimeCounter from 'components/TimeCounter'

export const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
  responsive: 'scroll',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}


export const columnsPayments = [
  {
   name: 'number',
   label: 'Número da Parcela',
   options: {
    filter: true,
    sort: true,
   },
  },
  {
   name: 'situation',
    label: 'Situação',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'available_from',
    label: 'Disponível em',
   options: {
    filter: true,
    sort: false,
   },
  },
 ]

export const columnsSocial = [
  {
    name: 'program_name',
   label: 'Nome do Programa',
   options: {
    filter: true,
    sort: true,
   },
  },
  {
    name: 'program_state',
    label: 'Estado',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'assistance_details_city',
    label: 'Cidade',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'assistance_start_date',
    label: 'Início',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'assistance_amount',
    label: 'Valor',
   options: {
    filter: true,
    sort: false,
   },
  },
 ]

export const columnsRais = [
  {
    name: 'cnpj_cei',
   label: 'CNPJ',
   options: {
    filter: true,
    sort: true,
   },
  },
  {
    name: 'corporate_name',
    label: 'Nome',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'situation',
    label: 'Situação',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'admission_date',
    label: 'Data de Admissão',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'delivery_date',
    label: 'Data de entrega',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
    name: 'shutdown_date',
    label: 'Data de Conclusão',
   options: {
    filter: true,
    sort: false,
   },
  },
 ]

export const columnsRelatives = [
  {
    name: 'name',
    label: 'Nome',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'cpf',
    label: 'cpf',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        try {
          return value ? formatToCPF(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'birth_date',
    label: 'Data de Nascimento',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const columnsPartners = [
  {
    name: 'name',
    label: 'Nome',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        try {
          return value ? <div style={{width: 100}}>{formatToCNPJ(value)}</div> : null
        } catch (err) {
          Sentry.captureException(err)
          return null
        }
      },
    },
  },
  // {
  //   name: 'stock',
  //   label: 'Participação na empresa',
  //   options: {
  //     filter: true,
  //     sort: true,
  //     customBodyRender: (value) => {
  //       try {
  //         return value ? `${value}%` : ''
  //       } catch (err) {
  //         Sentry.captureException(err)
  //         return null
  //       }
  //     },
  //   },
  // },
  {
    name: 'cadastral_situation',
    label: 'Situação',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!Number.isNaN(Number(value))) {
          return <TimeCounter responseTime={value} column={true} small={true} />
        }

        return <strong>{value}</strong>
      },
    },
  },
  {
    name: 'members',
    label: 'Sócios',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (members) => {
        if (_.isEmpty(members)) return null

        if (!Number.isNaN(Number(members))) {
          return <TimeCounter responseTime={members} column={true} small={true} />
        }

        const textMember = (member) => {
          const name = _.chain(member).get('name').toLower().startCase().value()
          const cpf = formatToCPF(_.get(member, 'cpf') || '')
          const qualification = _.chain(member).get('qualification').replace(/[0-9]+?-/, '').toLower().startCase().value() || ''

          return _.filter([name, cpf, qualification], (x) => !_.isEmpty(x)).join(' - ')
        }

        return (
          <Fragment>
            {
              _.map(members, (member, i) => (
                <Fragment key={i.toString()}>
                  <p style={{ whiteSpace: 'nowrap' }}>
                    {textMember(member)}
                  </p>
                </Fragment>
              ))
            }
          </Fragment>
        )
      },
    },
  },
  {
    name: 'type',
    label: 'Tipo',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'address',
    label: 'Endereço',
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: 'neighborhood',
  //   label: 'Bairro',
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
  {
    name: 'city',
    label: 'Cidade/Estado',
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: 'zipcode',
  //   label: 'CEP',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRender: (value) => {
  //       try {
  //         return value ? formatToCEP(value) : null
  //       } catch (err) {
  //         Sentry.captureException(err)
  //         return false
  //       }
  //     },
  //   },
  // },
]

export const columnsCompanyJobDetails = [
  {
    name: 'name',
    label: 'Nome',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        try {
          return value ? <div style={{ width: 100 }}>{formatToCNPJ(value)}</div> : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'address',
    label: 'Endereço',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'city',
    label: 'Cidade/Estado',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'zipcode',
    label: 'CEP',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        try {
          return value ? formatToCEP(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
]

export const columnsJobsHistory = [
  {
    name: 'name',
    label: 'Nome',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        try {
          return value ? <div style={{width: 100}}>{formatToCNPJ(value)}</div> : null
        } catch (err) {
          Sentry.captureException(err)
          return null
        }
      },
    },
  },
  {
    name: 'income',
    label: 'Salário',
    options: {
      filter: true,
      sort: true,
    },
  },
]
