import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Button,
} from '@material-ui/core'
import { Select } from 'components/actions'
import { MaskInput } from 'components/MaskedInput'
import { inputsValid, templateServicesNotInObjServices } from 'helpers/functions'
import { mappedInput, originalInput } from 'helpers/mapInputs'
import { canNotJoinInput } from 'helpers/mapNotJoinInputs'
import { translateService } from 'helpers/translateServicesInfos'
import { getTemplateAllCheckedServicesIds, getTemplateAllCheckedServices } from 'helpers/constants/defaultTemplateConfigBlocks'

import {
  addValInInputServices,
  postServices,
} from 'redux/servicesRequested/actions'
import { translateKeyName } from 'helpers/translateKeyName'
import styles from './styles'

const removeServiceIdFromKey = (serviceId, inputKey) => inputKey.replace(`${serviceId}--`,'')
const getServiceIdFromKey = (inputKey) => _.first(_.split(inputKey, '--', 1))
const getInputKeyFromKey = (inputKey) => _.last(_.split(inputKey, '--', 2))

const mergeInputs = (inputs1, inputs2, serviceId1 = undefined) => {
  const allKeys = _
    .chain(_.keys(inputs1))
    .union(_.keys(inputs2))
    .map((inputKey) => {
      if (canNotJoinInput(serviceId1, inputKey)) return `${serviceId1}--${inputKey}`
      return inputKey
    })
    .value()

  return _.reduce(allKeys, (result, key) => {
    const inputKey = removeServiceIdFromKey(serviceId1, key)

    const input1 = inputs1[inputKey]
    const input2 = inputs2[inputKey]
    if (_.isEmpty(input2)) return { ...result, [key]: input1 }
    if (_.isEmpty(input1)) return { ...result, [key]: input2 }

    // nesteds
    if (!_.has(input1, 'description') && !_.has(input2, 'description')) {
      return {
        ...result,
        [key]: {
          ...input1,
          ...input2,
          ...mergeInputs(input1, input2),
        },
      }
    }

    const newInputs = {
      ...result,
      [key]: {
        ...input1,
        ...input2,
        servicesIds: _.union(input1.servicesIds, input2.servicesIds),
        objIds: _.union(input1.objIds, input2.objIds),
        names: _.union(input1.names, input2.names),
        value: input1.value || input2.value,
        description: input1.description || input2.description,
        required: input1.required || input2.required,
        populated: input1.populated && input2.populated,
        valid: input1.valid && input2.valid,
      },
    }

    const enums = _.union(input1.enums, input2.enums)
    if (!_.isEmpty(enums)) newInputs[key].enums = enums
    return newInputs
  }, {})
}

const ContainerNeedInputs = () => {
  const classes = styles()

  const dispatch = useDispatch()
  const objServices = useSelector(state => state.servicesRequested.objServices)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const valueValid = useSelector(state => state.servicesRequested.valueValid)
  const template = useSelector(state => state.templateConfig.template)

  const handleChange = (servicesIds, objIds, inputKey) => event => {
    const inKey = getInputKeyFromKey(inputKey)

    const objInputs = _.map(objIds, (objId) => {
      const objService = _.find(objServices, { objId: objId })
      const key = originalInput(objService.service_id, inKey) || inKey

      return {
        objId: objId,
        inputKey: key,
        value: event.target.value,
      }
    })

    dispatch(addValInInputServices(objInputs))
  }

  const handleClickOk = () => {
    dispatch(postServices())
  }

  const transformInputs = (inputs, servicesIds, objIds, names) => {
    const _inputs = _.cloneDeep(inputs)
    const transformeds = Object.keys(_inputs).map(inputKey => {
      if (_.isEmpty(_inputs[inputKey].properties)) {

        const mapped = mappedInput(servicesIds[0], inputKey)
        return {
          [mapped || inputKey]: {
            servicesIds: _.isArray(servicesIds) ? servicesIds : [],
            objIds: _.isArray(objIds) ? objIds : [],
            names: _.isArray(names) ? names : [],
            value: _inputs[inputKey].value,
            enums: _inputs[inputKey].enum,
            description: _inputs[inputKey].description,
            required: _inputs[inputKey].required,
            populated: _inputs[inputKey].populated,
            valid: _inputs[inputKey].valid,
          },
        }
      }

      const inputsNested = _inputs[inputKey].properties
      const trasformedInputsNested = Object.keys(inputsNested).map(() => {
        return transformInputs(inputsNested, servicesIds, objIds, names)
      })

      return {
        [inputKey]: Object.assign(...trasformedInputsNested, {}),
      }
    })

    return Object.assign(...transformeds, {})
  }

  const joinInputs = () => {
    const servicesIdsCheckeds = getTemplateAllCheckedServicesIds(template.blocks)
    const servicesIdsNeedInputs = _
    .chain(objServices)
    .filter((val) => val.status === 'NEED_INPUTS')
    .map('service_id')
    .value()

    const joinedInputs = _
      .chain(objServices)
      .filter((val) => servicesIdsCheckeds.includes(val.service_id) && servicesIdsNeedInputs.includes(val.service_id) )
      .reduce((total, val) => {
        if (_.isEmpty(val.inputs)) return total

        // add values from template services
        const templateServices = getTemplateAllCheckedServices(template.blocks)
        const templateService = _.find(templateServices, { service_id: val.service_id })

        const inputsUpdated = _.reduce(val.inputs, (result, inputVal, inputKey) => {
          const serviceInput = _.find(templateService.inputs, { name: inputKey })
          if (_.isEmpty(serviceInput)) return { ...result, [inputKey]: inputVal }
          return { ...result, [inputKey]: { ...inputVal, value: serviceInput.value, populated: true } }
        }, {})

        const transformedInputs = transformInputs(inputsUpdated, [val.service_id], [val.objId], [val.name])

        return mergeInputs(_.cloneDeep(transformedInputs), _.cloneDeep(total), val.service_id)
      }, {})
      .value()

    const joinedInputsToCall = _
    .chain(objServices)
    .filter((val) => val.toCall || servicesIdsNeedInputs.includes(val.service_id))
    .reduce((result, val) => {
      if (_.isEmpty(val.inputs)) return result

      const transformedInputs = transformInputs(val.inputs, [val.service_id], [val.objId], [val.name])

      return mergeInputs(_.cloneDeep(transformedInputs), _.cloneDeep(result), val.service_id)
    }, {})
    .value()

    const mergedInputs = mergeInputs(_.cloneDeep(joinedInputsToCall), _.cloneDeep(joinedInputs))

    return _
    .chain(mergedInputs)
    .keys()
    .sort()
    .reduce((result, key) => ({ ...result, [key]: mergedInputs[key] }), {})
    .omit(['cpf'])
    .value()
  }

  const hasNewTemplateServices = () => {
    const newServices = templateServicesNotInObjServices(template.blocks, objServices)
    return !_.isEmpty(newServices)
  }

  const hasObjServicesToCalled = () => {
    if (_.isEmpty(objServices)) return false

    const servicesNotCalled = objServices.filter(val => (!val.token && val.toCall) || val.status === 'NEED_INPUTS')
    if (_.isEmpty(servicesNotCalled)) return false

    return true
  }

  const allInputsValid = () => {
    const servicesNotCalled = objServices.filter(val => (!val.token && val.toCall) || val.status === 'NEED_INPUTS')

    return servicesNotCalled.every(val => {
      return inputsValid(val.inputs)
    })
  }

  const renderInputSelect = (keyElem, servicesIds, objIds, inputKey, value, required, enums) => {
    const complementText = required === true ? '' : ' \xa0\xa0(opcional)'
    const label = translateKeyName( _.last(getInputKeyFromKey(inputKey).split('.')) )
    let labelService = translateService(getServiceIdFromKey(inputKey)).name
    labelService = !_.isEmpty(labelService) ? ` - ${labelService}` : ''

    return (
      <Select
        key={keyElem}
        onChange={handleChange(servicesIds, objIds, inputKey)}
        options={enums}
        label={`${label}${labelService}${complementText}`}
        value={value || ''}
        className={classes.textField}
      />
    )
  }

  const renderInputText = (keyElem, servicesIds, objIds, inputKey, value, required, valid, name) => {
    const complementText = required === true ? '' : ' \xa0\xa0(opcional)'
    const label = translateKeyName( _.last(getInputKeyFromKey(inputKey).split('.')) )
    let labelService = translateService(getServiceIdFromKey(inputKey)).name
    labelService = !_.isEmpty(labelService) ? ` - ${labelService}` : ''

    return (
      <MaskInput
        className={classes.textField}
        key={keyElem}
        label={`${label}${labelService}${complementText}`}
        name={name}
        onChange={handleChange(servicesIds, objIds, inputKey)}
        value={value || ''}
        variant="outlined"
        errorMsg={valid ? null : 'Campo inválido'}
      />
    )
  }

  const renderInputsNested = (parentKey, transformedInputs) => {
    const elemsInputs = _.compact(renderInputs(transformedInputs, parentKey))
    if (_.isEmpty(elemsInputs)) return null

    return (
      <div style={{ marginLeft: 10 }} key={parentKey}>
        <p style={{ marginBottom: 8 }}>
          {translateKeyName( _.last(parentKey.split('.')) )}
        </p>
        <div style={{ marginLeft: 10 }}>
          {elemsInputs}
        </div>
      </div>
    )
  }

  const renderInputs = (transformedInputs, initKey = '') => {
    const elemsInputs = Object.entries(transformedInputs).map(([inputKey, val]) => {
      const { servicesIds, objIds, value, enums, required, populated, valid } = val

      if (populated === true) return null

      const strInputKey = _.isEmpty(initKey) ? inputKey : `${initKey}.${inputKey}`

      if (servicesIds === undefined) {
        return renderInputsNested(strInputKey, val)
      }

      if (required !== true) return null

      const keyElem = `${servicesIds[0]}-${strInputKey}`

      if (enums) {
        return renderInputSelect(keyElem, servicesIds, objIds, strInputKey, value, required, enums)
      }

      if (inputKey.includes('date')) {
        return renderInputText(keyElem, servicesIds, objIds, strInputKey, value, required, valid, 'date')
      }

      return renderInputText(keyElem, servicesIds, objIds, strInputKey, value, required, valid, null)
    })

    return elemsInputs
  }

  const joinedInputs = joinInputs()

  return (
    <>
      {renderInputs(joinedInputs)}
      <Button
        variant="contained"
        size="small"
        className={classes.button}
        onClick={handleClickOk}
        disabled={!allInputsValid() || !valueValid || servicesReqLoading || !(hasNewTemplateServices() || hasObjServicesToCalled())}
      >
        Solicitar
      </Button>
    </>
  )
}

export default ContainerNeedInputs
