import red from '@material-ui/core/colors/red'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#002d3e',
      hover: '#026f95',
      bg: '#fff',
      dark: '#192131',
      darker: '#070f20',
      light: '#9b9fb0',
      lighter: '#fff',

      orange: '#ed9900',
      gray: '#808080',
      grayDark: '#666666',
      grayLight: '#cccccc',
      grayLight2: '#999999',
      grayPaper: '#f8f8f8',

      black: '#000000',
      green: '#2daa00',
      greenHover: '#019101',
      red: '#d0021b',
    },
    secondary: {
      main: '#343a40',
      hover: '#61656b',
    },
    third: {
      main: '#2daa00',
      hover: '#019101',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: "'Barlow Condensed', sans-serif",
  },
})

export default theme
