import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useTheme, withStyles } from '@material-ui/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import {
  Checkbox,
  CircularProgress,
  Divider,
  ExpansionPanelDetails,
  Icon,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { CardTitle, StatusConsult } from 'components/styledComponents'
import styles from './styles'

const ExpansionPanel = withStyles({
  root: {
    border: '2px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    borderRadius: 15,
  },
  rounded:{
    '&:first-child': {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    '&:last-child': {
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#FFF',
    '&$focused': '#FFF',
    borderRadius: 15,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})(MuiExpansionPanelSummary)

export default function ControlledExpansionPanels({
  title,
  children,
  processed,
  processing,
  slowProcessing,
  loading,
  consists,
  onChangeCheckbox,
  checkboxChecked,
  checkboxIndeterminate,
  checkboxDisabled,
  showCheckbox = false,
}) {
  const classes = styles()
  const theme = useTheme()

  const servicesReqShowing = useSelector(state => state.servicesRequested.showing)
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  React.useEffect(() => {
    setExpanded(false)
  }, [servicesReqShowing])

  const renderSubtitle = () => {
    let elem = null

    if (loading) {
      elem = <CircularProgress className={classes.progress} style={{ width: 25, height: 25 }} />
    } else if (processing) {
      elem = (
        <StatusConsult>
          <span style={{ color: theme.palette.primary.grayLight2 }}>Processando</span>
          <Icon
            style={{
              color: slowProcessing ? theme.palette.primary.orange : theme.palette.primary.grayLight2,
              margin: 5,
            }}
          >
            autorenew
          </Icon>
        </StatusConsult>
      )
    } else if (consists) {
      elem = (
        <StatusConsult>

          <Icon style={{color: theme.palette.primary.red, margin: 5}}>error</Icon>
        </StatusConsult>
      )
    } else if (processed) {
      elem = (
        <StatusConsult>
          OK
          <Icon style={{color: theme.palette.primary.green, margin: 5}}>check_circle</Icon>
        </StatusConsult>
      )
    }

    return <div className={classes.subtitle}>{elem}</div>
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        square={false}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          { showCheckbox && (
            <Checkbox
              checked={checkboxChecked}
              indeterminate={checkboxIndeterminate}
              onChange={handleChangeCheckbox()}
              onClick={(e) => e.stopPropagation()}
              disabled={checkboxDisabled}
            />
          )}
          <CardTitle
            consists={consists ? 1 : 0}
            loading={loading ? 1 : 0}
            processed={processed ? 1 : 0}
          >
            {title}
          </CardTitle>
          {renderSubtitle()}
        </ExpansionPanelSummary>

        <Divider />

        <ExpansionPanelDetails>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {children}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
