import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  containerListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: 15,
    borderRadius: 15,
    border: `solid 1px ${theme.palette.primary.grayLight}`,
    backgroundColor: theme.palette.primary.grayPaper,
  },
  header: {
    paddingTop: 0,
  },
  headerText: {
    fontSize: '20px',
  },
  containerItemData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItemPagination: {
  },
  containerInputsFilter: {
    marginBottom: 10,
    paddingLeft: 16,
  },
  inputFilterText: {
     width: '30%',
  },
}))

export default styles
