import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

const HOST = process.env.REACT_APP_BACKGROUND_CHECK_API

export async function getCpfReport(apiKey, userId, params = {}) {
  const dateFormat = 'DD-MM-YYYY'
  const initialDate = params.initialDate ? params.initialDate.format(dateFormat) : moment().subtract(1, 'years').format(dateFormat)
  const finalDate = params.finalDate ? params.finalDate.format(dateFormat) : moment(new Date()).format(dateFormat)
  const { value } = params
  const { docId } = params
  const page = params.page || 1

  let queryString = `&user_id=${userId}`
  queryString += `&initial_date=${initialDate}`
  queryString += `&final_date=${finalDate}`
  queryString += `&page=${page}`
  queryString += value ? `&value=${value}` : ''
  queryString += docId ? `&doc_id=${docId}` : ''

  const res = await axios({
    method: 'GET',
    url: `${HOST}/cpf/report?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })
  return res
}

export async function getCpfService(apiKey, userId, dataId) {
  const res = await axios({
    method: 'GET',
    url: `${HOST}/cpf/services?user_id=${userId}&data_id=${dataId}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function postCpfCreate(apiKey, userId, value, services) {
  const data = {
    services: services,
    user_id: userId,
    value: value,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/cpf/create`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function patchCpfInsert(apiKey, userId, docId, services) {
  const data = {
    services: services,
  }

  let queryString = `&user_id=${userId}`
  queryString += `&doc_id=${docId}`

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/cpf/insert/services?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function patchCpfUpdate(apiKey, userId, docId, servicesWithDataId) {
  const data = {
    services: _.map(servicesWithDataId, (val) => _.omit(val, 'service_id')),
  }

  let queryString = `&user_id=${userId}`
  queryString += `&doc_id=${docId}`

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/cpf/update/services?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function postCpfTemplate(apiKey, userId, template) {
  const data = {
    user_id: userId,
    template: template,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/cpf/servicestemplate`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function putCpfTemplate(apiKey, userId, template) {
  const data = {
    user_id: userId,
    template: template,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/cpf/servicestemplate`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function getCpfTemplate(apiKey, userId, templateName) {
  const res = await axios({
    method: 'GET',
    url: `${HOST}/cpf/servicestemplate?user_id=${userId}&template_name=${templateName}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getCpfReportFiles(apiKey, userId, docId) {
  let queryString = `&user_id=${userId}`
  queryString += `&doc_id=${docId}`

  const res = await axios({
    method: 'GET',
    url: `${HOST}/cpf/reportfiles?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })
  return res
}
