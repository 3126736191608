import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import classNames from 'classnames'

import { toast } from 'react-toastify'
import { IconButton, Tooltip } from '@material-ui/core'

import { getCpfReportFiles } from 'services/apiBgc'
import { getExtensionFileName } from 'helpers/functions'

import styles from './styles'

const getReportUrls = async (apiKey, userId, docId) => {
  let res = null
  try {
    res = await getCpfReportFiles(apiKey, userId, docId)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
    throw new Error('Houve um problema ao obter o relatório')
  }

  return res.data
}

const urlToPromise = async (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Cache-Control' : 'no-cache',
      },
    }

    axios.get(url, config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

const saveZip = async (fileName, mainUrl, servicesUrls) => {
  const zip = new JSZip()

  zip.file(
    `relatorio.${getExtensionFileName(mainUrl.document_url)}`,
    urlToPromise(mainUrl.document_url),
    { binary: true },
  )

  servicesUrls.forEach(item => {
    const pdfName = item.name
    const extension = getExtensionFileName(item.document_url)
    const folder = 'certidoes'

    zip.file(
      `${folder}/${pdfName}.${extension}`,
      urlToPromise(item.document_url),
      { binary: true },
    )
  })

  try {
    const zipContent = await zip.generateAsync({ type: 'blob' })
    const zipName = `${fileName}.zip`
    saveAs(zipContent, zipName)
  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
    throw new Error('Houve um problema ao gerar o relatório')
  }
}

const ButtonDownloadReport = ({ createdAt, docId, value }) => {
  const classes = styles()

  const userId = useSelector(state => state.auth.sub)
  const apiKey = useSelector(state => state.auth.api_key)
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)

    let reportUrls = null
    try {
      reportUrls = await getReportUrls(apiKey, userId, docId)
    } catch (err) {
      toast.error(err.message)
      return null
    }

    const nameDate = moment(createdAt).format('DD-MM-YYYY_HH-mm')
    const fileName = `bgc_${value}_${nameDate}`

    saveZip(fileName, reportUrls.main, reportUrls.services)
    .then(() => {
      setLoading(false)
    }).catch((err) => {
      toast.error(err.message)
    })

    return null
  }

  return (
    <Tooltip title="Baixar Relatório" aria-label="Baixar Relatório">
      <IconButton
        aria-label="delete"
        edge="end"
        onClick={handleClick}
        disabled={loading}
      >
        <i className={classNames(classes.iconDownload, 'fas', 'fa-download')} />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonDownloadReport
