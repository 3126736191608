import _ from 'lodash'

import defaultTemplateConfigBlocks, { generateObjService } from 'helpers/constants/defaultTemplateConfigBlocks'
import servicesDocsConstants from 'helpers/constants/servicesDocs'

import {
  FETCH_TEMPLATE_CONFIG,
  FETCH_TEMPLATE_CONFIG_FAILED,
  FETCH_TEMPLATE_CONFIG_SUCCEEDED,
  SAVE_TEMPLATE_CONFIG,
  SAVE_TEMPLATE_CONFIG_FAILED,
  SAVE_TEMPLATE_CONFIG_SUCCEEDED,
  TEMPLATE_CONFIG_CLEAR,
  UPDATE_TEMPLATE_CONFIG_BLOCKS,
} from './actions'

const joinBlockServices = (services, otherServices, addChecked, addServices, addInputs, overwriteInputValue) => {
  const servicesCloned = _.cloneDeep(services)
  _.forEach(otherServices, (service) => {
     const defaultService = _.find(servicesCloned, (val) => {
       return (_.isEqual(val.service_id, service.service_id) && _.isEqual(val.inputs, service.inputs))
            || (_.isEqual(val.service_id, service.service_id) && _.isEmpty(val.inputs))
     })
    if (_.isEmpty(defaultService)) {
      if (addServices === true) servicesCloned.push(
        generateObjService(service.service_id, service.inputs, service.checked, true)
      )
      return
    }

    if (addChecked === true) defaultService.checked = service.checked

    service.inputs.forEach((input) => {
      const defaultInput = _.find(defaultService.inputs, { name: input.name })
      if (_.isEmpty(defaultInput)) {
        if (addInputs === true) defaultService.inputs.push(input)
        return
      }

      if (_.isEmpty(defaultInput.value) || overwriteInputValue) defaultInput.value = input.value
    })
  })

  const servicesIdsPermitted = _.chain(defaultTemplateConfigBlocks)
    .reduce((result, val, _key) => [...result, ..._.values(val.services)], [])
    .reduce((result, val) => [...result, val.service_id], [])
    .uniq()
    .value()

  const servicesClonedFiltered = _.filter(servicesCloned, (service) => {
    return servicesIdsPermitted.includes(service.service_id)
  })

  return servicesClonedFiltered
}

const joinBlocks = (blocks1, blocks2, addChecked, addBlocks, addServices, addInputs, overwriteInputValue) => {
  const blocksCloned = _.cloneDeep(blocks1)
  blocks2.forEach((block2) => {
    const defaultBlock = _.find(blocksCloned, { block_id: block2.block_id })
    if (_.isEmpty(defaultBlock)) {
      if (addBlocks === true) blocksCloned.push(block2)
      return
    }

    if (addChecked === true) defaultBlock.checked = block2.checked

    defaultBlock.services = joinBlockServices(defaultBlock.services, block2.services, addChecked, addServices, addInputs, overwriteInputValue)
  })

  return blocksCloned
}

const mapInputs = (servicesDocs) => {
  return _.map(servicesDocs, (val, key) => {
    const inputs = _
      .chain(val.inputs)
      .pickBy((inputVal) => !_.isEmpty(inputVal.enum) && inputVal.required === true)
      .map((inputVal, inputKey) => ({ name: inputKey, value: inputVal.enum[0] }))
      .value()

    return generateObjService(key, inputs)
  }, {})
}

const joinBlocksWithInputs = () => {
  const servicesIdsPermitted = _.chain(defaultTemplateConfigBlocks)
    .reduce((result, val, _key) => [...result, ..._.values(val.services)], [])
    .reduce((result, val) => [...result, val.service_id], [])
    .uniq()
    .value()

  const blockServicesPermitted = _.pickBy(
    mapInputs(servicesDocsConstants),
    (blockService) => servicesIdsPermitted.includes(blockService.service_id)
  )

  return _.map(defaultTemplateConfigBlocks, (block, _key) => {
    return {
      ...block,
      services: joinBlockServices(block.services, blockServicesPermitted, false, false, true, false),
    }
  })
}

const initialState = {
  fail: false,
  fetched: false,
  load: false,
  template: {
    template_name: 'template_1',
    blocks: joinBlocksWithInputs(),
  },
  messageError: '',
}

export const templateConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_CONFIG_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case FETCH_TEMPLATE_CONFIG: {
      return {
        ...state,
        fail: false,
        fetched: false,
        load: true,
      }
    }
    case FETCH_TEMPLATE_CONFIG_SUCCEEDED: {
      return {
        ...state,
        template: {
          ...state.template,
          ...action.payload,
          blocks: joinBlocks(state.template.blocks, action.payload.blocks, true, false, true, true, true),
        },
        fail: false,
        fetched: true,
        load: false,
      }
    }
    case FETCH_TEMPLATE_CONFIG_FAILED: {
      return {
        ...state,
        fail: true,
        fetched: false,
        load: false,
        messageError: action.payload,
      }
    }

    case SAVE_TEMPLATE_CONFIG: {
      return {
        ...state,
        // template: action.payload,
        load: true,
        fail: false,
      }
    }
    case SAVE_TEMPLATE_CONFIG_SUCCEEDED: {
      return {
        ...state,
        fail: false,
        load: false,
      }
    }
    case SAVE_TEMPLATE_CONFIG_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }

    case UPDATE_TEMPLATE_CONFIG_BLOCKS: {
      return {
        ...state,
        template: {
          ...state.template,
          blocks: action.payload,
        },
        load: false,
        fail: false,
      }
    }

    default:
      return state
  }
}

export default templateConfigReducer
