import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  icon: {
    fontSize: 30,
  },
  certiLine: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 17,
    paddingTop: 10,
  },
}))

export default styles
