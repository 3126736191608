import React from 'react'
import { withStyles } from '@material-ui/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

import styles from './styles'

const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid #cecece',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    borderRadius: 15,
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    display: 'flex',
    // flexDirection: 'column',
  },
})(MuiExpansionPanelSummary)


export default function ProcessAccordion({title, subtitle, icon, children}) {
  const classes = styles()

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {icon && (<i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />)}
            <div>
              <Typography className={classes.heading}>{title}</Typography>
              <Typography className={classes.secondaryHeading}>{subtitle}</Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
        {children}
      </ExpansionPanel>
    </div>
  )
}
