import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, useTheme } from '@material-ui/styles'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  AppBar,
  Toolbar,
  Popper,
  Grow,
  Paper,
  Typography,
  // InputBase,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery,
  IconButton,
  Drawer,
} from '@material-ui/core'

import {
  // Assessment,
  // Warning,
  // CheckBox,
  // ArrowDropDownCircle,
  Menu as MenuIcon,
  InsertEmoticon,
  Cancel,
  // Search as SearchIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import { logout } from 'redux/auth/actions'

import styles from './styles'

const MainNavbar = () => {
  const classes = styles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const dispatch = useDispatch()
  const theme = useTheme()

  // function handleToggle() {
  //   setOpen(prevOpen => !prevOpen)
  // }

  const [openDrawer, setOpenDrawer] = React.useState(false)
    const name = useSelector(state => state.auth.name)


  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  function handleClose(event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      keepMounted
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                classes={{
                  root: classes.dropdown,
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  const menuItens = () => (
    <ul className={classes.listNav}>
      {/* <li className={classes.listNavItem}>
        <Assessment />
        <Typography color="inherit">
          <Link className={classes.menuItem} to="/">
            RELATÓRIO
          </Link>
        </Typography>
      </li>

      <li className={classes.listNavItem}>
        <CheckBox />
        <Typography color="inherit">
          <Link
            className={classes.menuItem}
            to="/containers_list"
          >
            LISTA
          </Link>
        </Typography>
      </li>

      <li className={classes.listNavItem}>
        <Warning />
        <Typography color="inherit">
          <Link
            className={classes.menuItem}
            to="/analyze_container"
          >
            STATUS
          </Link>
        </Typography>
      </li> */}
    </ul>
  )

  const sectionUser = () => (
    <div className={classes.sectionUser}>
      <ul className={classes.listNav}>
        <li className={classes.listNavItemUsername}>
          <InsertEmoticon />
          <Typography color="inherit" className={classes.menuItem}>{name.toUpperCase()}</Typography>
        </li>
        <li
          className={classes.listNavItem}
        >
          <Cancel onClick={() => dispatch(logout())} />
          <Typography color="inherit" className={classes.logolf}>USERNAME</Typography>

        </li>
      </ul>
    </div>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
      >
        <Toolbar>
          <div
            className={classes.toolbar}
          >
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1170px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <img
                  className={classes.logo}
                  src="assets/images/logos/logo_main.png"
                  alt="logo"
                />
                <div>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    Background Check
                  </Typography>
                  <span className={classes.version}>
                    {`version ${process.env.REACT_APP_PROJECT_VERSION}`}
                  </span>
                </div>
              </div>
            </div>

            <div
              className={classes.menubar}
            >
              <div className={classes.sectionDesktop}>
                {useMediaQuery('(min-width: 1170px)') && menuItens()}
              </div>

              {/* <div className={classes.search}>
                <InputBase
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              </div> */}
              {useMediaQuery('(min-width: 1170px)') && sectionUser()}

            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {useMediaQuery('(max-width: 1170px)') && menuItens()}
          {useMediaQuery('(max-width: 1170px)') && sectionUser()}
        </Drawer>
      </AppBar>
      {renderMenu}
    </div>
  )
}

MainNavbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainNavbar)
