import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E0E0E0',
    borderRadius: 15,
    padding: 15,
  },
  containerBlockServices: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 5,
    border: '1px #B9B9B9 solid',
    marginLeft: 15,
    paddingLeft: 10,
    paddingRight: 10,
  },
  containerBlockServicesInput: {
    marginLeft: 15,
    marginBottom: 10,
  },
  containerBlockServicesInputEnum: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxBlock: {
    padding: 1,
    margin: 4,
  },
  checkboxService: {
    padding: 1,
    marginTop: 5,
    marginLeft: 10,
  },
  checkboxEnums: {
    padding: 1,
    marginLeft: 10,
  },
}))

export default styles
