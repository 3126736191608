import React from 'react'
import * as Sentry from '@sentry/browser'

export const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
  responsive: 'scroll',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

export const columnsAlerts = [
  {
   name: 'date',
   label: 'Data',
   options: {
    filter: true,
    sort: true,
     customBodyRender: (value) => {
       try {
         return value ? <div style={{width: 100}}>{value}</div> : null
       } catch (err) {
         Sentry.captureException(err)
         return false
       }
     },
   },
  },
  {
   name: 'message',
   label: 'Mensagem',
   options: {
    filter: true,
    sort: false,
   },
  },
]

export const columnsBanking = [
  {
   name: 'bank',
   label: 'Banco',
   options: {
    filter: true,
    sort: true,
   },
  },
  {
   name: 'agency',
   label: 'Agência',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
   name: 'competency_date',
   label: 'Competência',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
   name: 'quantity',
   label: 'Quantidade',
   options: {
    filter: true,
    sort: false,
   },
  },
]

export const columnsVehicles = [
  {
    name: 'name',
    label: 'Nome',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'license_plate',
    label: 'Placa',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'year',
    label: 'Ano',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'renavam',
    label: 'Renavam',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'chassi',
    label: 'Chassi',
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: 'fuel_type',
  //   label: 'Combustível',
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
]
