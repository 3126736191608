import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginBottom: 18,
  },
  button: {
    backgroundColor: theme.palette.primary.green,
    fontSize: 20,
    color: theme.palette.primary.lighter,
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: theme.palette.primary.greenHover,
    },
  },
}))

export default styles
