import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import _ from 'lodash'

import constantsBlockServices from 'helpers/constants/blockServices'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual)

const filterObjServices = (objServices, blockService, otherServiceIds = []) =>
  _.filter(
    objServices,
    (obj) => _.concat(_.values(blockService), otherServiceIds).includes(obj.service_id),
  )

const customObjServicesSelector = (state, blockService, otherServiceIds = []) =>
  filterObjServices(state.servicesRequested.objServices, blockService, otherServiceIds)

export const selectObjServicesCadastralData = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.cadastralData, ['search_infos_person_complete']),
  objServices => filterObjServices(objServices, constantsBlockServices.cadastralData, ['search_infos_person_complete']),
)
export const selectObjServicesCadastralExtension = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.cadastralExtension, ['search_infos_person_complete', 'search_infos_person_complete_v2', 'cnpj_infos_federal_revenue', 'membership_board', 'pep_infos_government_base', 'pis_number']),
  objServices => filterObjServices(objServices, constantsBlockServices.cadastralExtension, ['search_infos_person_complete', 'search_infos_person_complete_v2', 'cnpj_infos_federal_revenue', 'membership_board', 'pep_infos_government_base', 'pis_number']),
)
export const selectObjServicesCreditHistory = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.creditHistory, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
  objServices => filterObjServices(objServices, constantsBlockServices.creditHistory, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
)
export const selectObjServicesPEP = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.pep),
  objServices => filterObjServices(objServices, constantsBlockServices.pep),
)
export const selectObjServicesNegativeMedia = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.negativeMedia),
  objServices => filterObjServices(objServices, constantsBlockServices.negativeMedia),
)
export const selectObjServicesProtestsAccounts = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.protestsAccounts),
  objServices => filterObjServices(objServices, constantsBlockServices.protestsAccounts),
)
export const selectObjServicesCvm = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.cvm),
  objServices => filterObjServices(objServices, constantsBlockServices.cvm),
)
export const selectObjServicesCertificates = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.certificates),
  objServices => filterObjServices(objServices, constantsBlockServices.certificates),
)
export const selectObjServicesCertificatesTRF = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.certificatesTRF),
  objServices => filterObjServices(objServices, constantsBlockServices.certificatesTRF),
)
export const selectObjServicesCourtLawsuits = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.courtLawsuits),
  objServices => filterObjServices(objServices, constantsBlockServices.courtLawsuits),
)
export const selectObjServicesLaborCourts = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.laborCourts),
  objServices => filterObjServices(objServices, constantsBlockServices.laborCourts),
)
export const selectObjServicesProcessesPJE = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.processesPJE),
  objServices => filterObjServices(objServices, constantsBlockServices.processesPJE),
)
export const selectObjServicesProcessesPJESecondInstance = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.processesPJESecondInstance),
  objServices => filterObjServices(objServices, constantsBlockServices.processesPJESecondInstance),
)
export const selectObjServicesProcessesESAJ = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.processesESAJ),
  objServices => filterObjServices(objServices, constantsBlockServices.processesESAJ),
)
export const selectObjServicesCriminalAntecedentsPC = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.criminalAntecedentsPC, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
  objServices => filterObjServices(objServices, constantsBlockServices.criminalAntecedentsPC, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
)
export const selectObjServicesCriminalAntecedentsTJ = createDeepEqualSelector(
  state => customObjServicesSelector(state, constantsBlockServices.criminalAntecedentsTJ, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
  objServices => filterObjServices(objServices, constantsBlockServices.criminalAntecedentsTJ, ['search_infos_person_complete', 'search_infos_person_complete_v2']),
)
const serviceSearchInfos = (objServices) => (
  _.find(objServices, { service_id: 'search_infos_person_complete_v2' })
  || _.find(objServices, { service_id: 'search_infos_person_complete' })
)
export const selectObjPerson = createSelector(
  state => state.servicesRequested.objServices,
  objServices => _.get(serviceSearchInfos(objServices), 'data.person'),
)
