import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { TitleBold } from 'components/styledComponents'

import {
  selectObjServicesCreditHistory,
} from 'redux/servicesRequested/selectors'

import DataTable from 'components/DataTable'
import TimeCounter from 'components/TimeCounter'
import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'
import { options, columnsAlerts, columnsBanking, columnsVehicles } from './configDataTable'

const ExpansionPanelCreditHistoric = ({ blockId }) => {
  const theme = useTheme()

  const objServicesRequested = useSelector(selectObjServicesCreditHistory)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(servicesList.data, (val) => getDefaultBlockServicesIds(blockId).includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const serviceSearchInfos = _.find(objServicesRequested, { service_id: 'search_infos_person_complete_v2' })
                          || _.find(objServicesRequested, { service_id: 'search_infos_person_complete' })
  const personConsists = _.get(serviceSearchInfos, 'consists')

  const consists = personConsists === true || (!_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true ))
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const person = _.get(serviceSearchInfos, 'data.person')
  const patrCalc = _.find(servicesList.data, { service_id: 'patr_calc_cpf' })

  const renderEstimatedIncome = () => {
    const elem = () => {
      if (_.get(patrCalc, 'status') === 'PROCESSING') {
        if (_.get(patrCalc, 'slow_processing')) {
          const msgInfos = _.get(patrCalc, 'data.msg_infos')
          if (!_.isEmpty(msgInfos)) {
            return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
          }
        }

        return <TimeCounter responseTime={_.get(patrCalc, 'response_time')} />
      }

      const data = _.get(patrCalc, 'data')

      const msgErrors = _.get(data, 'msg_errors')
      if (!_.isEmpty(msgErrors)) {
        return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
      }
      const msgInfos = _.get(data, 'msg_infos')
      if (!_.isEmpty(msgInfos)) {
        return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
      }

      return <span style={{ marginRight: '20px' }}>{_.get(data, 'patrimony_info.fx_renda')}</span>
    }

    return (
      <Grid container spacing={2} style={{ marginBottom: 20, marginTop: 20 }}>
        <Grid item xs={3}>
          <TitleBold>Renda Estimada</TitleBold>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          {elem()}
        </Grid>
      </Grid>
    )
  }

  const renderEstimatedEquity = () => {
    const elem = () => {
      if (_.get(patrCalc, 'status') === 'PROCESSING') {
        if (_.get(patrCalc, 'slow_processing')) {
          const msgInfos = _.get(patrCalc, 'data.msg_infos')
          if (!_.isEmpty(msgInfos)) {
            return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
          }
        }

        return <TimeCounter responseTime={_.get(patrCalc, 'response_time')} />
      }

      const data = _.get(patrCalc, 'data')

      const msgErrors = _.get(data, 'msg_errors')
      if (!_.isEmpty(msgErrors)) {
        return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
      }
      const msgInfos = _.get(data, 'msg_infos')
      if (!_.isEmpty(msgInfos)) {
        return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
      }

      return <span style={{ marginRight: '20px' }}>{_.get(data, 'patrimony_info.fx_patrimonio')}</span>
    }

    return (
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={3}>
          <TitleBold>Patrimônio Estimado</TitleBold>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          {elem()}
        </Grid>
      </Grid>
    )
  }

  const renderVehicles = () => {
    const vehicles = _.get(person, 'vehicles')
    if (_.isEmpty(vehicles)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 10 }}>Veículos que possui ou possuiu</TitleBold>
        <DataTable
          data={vehicles}
          columns={columnsVehicles}
          options={options}
        />
      </div>
    )
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', fontSize: 16 }}>
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Restrições Bancárias</TitleBold>
        {person ?
          person.banking_restrictions && person.banking_restrictions.length > 0 ?
            (
              <DataTable
                data={person.banking_restrictions}
                columns={columnsBanking}
                options={options}
              />
            ) : (
              <>
                <p>Não existem restrições bancárias para esse CPF</p>
              </>
            )
          : <div />
        }
      </div>
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Restrições de Compra</TitleBold>
        {person ?
          person.shopkeepers_restrictions && person.shopkeepers_restrictions.length > 0 ?
            (
              <DataTable
                data={person.shopkeepers_restrictions}
                columns={columnsBanking}
                options={options}
              />
            ) : (
              <>
                <p>Não existem restrições lojistas para esse CPF</p>
              </>
            )
          : <div />
        }
      </div>
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Alertas</TitleBold>
        {person ?
          person.alerts && person.alerts.length > 0 ?
            (
              <DataTable
                data={person.alerts}
                columns={columnsAlerts}
                options={options}
              />
            ) : (
              <>
                <p>Não existem alertas para esse CPF</p>
              </>
            )
          : <div />
        }
      </div>
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Restrição de Cheques</TitleBold>
        {person ?
          person.pre_dated_checks && person.pre_dated_checks.length > 0 ?
            (
              <DataTable
                data={person.pre_dated_checks}
                columns={columnsAlerts}
                options={options}
              />
            ) : (
              <>
                <p>Não existem cheques pré-datados para esse CPF</p>
              </>
            )
          : <div />
        }
      </div>

      {/* <Grid container spacing={2} style={{ marginBottom: 20, marginTop: 20 }}>
        <Grid item xs={3}>
          <div>Score SPC</div>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          <strong style={{ marginRight: '20px' }}>500</strong>
        </Grid>
      </Grid> */}
      {renderEstimatedIncome()}
      {renderEstimatedEquity()}
      {renderVehicles()}
    </div>
  </ExpansionPanel>
  )
}

export default ExpansionPanelCreditHistoric
