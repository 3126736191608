import React from 'react'
import DataTable from 'components/DataTable'

export const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
   responsive: 'scroll',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

export const columns = {
  irregular_electoral_accounts_cpf: [
    {
      name: 'process_identifier',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'process_date',
      label: 'Data do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'Cidade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'Cidade',
      label: 'Disponível em',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'process_link',
      label: 'Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'record_link',
      label: 'Gravação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'judgment_link',
      label: 'Julgamento',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  irregular_accounts_cpf: [
    {
      name: 'process_identifier',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'process_date',
      label: 'Data do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'Cidade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'Cidade',
      label: 'Disponível em',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'process_link',
      label: 'Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'record_link',
      label: 'Gravação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'judgment_link',
      label: 'Julgamento',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  unabled_people_cpf: [
    {
      name: 'process_identifier',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'deliberation',
      label: 'Deliberação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'judgment_date',
      label: 'Data do Julgamento',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'final_date',
      label: 'Data Final',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  irregular_bidders_cpf: [
    {
      name: 'process_identifier',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'deliberation',
      label: 'Deliberação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'judgment_date',
      label: 'Data do Julgamento',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'final_date',
      label: 'Data Final',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  ceis_infos_government_base_cpf: [
    {
      name: 'cpfcnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'reference_date',
      label: 'Data de referência',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'start_sanction_date',
      label: 'Início da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'end_sanction_date',
      label: 'Final da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'sanction_publication_date',
      label: 'Data de Publicação da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'transited_judged_date',
      label: 'Data do transitado julgado',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'information_origin_date',
      label: 'Data de origem da informação',
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: 'sanction_type',
    //   label: 'Tipo de Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'source_sanction',
    //   label: 'Fonte da Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'legislation',
    //   label: 'Legislação',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'sanctioning_organ',
    //   label: 'Órgão da Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'sanctioned',
    //   label: 'Sancionado',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: 'publication_text',
      label: 'Texto da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_link',
      label: 'Link da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_link',
      label: 'Link da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_detail',
      label: 'Detalhes da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'process_number',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'related_search',
      label: 'Pesquisas relacionadas',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'additional_information',
      label: 'Informações Adicionais',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  cnep_infos_government_base_cpf: [
    {
      name: 'cpfcnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'reference_date',
      label: 'Data de referência',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'start_sanction_date',
      label: 'Início da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'end_sanction_date',
      label: 'Final da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'sanction_publication_date',
      label: 'Data de Publicação da Sanção',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'transited_judged_date',
      label: 'Data do transitado julgado',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'information_origin_date',
      label: 'Data de origem da informação',
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: 'sanction_type',
    //   label: 'Tipo de Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'source_sanction',
    //   label: 'Fonte da Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'legislation',
    //   label: 'Legislação',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'sanctioning_organ',
    //   label: 'Órgão da Sanção',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //   name: 'sanctioned',
    //   label: 'Sancionado',
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: 'publication_text',
      label: 'Texto da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_link',
      label: 'Link da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_link',
      label: 'Link da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'publication_detail',
      label: 'Detalhes da publicação',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'process_number',
      label: 'Número do Processo',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'related_search',
      label: 'Pesquisas relacionadas',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'additional_information',
      label: 'Informações Adicionais',
      options: {
        filter: true,
        sort: false,
      },
    },
  ],
  ieptb_protests_registry_cpf: [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'uf',
      label: 'UF',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'Cidade',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'protests_number',
      label: 'qtd de protestos',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'protests',
      label: 'Valores',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (values) => {
          const protestsValuesColumns = [
            {
              name: 'protest_date',
              label: 'Data do Protesto',
              options: {
                filter: true,
                sort: false,
              },
            },
            {
              name: 'due_date',
              label: 'Data do Vencimento',
              options: {
                filter: true,
                sort: false,
              },
            },
            {
              name: 'value',
              label: 'Valor',
              options: {
                filter: true,
                sort: false,
              },
            },
          ]

          return (
            <DataTable
              data={values}
              columns={protestsValuesColumns}
              options={options}
            />
          )
        },
      },
    },
  ],
}
