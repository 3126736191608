import _ from 'lodash'

import {
  FETCH_ITEM_FAILED,
  FETCH_ITEM_SUCCEEDED,
  FETCH_LIST_ITEMS,
  FETCH_LIST_ITEMS_FAILED,
  FETCH_LIST_ITEMS_SUCCEEDED,
  LIST_ITEMS_CLEAR,
  UPDATE_LIST_ITEMS_SERVICE_INFO_STATUS,
} from './actions'

const initialState = {
  currentPage: 1,
  fail: false,
  items: [],
  load: false,
  messageError: '',
  perPage: 0,
  total: 0,
  options: {},
}

export const listItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ITEMS_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case FETCH_LIST_ITEMS: {
      return {
        ...state,
        fail: false,
        load: true,
        options: action.payload.options,
      }
    }
    case FETCH_LIST_ITEMS_SUCCEEDED: {
      return {
        ...state,
        fail: false,
        load: false,
        currentPage: action.payload.currentPage,
        items: action.payload.items,
        perPage: action.payload.perPage,
        total: action.payload.total,
      }
    }
    case FETCH_LIST_ITEMS_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }

    case FETCH_ITEM_SUCCEEDED: {
      const items = _.cloneDeep(state.items)
      const itemIndex = _.findIndex(items, ['doc_id', action.payload.doc_id])

      if (itemIndex === -1) {
        items.unshift(action.payload)
      } else {
        items[itemIndex] = action.payload
      }

      return {
        ...state,
        fail: false,
        items: items,
      }
    }
    case FETCH_ITEM_FAILED: {
      return state
    }

    case UPDATE_LIST_ITEMS_SERVICE_INFO_STATUS: {
      const { docId, token, status } = action.payload

      const items = _.cloneDeep(state.items)
      const itemIndex = _.findIndex(state.items, ['doc_id', docId])

      if (itemIndex !== -1) {
        const serviceIndex = _.findIndex(items[itemIndex].service_infos, ['token', token])
        items[itemIndex].service_infos[serviceIndex].status = status
      }

      return {
        ...state,
        items: items,
      }
    }

    default:
      return state
  }
}

export default listItemsReducer
