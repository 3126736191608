import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import {
  selectObjServicesCourtLawsuits,
} from 'redux/servicesRequested/selectors'

import ExpansionPanel from 'components/ExpansionPanel'
import CertidaoRow from 'components/CertidaoRow'
import RowProcess from 'components/RowProcess'

import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'
import { judicialCriminalProceduresRetroCompatibility } from 'helpers/constants/blockServices'
import { options, columns} from './configDatatable'

const ExpansionPanelJudicialCriminalProcedures = ({ blockId }) => {
  const objServicesRequested = useSelector(selectObjServicesCourtLawsuits)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(
    servicesList.data,
    (val) => getDefaultBlockServicesIds(blockId)
              .concat(_.values(judicialCriminalProceduresRetroCompatibility))
              .includes(val.service_id),
  )

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const court = _.get(_.find(servicesList.data, { service_id: 'court_warrant_bnmp_cpf' }), 'data')
               || _.get(_.find(servicesList.data, { service_id: 'court_warrant_bnmp' }), 'data')

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

return (
  <ExpansionPanel
    title={translateTemplateBlockId(blockId)}
    loading={servicesReqLoading}
    consists={consists}
    processed={allProcessed}
    processing={someProcessing}
    slowProcessing={someSlowProcessing}
  >
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {_
        .chain(servicesList.data)
        .sortBy(['name'])
        .map((service) => {
          const serviceId = service.service_id
          const serviceData = service.data || {}

          return serviceId === 'summary_of_processes' ? (
            <RowProcess
              key={(service.data_id || serviceId).toString()}
              title={`${service.name} (pode haver homônimos)`}
              consists={_.parseInt(_.get(service, 'data.total_processes')) > 0}
              docUrl={serviceData.document_url}
              msg_errors={serviceData.msg_errors}
              msg_infos={serviceData.msg_infos}
              status={service.status}
              slowProcessing={service.slow_processing}
              loading={service.load}
              response_time={service.response_time || '59'}
              info={_.get(service, 'data.likely_users')}
              total_processes={_.get(service, 'data.total_processes')}
            />
          ) : (
            <CertidaoRow
              key={(service.data_id || serviceId).toString()}
              title={service.name}
              consists={serviceData.consists}
              docUrl={serviceData.document_url}
              msg_errors={serviceData.msg_errors}
              msg_infos={serviceData.msg_infos}
              status={service.status}
              slowProcessing={service.slow_processing}
              loading={service.load}
              response_time={service.response_time || '59'}
              info={court || []}
              options={options}
              columns={columns}
            />
          )
        }).value()
      }
    </ul>
  </ExpansionPanel>
)}

export default ExpansionPanelJudicialCriminalProcedures
