import React from 'react'
import classNames from 'classnames'
import {
  Checkbox,
  CircularProgress,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { useTheme, withStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProcessAccordion from 'components/ProcessAccordion'
import _ from 'lodash'
import TimeCounter from 'components/TimeCounter'

import styles from './styles'

const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid #cecece',
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    paddingLeft: 0,
  },
})(MuiExpansionPanelSummary)

const RowProcessPJE = ({
  checkboxChecked,
  checkboxDisabled,
  consists,
  icon = true,
  info,
  loading,
  msg_errors,
  msg_infos,
  onChangeCheckbox,
  response_time,
  showCheckbox = false,
  slowProcessing,
  status,
  title,
  total_processes,
}) => {
  const classes = styles()
  const theme = useTheme()

  function getColor() {
    if (status === 'PROCESSING') {
      return slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
    }
    if (status === 'PROCESSED') {
      if (consists) {
        return theme.palette.primary.red
      }
      if (msg_errors && msg_errors.length > 0) {
        return theme.palette.primary.grayLight2
      }
      if (msg_infos && msg_infos.length > 0) {
        return theme.palette.primary.grayLight2
      }
      return theme.palette.primary.green
    } if (status === 'ERROR') {
      return theme.palette.primary.red
    }

    return theme.palette.primary.grayLight
  }

  function renderLoading() {
    return loading && (
      <CircularProgress
        className={classes.progress}
        style={{
          width: 25,
          height: 25,
          marginTop: 5,
        }}
      />
    )
  }

  function renderSecondary() {
    if (msg_errors && msg_errors.length > 0) {
      return msg_errors.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (msg_infos && msg_infos.length > 0) {
      return msg_infos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (status === 'PROCESSING') {
      if (msg_infos && msg_infos.length > 0) {
        return msg_infos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
      }

      return (
        <TimeCounter responseTime={response_time} />
      )
    }
    if (consists) {
      if (total_processes !== '0' && total_processes !== undefined && total_processes !== null){
        return (
          <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>{`Existem ${total_processes} ocorrências`}</span>
        )
      }
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Existe(m) ocorrência(s)</span>
      )
    }
    if (status === 'PROCESSED' && !consists) {
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Nenhuma ocorrência</span>
      )
    }
    return (
      <span />
    )
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={!_.isEmpty(info) ? <ExpandMoreIcon /> : null}
        style={{ cursor: !_.isEmpty(info) ? 'pointer' : 'default' }}
      >
        <ListItem className={classes.certiLine} style={{ color: getColor() }}>
          { showCheckbox && (
            <Checkbox
              checked={checkboxChecked}
              onChange={handleChangeCheckbox()}
              onClick={(e) => e.stopPropagation()}
              disabled={checkboxDisabled}
            />
          )}
          {icon === true && (
            <ListItemAvatar>
              <i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={<span style={{fontWeight: 600, fontSize: 17, display: 'block', color: getColor()}}>{title}</span>}
            secondary={renderSecondary()}
          />
          <ListItemSecondaryAction>
            {renderLoading()}
          </ListItemSecondaryAction>
        </ListItem>
      </ExpansionPanelSummary>

      <div style={{ marginLeft: 30 }}>
        {_.map(info, (item, index) => (
          <ProcessAccordion
            key={index.toString()}
            title={`Processo ${index + 1}`}
            subtitle={item.process_kind}
          >
            <div style={{ marginLeft: 60 }}>
              <p>
                <strong>Polo Ativo: </strong>
                {_.get(item, 'applicants.0.name')}
              </p>
              <p>
                <strong>Polo Passivo: </strong>
                {_.get(item, 'applicants.1.name')}
              </p>
              <p>
                <strong>Número do Processo: </strong>
                {item.process_number}
              </p>
              <p>
                <strong>Última Atualização: </strong>
                {item.last_change}
              </p>
              <p>
                <strong>Data da última Atualização: </strong>
                {item.last_change_date}
              </p>
            </div>
          </ProcessAccordion>
        ))}
      </div>
    </ExpansionPanel>
  )
}

export default RowProcessPJE
