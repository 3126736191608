import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import moment from 'moment'
import DataTable from 'components/DataTable'
import { formatToCPF, formatToPhone } from 'brazilian-values'
import TimeCounter from 'components/TimeCounter'

import {
  Grid,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  selectObjServicesCadastralData,
} from 'redux/servicesRequested/selectors'

import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { cadastralDataRetroCompatibility } from 'helpers/constants/blockServices'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'

import {TitleBold, Line, LineTitle, LineContent } from 'components/styledComponents'
import { options, columnsAddress } from './configDataTable'
import styles from './styles'

const ExpansionPanelCadastralData = ({ blockId }) => {
  const classes = styles()
  const theme = useTheme()

  const objServicesRequested = useSelector(selectObjServicesCadastralData)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(
    servicesList.data,
    (val) => getDefaultBlockServicesIds(blockId)
              .concat(_.values(cadastralDataRetroCompatibility))
              .includes(val.service_id)
  )

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  // remove v2 if v1 already exists
  const hasSearchInfoV1 = !_
    .chain(servicesList.data)
    .find({ service_id: 'search_infos_person_complete' })
    .isEmpty()
    .value()

  if (hasSearchInfoV1) {
    servicesList.data = _.reject(servicesList.data, { service_id: 'search_infos_person_complete_v2' })
  }

  const servicesToConsists = _.reject(servicesList.data, (service) => ['search_infos_person_complete', 'search_infos_person_complete_v2'].includes(service.service_id))
  const consists = !_.isEmpty(servicesList.data) && _.some(servicesToConsists, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const serviceSearchInfos = _.find(objServicesRequested, { service_id: 'search_infos_person_complete_v2' })
                          || _.find(objServicesRequested, { service_id: 'search_infos_person_complete' })
  const person = _.get(serviceSearchInfos, 'data.person')
  const situationCpf = _.find(servicesList.data, { service_id: 'cadastral_situation_cpf' })

  const addresses = []
  let personPhones = []

  if (person) {
    if(person.addresses) {
      person.addresses.map(item => {
        const end = {
          address: `${_.get(item, 'street') || ''}, ${_.get(item, 'number') || ''}, ${_.get(item, 'complement') || ''}`,
          neighborhood: _.get(item, 'neighborhood') || '',
          city: `${_.get(item, 'city') || ''}, ${_.get(item, 'state') || ''}`,
          zipcode: _.get(item, 'zipcode') || '',
        }
        return addresses.push(end)
      })
    }

    personPhones = _.filter(person.phones, (val) => !_.isEmpty(val.phone))
  }


  const renderObits = (service) => {
    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    if (data.cadastral_situation === 'TITULAR FALECIDO') {
      return <span style={{color: theme.palette.primary.red}}>Sim</span>
    }

    return <span style={{ color: theme.palette.primary.green }}>Não</span>
  }

  const renderCpfCadastralSituation = () => {
    const data = _.get(situationCpf, 'data')


    const situation = _.get(data, 'cadastral_situation')
    return situation && (
      <span
        style={{
          marginLeft: 10,
          color: situation === 'REGULAR' ? theme.palette.primary.green : theme.palette.primary.red,
        }}
      >
        {situation}
      </span>
    )
  }

  const renderPisNumber = () => {
    let pisNum = null

    if (person.pis_number) {
      pisNum = person.pis_number
    } else {
      const objPis = _.find(objServicesRequested, { service_id: 'pis_number' })
      pisNum = _.get(objPis, 'data.pis_identifier', null)
    }

    return pisNum && (
      <Line>
        <LineTitle>PIS</LineTitle>
        <LineContent>{pisNum}</LineContent>
      </Line>
    )
  }

  const renderNames = (service) => {
    const socialName = _.get(service, 'data.social_name')
    const civilName = _.get(service, 'data.civil_name')

    return (
      <React.Fragment>
        {civilName && (
          <Line>
            <LineTitle>Nome Civil</LineTitle>
            <LineContent>
              <span style={{ marginRight: '20px' }}>{civilName}</span>
            </LineContent>
          </Line>
        )}
        {socialName && (
          <Line>
            <LineTitle>Nome Social</LineTitle>
            <LineContent>
              <span style={{ marginRight: '20px' }}>{socialName}</span>
            </LineContent>
          </Line>
        )}
      </React.Fragment>
    )
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      {person ? (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <TitleBold>{person.name}</TitleBold>
          <div style={{ display: 'flex', marginTop: 30 }}>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={6}>
                {renderNames(situationCpf)}
                {person.birth_date && (
                  <Line>
                    <LineTitle>Nascimento</LineTitle>
                    <LineContent>
                      <span style={{ marginRight: '20px' }}>{person.birth_date}</span>
                      <span style={{ marginRight: '20px' }}>
                        {`${moment().diff(moment(person.birth_date, 'DD-MM-YYYY'), 'years', false)} anos`}
                      </span>
                    </LineContent>
                  </Line>
                )}

                {person.gender && (
                  <Line>
                    <LineTitle>Gênero</LineTitle>
                    <LineContent>{person.gender}</LineContent>
                  </Line>
                )}

                {person.filiation && (
                  <Line>
                    <LineTitle>Nome da mãe</LineTitle>
                    <LineContent>{person.filiation}</LineContent>
                  </Line>
                )}

                {person.degree && (
                  <Line>
                    <LineTitle>Escolaridade</LineTitle>
                    <LineContent>{person.degree}</LineContent>
                  </Line>
                )}

                {person.cpf && (
                  <Line>
                    <LineTitle>CPF</LineTitle>
                    <LineContent>
                      {formatToCPF(person.cpf)}
                      {renderCpfCadastralSituation()}
                    </LineContent>
                  </Line>
                )}

                <Line>
                  <LineTitle>Informações de óbito</LineTitle>
                  <LineContent>{renderObits(situationCpf)}</LineContent>
                </Line>

                {person.ctps_number && (
                  <Line>
                    <LineTitle>Carteira de Trabalho Série</LineTitle>
                    <LineContent>{person.ctps_number}</LineContent>
                  </Line>
                )}

                {renderPisNumber()}

                {person.zodiac_sign && (
                  <Line>
                    <LineTitle>Signo</LineTitle>
                    <LineContent>{person.zodiac_sign}</LineContent>
                  </Line>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className={classes.column2}>
                {personPhones && personPhones.length > 0 && (
                  <Line>
                    <LineTitle>Telefones Associados</LineTitle>
                    <div>
                      {personPhones.map(phone => {
                        return (
                          <LineContent key={phone.phone}>
                            <span style={{ marginRight: '10px', width: 110, display: 'inline-block', whiteSpace: 'nowrap' }}>
                              {formatToPhone(phone.phone)}
                            </span>
                            {phone.whatsapp === true ? (
                              <span style={{ marginRight: '10px', display: 'inline-block' }}>
                                <img src="assets/images/others/whatsapp.svg" alt="WhatsApp logo" style={{ width: 20, height: 'auto', position: 'relative', top: 5 }} />
                              </span>
                            ) : (
                              <span style={{ marginRight: '10px', display: 'inline-block', width: 20 }} />
                            )}
                            <span style={{ width: 65 }}>
                              {phone.network}
                            </span>
                          </LineContent>
                        )
                      })}
                    </div>
                  </Line>
                )}

                {person.procon && (
                  <Line>
                    <LineTitle>Procon</LineTitle>
                    <LineContent>{person.procon}</LineContent>
                  </Line>
                )}

                {person.emails && (
                  <Line>
                    <LineTitle>Email</LineTitle>
                    <div>
                      {_.map(person.emails, (email, i) => {
                        return <LineContent key={i.toString()}>{email}</LineContent>
                      })}
                    </div>
                  </Line>
                )}
              </Grid>
            </Grid>
          </div>

          {addresses && addresses.length > 0 &&
            (
              <div>
                <TitleBold style={{ marginTop: '30px' }}>Endereços</TitleBold>
                <DataTable
                  data={addresses}
                  columns={columnsAddress}
                  options={options}
                />
              </div>
            )
          }
        </div>
      ) : (
        <div />
      )}
    </ExpansionPanel>
  )
}

export default ExpansionPanelCadastralData
