import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { formatToCPF } from 'brazilian-values'
import { Button, Card, CircularProgress } from '@material-ui/core'

import {
  selectObjPerson,
} from 'redux/servicesRequested/selectors'

import { TitleNormal, TitleBold } from 'components/styledComponents'

import styles from './styles'

const CardNameCpf = ({ onClickButtonClear }) => {
  const classes = styles()

  const objPerson = useSelector(selectObjPerson)
  const servicesReqShowing = useSelector(state => state.servicesRequested.showing)

  if (!servicesReqShowing) return null

  return (
    <Card className={classes.paper} style={{ marginBottom: 20 }}>
      <div className={classes.rowContainer}>
        { _.isEmpty(objPerson)
          ? <CircularProgress />
          : (
            <div style={{ display: 'flex', 'justifyContent': 'space-between' }}>
              <div>
                <TitleNormal>{objPerson.name}</TitleNormal>
                <TitleBold>{formatToCPF(objPerson.cpf)}</TitleBold>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.buttonClear}
                  onClick={onClickButtonClear}
                >
                  Limpar
                </Button>
              </div>
            </div>
          )
        }
      </div>
    </Card>
  )
}

export default CardNameCpf
