import _ from 'lodash'
import { isCPF } from 'brazilian-values'

export const onlyNumbersValid = (string) => {
  return _.isString(string) && /^[0-9]+$/.test(string)
}

export const pisValid = (string) => {
  return _.isString(string) && string.length === 11 && onlyNumbersValid(string)
}

export const cpfValid = (string) => {
  return _.isString(string) && isCPF(string)
}

export const dateValid = (string) => {
  return _.isString(string) && /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(string)
}

export const fullnameValid = (string) => {
  return _.isString(string) && /^\w[\w ]+\w$/.test(string)
}

