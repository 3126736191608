import { all, call, cancel, delay, fork, put, take, takeEvery } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'

import { getCpfReport } from 'services/apiBgc'
import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'
import { selectOptions } from './saga.selectors'

import * as actions from './actions'

function* fetchListItems() {
  yield takeEvery(actions.FETCH_LIST_ITEMS, function* sg(action) {
    const { options } = action.payload

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let resListItems = null
    try {
      resListItems = yield call(getCpfReport, apiKey, userId, options)
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao oter a configuração'
      yield put({ type: actions.FETCH_LIST_ITEMS_FAILED, payload: messageError })
      return
    }

    const payload = {
      items: resListItems.data.cpf_datas,
      total: parseInt(resListItems.headers['x-total'], 10),
      currentPage: parseInt(resListItems.headers['x-page'], 10),
      perPage: parseInt(resListItems.headers['x-per-page'], 10),
    }

    yield put({ type: actions.FETCH_LIST_ITEMS_SUCCEEDED, payload: payload })
  })
}

function* fetchItem() {
  yield takeEvery(actions.FETCH_ITEM, function* sg(action) {
    const { docId, options } = action.payload

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let resListItems = null
    try {
      resListItems = yield call(getCpfReport, apiKey, userId, { ...options, docId: docId })
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao oter a configuração'
      yield put({ type: actions.FETCH_ITEM_FAILED, payload: messageError })
      return
    }

    const payload = resListItems.data.cpf_datas[0]

    yield put({ type: actions.FETCH_ITEM_SUCCEEDED, payload: payload })
  })
}

function* backgroundSyncListItems() {
  try {
    while (true) {
      yield delay(1000 * 60 * 5)
      const options = yield selectOptions()
      yield put({ type: actions.FETCH_LIST_ITEMS, payload: { options } })
    }
  } finally {
    // if (yield cancelled()) // yield put(actions.requestFailure('Sync cancelled!'))
  }
}

function* backgroundSync() {
  while ( yield take(actions.START_BACKGROUND_FETCH_LIST_ITEMS) ) {
    const bgSyncTask = yield fork(backgroundSyncListItems)
    yield take(actions.STOP_BACKGROUND_FETCH_LIST_ITEMS)
    yield cancel(bgSyncTask)
  }
}

export default function* rootSaga() {
  yield all([
    fork(backgroundSync),
    fork(fetchItem),
    fork(fetchListItems),
  ])
}
