export default {
  cadastralData: {
    cadastral_situation_cpf: 'cadastral_situation_cpf',
    search_infos_person_complete_v2: 'search_infos_person_complete_v2',
  },
  cadastralExtension: {
    bacen_qgi_cpf: 'bacen_qgi_cpf',
    ceaf_infos_government_base: 'ceaf_infos_government_base',
    electoralsituation_by_name: 'electoralsituation_by_name',
    eu_sanction_list: 'eu_sanction_list',
    fr_sanction_list: 'fr_sanction_list',
    irpf_refund_cpf: 'irpf_refund_cpf',
    onu_sanction_lists: 'onu_sanction_lists',
    red_notice_interpol: 'red_notice_interpol',
    sanctions_list: 'sanctions_list',
    social_assistance_person: 'social_assistance_person',
    uk_sanction_list: 'uk_sanction_list',
    // unemployment_insurance_by_pis: 'unemployment_insurance_by_pis',
    us_nonproliferation_sanction_list: 'us_nonproliferation_sanction_list',
    work_info_rais_base: 'work_info_rais_base',
    world_bank_ineligible_sanction_list: 'world_bank_ineligible_sanction_list',
  },
  creditHistory: {
    patr_calc_cpf: 'patr_calc_cpf',
  },
  pep: {
    pep_search_v2: 'pep_search_v2',
  },
  negativeMedia: {
    negative_media: 'negative_media',
  },
  protestsAccounts: {
    ceis_infos_government_base_cpf: 'ceis_infos_government_base_cpf',
    certificate_tax_debts_federal_cpf: 'certificate_tax_debts_federal_cpf',
    certificate_tax_debts_sp_cpf: 'certificate_tax_debts_sp_cpf',
    cnep_infos_government_base_cpf: 'cnep_infos_government_base_cpf',
    ieptb_protests_registry_cpf: 'ieptb_protests_registry_cpf',
    irregular_accounts_cpf: 'irregular_accounts_cpf',
    irregular_bidders_cpf: 'irregular_bidders_cpf',
    irregular_electoral_accounts_cpf: 'irregular_electoral_accounts_cpf',
    negative_certificate_irregular_accounts_cpf: 'negative_certificate_irregular_accounts_cpf',
    unabled_people_cpf: 'unabled_people_cpf',
  },
  cvm: {
    cvm_administrative_process: 'cvm_administrative_process',
    cvm_sanctioning_administrative_process: 'cvm_sanctioning_administrative_process',
  },
  certificates: {
    administrative_improbity_cpf: 'administrative_improbity_cpf',
    military_action_stm: 'military_action_stm',
    mpf_extrajudicial_procedures_cpf: 'mpf_extrajudicial_procedures_cpf',
  },
  certificatesTRF: {
    trf1_certificates_cpf: 'trf1_certificates_cpf',
    trf2_certificates_cpf: 'trf2_certificates_cpf',
    trf2_certificates_sjes_cpf: 'trf2_certificates_sjes_cpf',
    trf2_certificates_sjrj_cpf: 'trf2_certificates_sjrj_cpf',
    trf3_certificates_cpf: 'trf3_certificates_cpf',
    trf4_certificates_cpf: 'trf4_certificates_cpf',
    trf5_certificates_criminal_cpf: 'trf5_certificates_criminal_cpf',
    trf5_certificates_distribution_cpf: 'trf5_certificates_distribution_cpf',
    trf5_certificates_electoral_cpf: 'trf5_certificates_electoral_cpf',
  },
  courtLawsuits: {
    court_warrant_bnmp_cpf: 'court_warrant_bnmp_cpf',
    criminal_record_federal_police: 'criminal_record_federal_police',
    stf_process_name: 'stf_process_name',
    stj_process_name: 'stj_process_name',
    summary_of_processes: 'summary_of_processes',
  },
  laborCourts: {
    sit_certificate_cpf: 'sit_certificate_cpf',
    trt2_sp_certificate_cpf: 'trt2_sp_certificate_cpf',
    tst_certificate_cpf: 'tst_certificate_cpf',
    work_slave_cpf: 'work_slave_cpf',
  },
  processesPJE: {
    pje_ba_process_first_instance_cpf: 'pje_ba_process_first_instance_cpf',
    pje_ce_process_first_instance_cpf: 'pje_ce_process_first_instance_cpf',
    pje_df_process_first_instance_cpf: 'pje_df_process_first_instance_cpf',
    pje_es_process_first_instance_cpf: 'pje_es_process_first_instance_cpf',
    pje_ma_process_first_instance_cpf: 'pje_ma_process_first_instance_cpf',
    pje_mg_process_first_instance_cpf: 'pje_mg_process_first_instance_cpf',
    pje_mt_process_first_instance_cpf: 'pje_mt_process_first_instance_cpf',
    pje_pa_process_first_instance_cpf: 'pje_pa_process_first_instance_cpf',
    pje_pb_process_first_instance_cpf: 'pje_pb_process_first_instance_cpf',
    pje_pe_process_first_instance_cpf: 'pje_pe_process_first_instance_cpf',
    pje_pi_process_first_instance_cpf: 'pje_pi_process_first_instance_cpf',
    pje_pr_process_first_instance_cpf: 'pje_pr_process_first_instance_cpf',
    pje_rj_process_first_instance_cpf: 'pje_rj_process_first_instance_cpf',
    pje_rn_process_first_instance_cpf: 'pje_rn_process_first_instance_cpf',
    pje_ro_process_first_instance_cpf: 'pje_ro_process_first_instance_cpf',
    pje_rr_process_first_instance_cpf: 'pje_rr_process_first_instance_cpf',
    pje_rs_process_first_instance_cpf: 'pje_rs_process_first_instance_cpf',
    pje_se_process_first_instance_cpf: 'pje_se_process_first_instance_cpf',
  },
  processesPJESecondInstance: {
    pje_ba_process_second_instance_cpf: 'pje_ba_process_second_instance_cpf',
    pje_ce_process_second_instance_cpf: 'pje_ce_process_second_instance_cpf',
    pje_df_process_second_instance_cpf: 'pje_df_process_second_instance_cpf',
    pje_es_process_second_instance_cpf: 'pje_es_process_second_instance_cpf',
    pje_ma_process_second_instance_cpf: 'pje_ma_process_second_instance_cpf',
    pje_mg_process_second_instance_cpf: 'pje_mg_process_second_instance_cpf',
    pje_mt_process_second_instance_cpf: 'pje_mt_process_second_instance_cpf',
    pje_pb_process_second_instance_cpf: 'pje_pb_process_second_instance_cpf',
    pje_pe_process_second_instance_cpf: 'pje_pe_process_second_instance_cpf',
    pje_pi_process_second_instance_cpf: 'pje_pi_process_second_instance_cpf',
    pje_rj_process_second_instance_cpf: 'pje_rj_process_second_instance_cpf',
    pje_rn_process_second_instance_cpf: 'pje_rn_process_second_instance_cpf',
    pje_ro_process_second_instance_cpf: 'pje_ro_process_second_instance_cpf',
    pje_rr_process_second_instance_cpf: 'pje_rr_process_second_instance_cpf',
  },
  processesESAJ: {
    tj_sp_process_name: 'tj_sp_process_name',
  },
  criminalAntecedentsPC: {
    // Sudeste
    pc_es_certificate_cpf: 'pc_es_certificate_cpf',
    pc_mg_certificate_cpf: 'pc_mg_certificate_cpf',
    pc_rj_certificate_cpf: 'pc_rj_certificate_cpf',
    pc_sp_certificate_cpf: 'pc_sp_certificate_cpf',
    // Centro Oeste
    pc_ms_certificate_cpf: 'pc_ms_certificate_cpf',
    pc_mt_certificate_cpf: 'pc_mt_certificate_cpf',
    // Sul
    pc_pr_certificate_cpf: 'pc_pr_certificate_cpf',
    pc_rs_certificate_cpf: 'pc_rs_certificate_cpf',
    // Norte
    pc_pa_certificate_cpf: 'pc_pa_certificate_cpf',
    // Nordeste
    pc_ba_certificate_cpf: 'pc_ba_certificate_cpf',
    pc_pe_certificate_cpf: 'pc_pe_certificate_cpf',
    pc_se_certificate_cpf: 'pc_se_certificate_cpf',
  },
  criminalAntecedentsTJ: {
    // Sudeste
    // Centro Oeste
    tj_df_certificate_cpf: 'tj_df_certificate_cpf',
    tj_go_certificate_cpf: 'tj_go_certificate_cpf',
    tj_ms_certificate_cpf: 'tj_ms_certificate_cpf',
    // Sul
    tj_rs_certificate_cpf: 'tj_rs_certificate_cpf',
    tj_sc_certificate_cpf: 'tj_sc_certificate_cpf',
    // Norte
    tj_ac_certificate_cpf: 'tj_ac_certificate_cpf',
    tj_am_certificate_cpf: 'tj_am_certificate_cpf',
    tj_ap_certificate_cpf: 'tj_ap_certificate_cpf',
    tj_ro_certificate_cpf: 'tj_ro_certificate_cpf',
    tj_rr_certificate_cpf: 'tj_rr_certificate_cpf',
    tj_to_certificate_cpf: 'tj_to_certificate_cpf',
    // Nordeste
    tj_al_certificate_cpf: 'tj_al_certificate_cpf',
    tj_ba_certificate_cpf: 'tj_ba_certificate_cpf',
    // tj_ce_certificate_cpf: 'tj_ce_certificate_cpf',
    tj_ma_certificate_cpf: 'tj_ma_certificate_cpf',
    tj_pb_certificate_cpf: 'tj_pb_certificate_cpf',
    tj_pi_certificate_cpf: 'tj_pi_certificate_cpf',
    tj_rn_certificate_cpf: 'tj_rn_certificate_cpf',
    tj_se_certificate_cpf: 'tj_se_certificate_cpf',
  },
}

export const pepRetroCompatibility = {
  pep_infos_government_base: 'pep_infos_government_base',
}

export const cadastralDataRetroCompatibility = {
  search_infos_person_complete: 'search_infos_person_complete',
}

export const judicialCriminalProceduresRetroCompatibility = {
  court_warrant_bnmp: 'court_warrant_bnmp',
}
