export const TEMPLATE_CONFIG_CLEAR = 'TEMPLATE_CONFIG_CLEAR'

export const FETCH_TEMPLATE_CONFIG = 'FETCH_TEMPLATE_CONFIG'
export const FETCH_TEMPLATE_CONFIG_FAILED = 'FETCH_TEMPLATE_CONFIG_FAILED'
export const FETCH_TEMPLATE_CONFIG_SUCCEEDED = 'FETCH_TEMPLATE_CONFIG_SUCCEEDED'

export const SAVE_TEMPLATE_CONFIG = 'SAVE_TEMPLATE_CONFIG'
export const SAVE_TEMPLATE_CONFIG_FAILED = 'SAVE_TEMPLATE_CONFIG_FAILED'
export const SAVE_TEMPLATE_CONFIG_SUCCEEDED = 'SAVE_TEMPLATE_CONFIG_SUCCEEDED'

export const UPDATE_TEMPLATE_CONFIG_BLOCKS = 'UPDATE_TEMPLATE_CONFIG_BLOCKS'

export const templateConfigClear = () => ({
  type: TEMPLATE_CONFIG_CLEAR,
})

export const fetchTemplateConfig = (templateName) => ({
  type: FETCH_TEMPLATE_CONFIG,
  payload: templateName,
})

export const saveTemplateConfig = () => ({
  type: SAVE_TEMPLATE_CONFIG,
})

export const updateTemplateConfigBlocks = (blocks) => ({
  type: UPDATE_TEMPLATE_CONFIG_BLOCKS,
  payload: blocks,
})
