import _ from 'lodash'

const mappedInputs = {
  pep_infos_government_base: {
    query: 'name',
  },
  trt2_sp_certificate_cpf: {
    fullname: 'name',
  },
  summary_of_processes: {
    q: 'name',
  },
}

export const mappedInput = (serviceId, key) => {
  const mapped = _.get(mappedInputs, `${serviceId}.${key}`)
  if (_.isPlainObject(mapped)) return undefined

  return mapped
}

export const originalInput = (serviceId, mapped) => {
  const service = mappedInputs[serviceId]
  if (!_.isPlainObject(service)) return undefined

  return _.invert(service)[mapped]
}
