import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  icon: {
    fontSize: 30,
  },
  listItemContainer: {
    listStyle: 'none',
    padding: 0,
  },
  certiLine: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 17,
    listStyle: 'none',
    paddingTop: 10,
    padding: 0,
  },
}))

export default styles
