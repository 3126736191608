import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  selectObjServicesPEP,
} from 'redux/servicesRequested/selectors'

import TimeCounter from 'components/TimeCounter'

import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { pepRetroCompatibility } from 'helpers/constants/blockServices'
import { translateService, translateServiceName } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'
import { formatToCPF, formatToCNPJ } from 'helpers/masks'

import { renderCheckService } from '../utilsFunctions'

import styles from './styles'

const ExpansionPanelPEP = ({ blockId }) => {
  const classes = styles()
  const theme = useTheme()

  const objServicesRequested = useSelector(selectObjServicesPEP)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)

  let blockServices = block.services

  blockServices = [
    ...blockServices,
  ]

  blockServices = _
    .chain(blockServices)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })

  const allServiceIds = _.concat(getDefaultBlockServicesIds(blockId), _.values(pepRetroCompatibility))
  servicesList.data = _.filter(servicesList.data, (val) => allServiceIds.includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const renderPepV1 = (service) => {
    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'NEED_INPUTS') {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    // const msgInfos = _.get(data, 'msg_infos')
    // if (!_.isEmpty(msgInfos)) {
    //   return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    // }

    if(_.isArray(data) && data.length > 0) {
      return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
    }

    return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
  }

  const renderPepV2 = (service, isRelatedPerson = false) => {
    if (isRelatedPerson !== true) {
      const elemCheck = renderCheckService(service, theme)
      if (elemCheck !== null) return elemCheck
    }

    const data = isRelatedPerson !== true ? _.get(service, 'data') : service

    if (!_.get(data, 'consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    const {
      jobs,
      office,
      partners,
      pep_level: pepLevel,
      pep_partners: pepPartners,
      pep_reason: pepReason,
      pep_relateds: pepRelateds,
      political_party_office: politicalPartyOffice,
    } = data

    const pepLevelText = {
      HIGH_RISK: 'Alto Risco',
      MEDIUM_RISK: 'Médio Risco',
      LOW_RISK: 'Baixo Risco',
      RELATED_PERSON: 'Pessoa Relacionada',
      NOT_PEP: 'Não é PEP',
    }[pepLevel] || pepLevel

    const pepReasonText = {
      NOT_PEP: 'Não é PEP',
      PEP_PARTNER: 'Sócio de PEP',
      PEP_RELATED: 'Pessoa relacionada PEP',
      POLITICAL_PARTY_LEADERSHIP: 'Possui cargo de liderança partidária',
      PUBLIC_SERVER: 'Servidor Público',
      RESTRICTIVE_LIST: 'Consta em lista restritiva',
    }[pepReason] || pepReason

    return (
      <div className={classes.pepData}>
        <div className={classes.pepDataItem}>
          <strong style={{ color: theme.palette.primary.red, marginRight: 30 }}>Sim</strong>
          <span style={{ marginLeft: 30 }}>Nível:</span>
          <strong style={{ color: theme.palette.primary.red, marginLeft: 5 }}>{pepLevelText}</strong>
          <span style={{ marginLeft: 30 }}>Razão:</span>
          <strong style={{ color: theme.palette.primary.red, marginLeft: 5 }}>{pepReasonText}</strong>
        </div>

        {!_.isEmpty(office) && (
          <div className={classes.pepDataItem}>
            <span>Cargo Político:</span>
            <span style={{ marginLeft: 5 }}>{office}</span>
          </div>
        )}
        {!_.isEmpty(politicalPartyOffice) && (
          <div className={classes.pepDataItem}>
            <span>Cargo de liderança politica:</span>
            <span style={{ marginLeft: 5 }}>{politicalPartyOffice}</span>
          </div>
        )}

        {!_.isEmpty(jobs) && ['PUBLIC_SERVER'].includes(pepReason) && (
          <div className={classes.pepDataItem}>
            <span>Trabalhos:</span>
            <div style={{ marginLeft: 5 }}>
              {_.map(jobs, (job, i) => (
                <div key={i.toString()}>
                  <span>{_.chain(job).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCNPJ(job.cnpj)}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(partners) && ['PEP_PARNER'].includes(pepReason) && (
          <div className={classes.pepDataItem}>
            <span>Sócios:</span>
            <div style={{ marginLeft: 5 }}>
              {_.map(partners, (partner, i) => (
                <div key={i.toString()}>
                  <span>{_.chain(partner).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCNPJ(partner.cnpj)}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(pepPartners) && (
          <div className={classes.pepDataItem}>
            <span>Parentes PEP:</span>
            <div style={{ marginLeft: 5, marginTop: 15 }}>
              {_.map(pepPartners, (pepPartner, i) => (
                <div key={i.toString()} style={{ marginTop: 5 }}>
                  <span>{_.chain(pepPartner).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCPF(pepPartner.cpf)}</span>
                  {renderPepV2(pepPartner, true)}
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(pepRelateds) && (
          <div className={classes.pepDataItem}>
            <span>Relacionados PEP:</span>
            <div style={{ marginLeft: 5, marginTop: 15 }}>
              {_.map(pepRelateds, (pepRelated, i) => (
                <div key={i.toString()} style={{ marginTop: 5 }}>
                  <span>{_.chain(pepRelated).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCPF(pepRelated.cpf)}</span>
                  {renderPepV2(pepRelated, true)}
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderPep = () => {
    const pepV2 = _.find(servicesList.data, { service_id: 'pep_search_v2' })
    if (!_.isEmpty(_.get(pepV2, 'status'))) return renderPepV2(pepV2)

    const pepV1 = _.find(servicesList.data, { service_id: 'pep_infos_government_base' })
    if (!_.isEmpty(_.get(pepV1, 'status'))) return renderPepV1(pepV1)

    return renderPepV2()
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{translateServiceName('pep_search_v2')}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {renderPep()}
          </Grid>
        </Grid>
      </div>
    </ExpansionPanel>
  )
}

export default ExpansionPanelPEP
