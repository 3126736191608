import { all, call, cancel, debounce, delay, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { isCPF } from 'brazilian-values'

import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'
import { selectTemplate } from 'redux/templateConfig/saga.selectors'
import { fetchItem, fetchListItems, updateListItemServiceInfoStatus } from 'redux/listItems/actions'
import * as listItemsActions from 'redux/listItems/actions'
import { selectItems, selectCurrentPage } from 'redux/listItems/saga.selectors'
import { templateServicesNotInObjServices } from 'helpers/functions'
import { mappedInput } from 'helpers/mapInputs'
import { populateInput } from 'helpers/populateInputs'
import { canNotJoinInput } from 'helpers/mapNotJoinInputs'
import servicesDocsConstants from 'helpers/constants/servicesDocs'
import { getAllServices } from 'helpers/translateServicesInfos'
import { getCpfService, postCpfCreate, patchCpfInsert, patchCpfUpdate } from 'services/apiBgc'
import { selectObjServices, selectTypeId, selectValue, selectValueValid, selectDocIdSelected, selectShowing } from './saga.selectors'
import * as actions from './actions'

const mapNestedInputValue = (properties) => {
  return _.reduce(properties, (result, val, key) => ({ ...result, [key]: val.value }), {})
}

const blacklistServicesIds = (serviceId) => {
  const blacklistServiceIds = [
    'cnpj_infos_federal_revenue',
    'membership_board',
  ]

  return blacklistServiceIds.includes(serviceId)
}

const insertIrpfs = (services, objServices) => {
  const newServices = _.cloneDeep(services)
  const irpfToCall = _.find(newServices, { service_id: 'irpf_refund_cpf' })

  if (_.isEmpty(irpfToCall)) {
    return services
  }

  const existsIrpf = (year) => (
    !_.isEmpty(_.find(objServices, { service_id: 'irpf_refund_cpf', inputs: { year: { value: year } } }))
  )

  const currentYear = (new Date()).getFullYear()
  const currentMonth = (new Date()).getMonth()
  const decreaseYear = currentMonth < 5 ? 1 : 0 // less than june
  const yearFirst = (currentYear - decreaseYear).toString()
  const yearSecond = (currentYear - decreaseYear - 1).toString()
  const yearThird = (currentYear - decreaseYear - 2).toString()

  if (!existsIrpf(yearFirst)) {
    _
    .chain(newServices)
    .find({ service_id: 'irpf_refund_cpf' })
    .set('inputs.year', yearFirst)
    .value()
  }
  if (!existsIrpf(yearSecond)) {
    const newIrpf = _.cloneDeep(irpfToCall)
    _.set(newIrpf, 'inputs.year', yearSecond)
    newServices.push(newIrpf)
  }
  if (!existsIrpf(yearThird)) {
    const newIrpf = _.cloneDeep(irpfToCall)
    _.set(newIrpf, 'inputs.year', yearThird)
    newServices.push(newIrpf)
  }

  return newServices
}

function* clearServices() {
  yield takeLatest(actions.SERVICES_REQUESTED_CLEAR, function* sg() {
    yield put({ type: actions.STOP_BACKGROUND_CHECK_SERVICES_STATUS })
    yield put({ type: actions.SERVICES_REQUESTED_CLEAR_SUCCEEDED })
  })
}

function* postServices() {
  yield debounce(300, actions.POST_SERVICES, function* sg() {
    const valueValid = yield selectValueValid()
    if (valueValid !== true) return

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()
    const typeId = yield selectTypeId()
    const value = yield selectValue()
    const template = yield selectTemplate()
    const docIdSelected = yield selectDocIdSelected()
    const objServices = yield selectObjServices()

    let servicesCheckeds = templateServicesNotInObjServices(template.blocks, objServices)
    servicesCheckeds = _.reject(servicesCheckeds, (val) => blacklistServicesIds(val.service_id))

    // TODO: quando o servidor do linker for juntado com o bgcapi,
    // esse parte de adicionar os inputs não serão necessários, será feito pelo backend
    let servicesInputs = _.map(servicesCheckeds, (val) => {
      const inputs = _.get(servicesDocsConstants, `${val.service_id}.inputs`)
      const newInputs = _
        .chain(inputs)
        .pickBy((input) => input.required === true)
        .reduce((result, _val, inputKey) => {
          const currentInput = _.find(val.inputs, { name: inputKey })
          const newValue = inputKey === 'cpf' ? value : null
          return { ...result, [inputKey]: _.get(currentInput, 'value', newValue) }
        }, {})
        .value()

      return { service_id: val.service_id, inputs: newInputs }
    })

    const showingSelected = yield selectShowing()
    if (showingSelected === true) {
      const objServicesFiltered = _
        .chain(objServices)
        .filter((val) => val.status === 'NEED_INPUTS' || val.toCall)
        .value()

      _.forEach(objServicesFiltered, (val) => {
        let serviceInput = _.find(servicesInputs, { objId: val.objId })
        if (_.isEmpty(serviceInput)) {
          serviceInput = { service_id: val.service_id }
          servicesInputs.push(serviceInput)
        }

        if (!_.isEmpty(val.data_id)) serviceInput.data_id = val.data_id

        _
        .chain(val.inputs)
        .pickBy((inputVal, _key) => {
          if (inputVal.required === true) return true
          if (!_.isEmpty(inputVal.value)) return true

          return false
        })
        .forEach((inputVal, inputKey) => {
          if (!_.isEmpty(_.get(serviceInput, `inputs.${inputKey}`))) return

          const inputValue = _.has(inputVal, 'properties')
            ? mapNestedInputValue(_.get(inputVal, 'properties'))
            : inputVal.value
          _.set(serviceInput, `inputs.${inputKey}`, inputValue)
        })
        .value()
      })
    }

    servicesInputs = insertIrpfs(servicesInputs, objServices)

    let newDocId = docIdSelected
    try {
      if (_.isEmpty(docIdSelected)) {
        const res = yield call(postCpfCreate, apiKey, userId, value, servicesInputs)
        newDocId = res.data.doc_id
      } else {
        const servicesToUpdate = _.filter(servicesInputs, 'data_id')
        const servicesToInsert = _.reject(servicesInputs, 'data_id')

        if (!_.isEmpty(servicesToInsert)) {
          yield call(patchCpfInsert, apiKey, userId, docIdSelected, servicesToInsert)
        }
        if (!_.isEmpty(servicesToUpdate)) {
          yield call(patchCpfUpdate, apiKey, userId, docIdSelected, servicesToUpdate)
        }
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Não foi possível realizar a solicitação'
      yield put({ type: actions.POST_SERVICES_FAILED, payload: messageError })
      return
    }

    const currentPage = yield selectCurrentPage()
    if (_.isEmpty(docIdSelected) && currentPage === 1) {
      yield put(fetchListItems(typeId))
    }

    yield put({ type: actions.POST_SERVICES_SUCCEEDED, payload: { docId: newDocId } })

    yield put(actions.stopCheckServicesStatus())
    yield put(actions.checkServicesStatus())
  })
}

function* fetchServicesDocs() {
  yield takeEvery([actions.ADD_SERVICES_TO_CALL, actions.UPDATE_SERVICES_TO_CALL], function* sg(action) {
    const objServices = yield selectObjServices()
    const objIds = _
    .chain(objServices)
    .filter((val) => {
      if (val.toCall !== true) return false
      if (action.type === actions.ADD_SERVICES_TO_CALL && !action.payload.includes(val.service_id)) return false
      if (action.type === actions.UPDATE_SERVICES_TO_CALL && !action.payload.includes(val.objId)) return false

      return true
    })
    .map('objId')
    .value()

    yield put({ type: actions.ADD_SERVICES_TO_CALL_SUCCEEDED, payload: objIds })
  })
}

// TODO: mover o codigo dessa funcao e apaga-la
function* fetchServicesDocsSucceeded() {
  yield takeEvery(actions.ADD_SERVICES_TO_CALL_SUCCEEDED, function* sg(action) {
    const objServices = yield selectObjServices()
    const value = yield selectValue()
    const valueValid = yield selectValueValid()

    const objIds = action.payload

    // get doc and insert inputs
    const inputsServices = []
    for (let i = 0; i < objIds.length; i += 1) {
      const objId = objIds[i]
      const serviceId = _.find(objServices, { objId: objId }).service_id

      const serviceDoc = servicesDocsConstants[serviceId]
      if (!_.isEmpty(serviceDoc)) {
        let { inputs } = serviceDoc
        delete inputs.token

        const inputKeys = Object.keys(inputs)
        inputKeys.forEach(inputKey => {
          const mappedKey = mappedInput(serviceId, inputKey)
          if (canNotJoinInput(serviceId, inputKey)) return

          const popVal = populateInput(objServices, mappedKey || inputKey)
          if (!_.isEmpty(popVal)) {
            inputs[inputKey].populated = true
            inputs[inputKey].valid = true
            inputs[inputKey].value = popVal
            return
          }

          // insert values of similar inputs
          _.forEach(objServices, (objService) => {
            if (inputKey === 'cpf') { // TODO: mapear outros campos
              inputs[inputKey].populated = true
              inputs[inputKey].valid = valueValid
              inputs[inputKey].value = value
            } else {
              const input = objService.inputs && objService.inputs[mappedKey || inputKey]

              if (!_.isEmpty(input)) {
                const inputsNested = inputs[inputKey].properties

                if (_.isEmpty(inputsNested)) {
                  if (
                    _.isEmpty(inputs[inputKey].updatedAt) ||
                    (!_.isEmpty(input.updatedAt) && inputs[inputKey].updatedAt.isBefore(input.updatedAt))
                  ) {
                    inputs[inputKey].populated = input.populated
                    inputs[inputKey].valid = input.valid
                    inputs[inputKey].valid = inputs[inputKey].required ? (input.valid && !_.isEmpty(input.value)) : input.valid
                    inputs[inputKey].value = input.value
                    inputs[inputKey].updatedAt = input.updatedAt
                  }
                } else {
                  _.forEach(inputsNested, (nestedVal, nestedKey) => {
                    if (!_.isEmpty(input.properties)) {
                      if (!_.isEmpty(nestedVal) && !_.isEmpty(input.properties[nestedKey])) {
                        if (
                          _.isEmpty(nestedVal.updatedAt) ||
                          (!_.isEmpty(input.updatedAt) && nestedVal.updatedAt.isBefore(input.updatedAt))
                        ) {
                          nestedVal.populated = input.populated
                          nestedVal.valid = nestedVal.required ? (input.properties[nestedKey].valid && !_.isEmpty(input.properties[nestedKey].value)) : input.properties[nestedKey].valid
                          nestedVal.value = input.properties[nestedKey].value
                          nestedVal.updatedAt = input.updatedAt
                        }
                      }
                    }
                  })
                }

              }
            }
          })
        })

        // added valid: true in field with value empty and required !== true
        inputs = _.reduce(inputs, function it(result, val, key) {
          const newResult = result
          const newVal = val

          if (!_.isEmpty(newVal.properties)) {
            newVal.properties = _.reduce(newVal.properties, it, newVal.properties)
          } else if (_.isEmpty(newVal.value) && newResult[key].required !== true) {
            newResult[key].valid = true
          }

          return newResult
        }, inputs)

        inputsServices.push({
          objId: objId,
          inputs: inputs,
        })
      }
    }

    if (!_.isEmpty(inputsServices)) {
      yield put({ type: actions.ADD_INPUTS_IN_SERVICES, payload: inputsServices })
    }
  })
}

function* setValue() {
  yield debounce(500, actions.SET_VALUE, function* sg(action) {
    const value = action.payload
    const valid = isCPF(value)

    yield put({
      type: actions.SET_VALUE_SUCCEEDED,
      payload: { value: value, valueValid: valid },
    })

    const objServices = yield selectObjServices()

    for (let i = 0; i < objServices.length; i += 1) {
      const { inputs, objId } = objServices[i]

      if (Object.keys(inputs || {}).some(inputKey => inputKey === 'cpf')) { // TODO: colocar outros campos
        yield put(actions.addValInInputServices([
          { objId: objId, inputKey: 'cpf', value: value },
        ]))
      }
    }
  })
}

function* addValInInput() {
  // TODO: usar debounce baseado no serviceId vindo da action
  yield takeEvery(actions.ADD_VAL_IN_INPUT_SERVICE, function* sg(action) {
    yield put({ type: actions.ADD_VAL_IN_INPUT_SERVICE_SUCCEEDED, payload: action.payload })
  })
}

function* reqGetService() {
  yield takeEvery(actions.SERVICES_REQUESTED_GET_SERVICE, function* sg(action) {
    const { dataId } = action.payload

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()
    const objsServices = yield selectObjServices()
    const docIdSelected = yield selectDocIdSelected()

    const objService = _.find(objsServices, { data_id: dataId })

    if (!_.isEmpty(objService.data)) return
    if (!objService.token) return

    let { status } = objService

    try {
      const response = yield call(getCpfService, apiKey, userId, dataId)
      status = response.data.status
      switch (status) {
        case 'PROCESSED':
          break
        case 'PROCESSING':
          if (_.isEmpty(response.data)) return
          break
        default:
          return
      }

      // TODO: checar se tem msg_errors e msg_infos

      if (status !== objService.status) {
        yield put(updateListItemServiceInfoStatus(docIdSelected, objService.token, status))
      }

      yield put({
        type: actions.SERVICES_REQUESTED_GET_SERVICE_SUCCEEDED,
        payload: { dataId: dataId, data: response.data.data, status: status },
      })
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      yield put({
        type: actions.SERVICES_REQUESTED_GET_SERVICE_FAILED,
        payload: { dataId: dataId, message: err.message }, // TODO: refatorar
      })
    }
  })
}

function* reqGetLinkerAnalytical() {
  yield takeLatest(actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL, function* sg(action) {
    const { servicesIds } = action.payload

    const typeId = yield selectTypeId()
    const docIdSelected = yield selectDocIdSelected()
    const objServices = yield selectObjServices()
    const listItems = yield selectItems()

    if (_.isEmpty(typeId) || _.isEmpty(docIdSelected)) return

    const itemSelected = _.find(listItems, ['doc_id', docIdSelected])

    try {
      const servicesInfos = itemSelected.service_infos
      if (_.isEmpty(servicesInfos)) return

      let newObjServices = servicesInfos

      if (!_.isEmpty(servicesIds)) {
        newObjServices = _.filter(
          newObjServices,
          (val) => servicesIds.includes(val.service_id) || !_.find(objServices, ['data_id', val.data_id])
        )
      }

      const insertValueInInputs = (inputs, docInputs) => {
        return _.reduce(inputs, (resu, inputVal, inputKey) => {
          const properties = _.get(docInputs[inputKey], 'properties')
          if (!_.isEmpty(properties)) {
            return {
              ...resu,
              [inputKey]: {
                ...docInputs[inputKey],
                properties: insertValueInInputs(inputVal, properties),
              },
            }
          }

          return {
            ...resu,
            [inputKey]: {
              ...docInputs[inputKey],
              value: inputVal,
              populated: !!inputVal,
              valid: !!inputVal,
            },
          }
        }, {})
      }

      // add fields in inputs
      newObjServices = _.map(newObjServices, (val) => {
        const inputs = _.get(servicesDocsConstants, `${val.service_id}.inputs`, {})
        const newInputs = insertValueInInputs(val.inputs, inputs)

        return { ...val, inputs: newInputs }
      })

      yield put({
        type: actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED,
        payload: { objServices: newObjServices, servicesIds: servicesIds },
      })
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      yield put({
        type: actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_FAILED,
        payload: { message: err.message }, // TODO: refatorar
      })
    }
  })
}

function* reqGetLinkerAnalyticalSucceeded() {
  yield takeLatest(actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED, function* sg(action) {
    const { servicesIds } = action.payload
    const objServices = yield selectObjServices()

    const dataIdsFiltered = _
    .chain(objServices)
    .filter((val) => {
      if (val.status === 'PROCESSING' && val.slow_processing !== true) return false // TODO: passar a string para constante
      if (val.status === 'NEED_INPUTS') return false // TODO: passar a string para constante
      if (_.isEmpty(val.token)) return false
      if (!_.isEmpty(val.data)) return false
      if (!_.isEmpty(servicesIds) && !servicesIds.includes(val.service_id)) return false

      return true
    })
    .map('data_id')
    .value()

     yield all(
       dataIdsFiltered.map(dataId => {
         return put(actions.servicesRequestedGetService({ dataId }))
       })
     )
  })
}

function* bgCheckStatusServices() {
  const docIdSelected = yield selectDocIdSelected()

  try {
    // TODO: adicionar um contador pra não ficar executando ad aeternum

    let delayTime = 0
    let servicesIdsProcessing = []
    do {
      yield delay(delayTime * 1000)

      yield put(fetchItem(docIdSelected))
      yield take(listItemsActions.FETCH_ITEM_SUCCEEDED)

      yield put({
        type: actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL,
        payload: { servicesIds: servicesIdsProcessing },
      })

      yield take(actions.SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED)

      const objServices = yield selectObjServices()

      servicesIdsProcessing = _
      .chain(objServices)
      .filter((val) => {
        if (val.status === 'PROCESSING') return true // TODO: passar a string para constante

        return false
      })
      .map('service_id')
      .value()

      if (servicesIdsProcessing.length === 0) break

      if (
        servicesIdsProcessing.includes('search_infos_person_complete')
        || servicesIdsProcessing.includes('search_infos_person_complete_v2')
      ) {
        delayTime = 5
      } else {
        delayTime = _
        .chain(getAllServices())
        .pick(servicesIdsProcessing)
        .map(info => parseInt(info.response_time, 10))
        .min()
        .value() || 180
      }
    } while (true)

    yield put({ type: actions.STOP_BACKGROUND_CHECK_SERVICES_STATUS })
  } finally {
    // if (yield cancelled()) yield put(actions.requestFailure('Sync cancelled!'))
  }
}

function* checkServicesStatus() {
  while ( yield take(actions.START_BACKGROUND_CHECK_SERVICES_STATUS) ) {
    const bgCheckStatus = yield fork(bgCheckStatusServices)
    yield take(actions.STOP_BACKGROUND_CHECK_SERVICES_STATUS)
    yield cancel(bgCheckStatus)
  }
}

export default function* rootSaga() {
  yield all([
    fork(addValInInput),
    fork(checkServicesStatus),
    fork(clearServices),
    fork(fetchServicesDocs),
    fork(fetchServicesDocsSucceeded),
    fork(postServices),
    fork(reqGetLinkerAnalytical),
    fork(reqGetLinkerAnalyticalSucceeded),
    fork(reqGetService),
    fork(setValue),
  ])
}
