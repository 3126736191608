import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Grid,
} from '@material-ui/core'

import { MaskInput } from 'components/MaskedInput'
import { withStyles } from '@material-ui/styles'

import {
  checkServicesStatus,
  servicesRequestedClear,
  setTypeId,
  setValue,
  stopCheckServicesStatus,
} from 'redux/servicesRequested/actions'

import {
  fetchTemplateConfig,
  saveTemplateConfig,
} from 'redux/templateConfig/actions'

import ExpansionPanelCadastralData from 'components/ExpansionPanelCadastralData'
import ExpansionPanelCadastralExtension from 'components/ExpansionPanelCadastralExtension'
import ExpansionPanelPEP from 'components/ExpansionPanelPEP'
import ExpansionPanelCertificates from 'components/ExpansionPanelCertificates'
import ExpansionPanelCertificatesTRF from 'components/ExpansionPanelCertificatesTRF'
import ExpansionPanelCreditHistoric from 'components/ExpansionPanelCreditHistoric'
import ExpansionPanelCriminalAntecedentPC from 'components/ExpansionPanelCriminalAntecedentPC'
import ExpansionPanelCriminalAntecedentTJ from 'components/ExpansionPanelCriminalAntecedentTJ'
import ExpansionPanelCvm from 'components/ExpansionPanelCvm'
import ExpansionPanelJudicialCriminalProcedures from 'components/ExpansionPanelJudicialCriminalProcedures'
import ExpansionPanelNegativeMedia from 'components/ExpansionPanelNegativeMedia'
import ExpansionPanelProcessesESAJ from 'components/ExpansionPanelProcessesESAJ'
import ExpansionPanelProcessesPJE from 'components/ExpansionPanelProcessesPJE'
import ExpansionPanelProcessesPJESecondInstance from 'components/ExpansionPanelProcessesPJESecondInstance'
import ExpansionPanelProtestsAccounts from 'components/ExpansionPanelProtestsAccounts'
import ExpansionPanelWorkCourts from 'components/ExpansionPanelWorkCourts'
import ContainerNeedInputs from 'components/ContainerNeedInputs'
import ListCpfCnpj from 'components/ListCpfCnpj'
import CardNameCpf from 'components/CardNameCpf'
import TemplateConfig from 'components/TemplateConfig'
import onlyNumbers from 'helpers/onlyNumbers'
import { isBlockChecked } from 'helpers/constants/defaultTemplateConfigBlocks'
import styles from './styles'

const typeId = 'CPF'

const Dashboard = () => {
  const classes = styles()

  const [showTemplateConfig, setShowTemplateConfig] = useState(false)

  const dispatch = useDispatch()

  const servicesReqShowing = useSelector(state => state.servicesRequested.showing)
  const valueValid = useSelector(state => state.servicesRequested.valueValid)
  const valueCurrent = useSelector(state => state.servicesRequested.value)

  const template = useSelector(state => state.templateConfig.template)

  useEffect(() => {
    dispatch(setTypeId(typeId))
  }, [dispatch])

  useEffect(() => {
    // TODO: chamar api para obter os nomes dos templates do usuario e apos isso chamar o fetch
    dispatch(fetchTemplateConfig(template.template_name))
  }, [dispatch, template.template_name])

  const handleChange = (_name) => event => {
    dispatch(setValue(onlyNumbers(event.target.value)))
  }

  const handleClickClear = () => {
    dispatch(servicesRequestedClear())
    dispatch(setTypeId(typeId))
  }

  const handleUpdateServices = () => {
    dispatch(stopCheckServicesStatus())
    dispatch(checkServicesStatus())
  }

  const handleShowTemplateConfig = () => {
    if (showTemplateConfig === true) {
      dispatch(saveTemplateConfig())
    }
    setShowTemplateConfig(!showTemplateConfig)
  }

  /* eslint-disable-next-line no-unused-vars */
  const renderButtonUpdateAllServices = () => (
    servicesReqShowing && (
      <Button
        variant="contained"
        size="small"
        className={classes.buttonAction}
        onClick={handleUpdateServices}
      >
        Atualizar Todos
      </Button>
    )
  )

  const renderButtonTemplateConfig = () => (
    <Button
      variant="contained"
      size="small"
      className={classes.buttonAction}
      onClick={handleShowTemplateConfig}
    >
      {showTemplateConfig !== true ? 'Configurar' : 'Salvar e Voltar'}
    </Button>
  )

  return (
    <div className={classes.root}>

      <main className={classes.main}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          {renderButtonTemplateConfig()}
          {/* renderButtonUpdateAllServices() */}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>

            { showTemplateConfig
              ? <TemplateConfig />
              : (
                <div>
                  <CardNameCpf onClickButtonClear={handleClickClear} />

                  <Card className={classes.paper}>
                    <div className={classes.rowContainer}>
                      {
                        !servicesReqShowing && (
                        <MaskInput
                          className={classes.textField}
                          label="Inserir CPF"
                          onChange={handleChange('cpf')}
                          name="cpf"
                          variant="outlined"
                          value={valueCurrent}
                          disabled={false} // TODO: set from state
                          errorMsg={valueValid ? null : 'CPF inválido'}
                        />
                      )}
                      <ContainerNeedInputs />
                    </div>
                  </Card>
                </div>
              )
            }

            <ListCpfCnpj typeId={typeId} />
          </Grid>

          <Grid item xs={12} md={9}>
            { isBlockChecked(template.blocks, 'CADASTRAL_DATA') && <ExpansionPanelCadastralData blockId="CADASTRAL_DATA" /> }

            { isBlockChecked(template.blocks, 'CADASTRAL_EXTENSION') && <ExpansionPanelCadastralExtension blockId="CADASTRAL_EXTENSION" /> }

            { isBlockChecked(template.blocks, 'CREDIT_HISTORY') && <ExpansionPanelCreditHistoric blockId="CREDIT_HISTORY" /> }

            { isBlockChecked(template.blocks, 'PEP') && <ExpansionPanelPEP blockId="PEP" /> }

            { isBlockChecked(template.blocks, 'NEGATIVE_MEDIA') && <ExpansionPanelNegativeMedia blockId="NEGATIVE_MEDIA" /> }

            { isBlockChecked(template.blocks, 'PROTESTS_ACCOUNTS') && <ExpansionPanelProtestsAccounts blockId="PROTESTS_ACCOUNTS" /> }

            { isBlockChecked(template.blocks, 'CVM') && <ExpansionPanelCvm blockId="CVM" /> }

            { isBlockChecked(template.blocks, 'CERTIFICATES') && <ExpansionPanelCertificates blockId="CERTIFICATES" /> }

            { isBlockChecked(template.blocks, 'CERTIFICATES_TRF') && <ExpansionPanelCertificatesTRF blockId="CERTIFICATES_TRF" /> }

            { isBlockChecked(template.blocks, 'COURT_LAWSUITS') && <ExpansionPanelJudicialCriminalProcedures blockId="COURT_LAWSUITS" /> }

            { isBlockChecked(template.blocks, 'LABOR_COURTS') && <ExpansionPanelWorkCourts blockId="LABOR_COURTS" /> }

            { isBlockChecked(template.blocks, 'PROCESSES_PJE') && <ExpansionPanelProcessesPJE blockId="PROCESSES_PJE" /> }

            { isBlockChecked(template.blocks, 'PROCESSES_PJE_SECOND_INSTANCE') && <ExpansionPanelProcessesPJESecondInstance blockId="PROCESSES_PJE_SECOND_INSTANCE" /> }

            { isBlockChecked(template.blocks, 'PROCESSES_ESAJ') && <ExpansionPanelProcessesESAJ blockId="PROCESSES_ESAJ" /> }

            <ExpansionPanelCriminalAntecedentPC blockId="CRIMINAL_ANTECEDENTS_PC" />

            <ExpansionPanelCriminalAntecedentTJ blockId="CRIMINAL_ANTECEDENTS_TJ" />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(Dashboard)
