import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import {
  selectObjServicesCriminalAntecedentsPC,
} from 'redux/servicesRequested/selectors'

import ExpansionPanel from 'components/ExpansionPanel'
import CertidaoRow from 'components/CertidaoRow'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { blockCriminalAntecedentsPC } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'

import { addServicesToCall, removeServicesToCall, updateServicesToCall } from 'redux/servicesRequested/actions'

const ExpansionPanelCriminalAntecedentPC = ({ blockId }) => {
  const dispatch = useDispatch()

  const objServicesRequested = useSelector(selectObjServicesCriminalAntecedentsPC)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)

  const block = blockCriminalAntecedentsPC
  const blockServices = _
    .chain(block.services)
    // .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(servicesList.data, (val) => _.map(blockServices, 'service_id').includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const handlePanelChangeCheckbox = (isChecked) => {
    const filterList = _.filter(servicesList.data, (val) => {
      return _.isPlainObject(val) && !val.token
    })
    const objIds = _.chain(filterList).filter('objId').map('objId').value()

    if (isChecked === true) {
      const servicesIds = _.chain(filterList).reject('objId').map('service_id').value()
      if (!_.isEmpty(objIds)) dispatch(updateServicesToCall(objIds))
      if (!_.isEmpty(servicesIds)) dispatch(addServicesToCall(servicesIds))
    }
    else if (isChecked === false) dispatch(removeServicesToCall(objIds))
  }

  const handleServiceChangeCheckbox = (service) => (isChecked) => {
    if (isChecked === true) {
      if (!_.isEmpty(service.objId)) {
        dispatch(updateServicesToCall([service.objId]))
      } else {
        dispatch(addServicesToCall([service.service_id]))
      }
    }
    else if (isChecked === false) dispatch(removeServicesToCall([service.objId]))
  }

  const servicesCheckedsValues = _.reduce(servicesList.data, (result, val) => {
    if (val.token) return result
    return [ ...result, _.isPlainObject(val) && !!val.toCall]
  }, [])
  const allServicesCalled = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => !!val.token)

  const serviceSearchInfos = _.find(objServicesRequested, { service_id: 'search_infos_person_complete_v2' })
                          || _.find(objServicesRequested, { service_id: 'search_infos_person_complete' })
  const person = _.get(serviceSearchInfos, 'data.person')

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}

      onChangeCheckbox={handlePanelChangeCheckbox}
      checkboxChecked={(!_.isEmpty(servicesCheckedsValues) && servicesCheckedsValues.every((x) => x)) || allServicesCalled}
      checkboxIndeterminate={(new Set(servicesCheckedsValues)).size > 1}
      checkboxDisabled={_.isEmpty(person) || allServicesCalled}
      showCheckbox={true}
    >
      <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
        {_
          .chain(servicesList.data)
          .sortBy(['name'])
          .map((service) => {
            const serviceId = service.service_id
            const serviceData = service.data || {}

            return (
              <CertidaoRow
                key={(service.data_id || serviceId).toString()}
                title={service.name}
                consists={serviceData.consists}
                docUrl={serviceData.document_url}
                msg_errors={serviceData.msg_errors}
                msg_infos={serviceData.msg_infos}
                status={service.status}
                slowProcessing={service.slow_processing}
                loading={service.load}
                response_time={service.response_time || '59'}

                onChangeCheckbox={handleServiceChangeCheckbox(service)}
                checkboxChecked={!!service.toCall || !!service.token}
                checkboxDisabled={!_.isEmpty(service.status) || _.isEmpty(person)}
                showCheckbox={true}
              />
            )
          }).value()
        }
      </ul>
    </ExpansionPanel>
  )
}

export default ExpansionPanelCriminalAntecedentPC
