import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  paper:{
    padding: 15,
    borderRadius: 15,
    border: `solid 1px ${theme.palette.primary.grayLight}`,
    backgroundColor: theme.palette.primary.grayPaper,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonClear: {
    fontSize: 18,
    color: theme.palette.primary.black,
    border: 'none',
  },
}))

export default styles
