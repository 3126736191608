const mappedId = {
  address: 'Endereço',
  address_number: 'Número',
  applicant_city: 'Cidade do Requerente',
  applicant_complement: 'Complemento do Requerente',
  applicant_cpf_cnpj: 'CPF/CNPJ do Requerente',
  applicant_ddd: 'DDD do Requerente',
  applicant_email: 'Email do Requerente',
  applicant_name: 'Nome do Requerente',
  applicant_neighborhood: 'bairro do Requerente',
  applicant_number: 'Número do Requerente',
  applicant_phone: 'Telefone do Requerente',
  applicant_public_place: 'Logradouro do Requerente',
  applicant_type: 'Tipo do Requerente',
  applicant_uf: 'Estado do Requerente',
  applicant_zipcode: 'CEP do Requerente',
  birth_date: 'Data de Nascimento',
  certificate_model: 'Tipo da certidão',
  certificate_type: 'Tipo de Certificado',
  city: 'Cidade',
  civil_status: 'Estado Civil',
  cnpj: 'CNPJ',
  code: 'Código',
  code_identifier: 'Código Identificador',
  company_name: 'Nome da Empresa',
  comprehensiveness: 'Abrangência',
  country: 'País',
  cpf: 'CPF',
  cpf_cnpj: 'CPF/CNPJ',
  ddd: 'DDD',
  doc_code: 'Código do Documento',
  doc_type: 'Tipo de Documento',
  email: 'Email',
  father_name: 'Nome do Pai',
  filter: 'Filtro',
  full_name: 'Nome Completo',
  fullname: 'Nome Completo',
  gender: 'Gênero',
  judicial_district: 'Comarca',
  login: 'Login',
  mother_name: 'Nome da mãe',
  name: 'Nome',
  nationality: 'Nacionalidade',
  naturalness_city: 'Cidade que nasceu',
  naturalness_state: 'Estado que nasceu',
  neighborhood: 'Bairro',
  nfe: 'Nota Fiscal',
  nire: 'NIRE',
  number: 'Número',
  passport_number: 'Número do Passaporte',
  passport_serie: 'Série do Passaporte',
  password: 'Senha',
  person_type: 'Pessoa Física ou Jurídica',
  phone: 'Telefone',
  pis_identifier: 'Número do PIS',
  process_type: 'Tipo de Processo',
  protocol: 'Protocolo',
  protocol_identifier: 'Protocolo',
  q: 'Parâmetros da Busca (Ex: Nome, Processo, etc...)',
  query: 'Parâmetros da Busca',
  receipt_identifier: 'Número do Recibo',
  requested: 'Requerido',
  rg_expedition_date: 'Data de expedição do RG',
  rg_identifier: 'RG',
  rg_issuing_body: 'Órgão Emissor do RG',
  rg_issuing_body_state: 'UF do Órgão Emissor',
  rg_state: 'UF da Idendidade',
  rle_identifier: 'Identificador RLE',
  scope_search: 'Area de Busca',
  solicitation_situation: 'Situação da Solicitação',
  solicitation_type: 'Tipo de Solicitação',
  state: 'Estado',
  street: 'Rua',
  total_years: 'Quantidade de anos',
  type: 'Tipo',
  type_form1: 'Formulário Tipo 1',
  type_form2: 'Formulário Tipo 2',
  user: 'Usuário',
  user_login: 'Nome de Usuário',
  user_password: 'Senha',
  username: 'Nome de Usuário',
  year: 'Ano',
  zipcode: 'CEP',
}

export const translateKeyName = (key) => {
  return mappedId[key] || key
}
