export const SERVICES_REQUESTED_CLEAR = 'SERVICES_REQUESTED_CLEAR'
export const SERVICES_REQUESTED_CLEAR_SUCCEEDED = 'SERVICES_REQUESTED_CLEAR_SUCCEEDED'

export const POST_SERVICES = 'POST_SERVICES'
export const POST_SERVICES_FAILED = 'POST_SERVICES_FAILED'
export const POST_SERVICES_SUCCEEDED = 'POST_SERVICES_SUCCEEDED'

export const START_BACKGROUND_CHECK_SERVICES_STATUS = 'START_BACKGROUND_CHECK_SERVICES_STATUS'
export const STOP_BACKGROUND_CHECK_SERVICES_STATUS = 'STOP_BACKGROUND_CHECK_SERVICES_STATUS'
// ------------------

export const ADD_SERVICES_TO_CALL = 'ADD_SERVICES_TO_CALL'
export const ADD_SERVICES_TO_CALL_SUCCEEDED = 'ADD_SERVICES_TO_CALL_SUCCEEDED'
export const ADD_SERVICES_TO_CALL_FAILED = 'ADD_SERVICES_TO_CALL_FAILED'

export const REMOVE_SERVICES_TO_CALL = 'REMOVE_SERVICES_TO_CALL'
export const UPDATE_SERVICES_TO_CALL = 'UPDATE_SERVICES_TO_CALL'

export const SET_TYPE_ID = 'SET_TYPE_ID'
export const SET_VALUE = 'SET_VALUE'
export const SET_VALUE_SUCCEEDED = 'SET_VALUE_SUCCEEDED'

export const SELECT_ITEM = 'SELECT_ITEM'

export const ADD_INPUTS_IN_SERVICES = 'ADD_INPUTS_IN_SERVICES'

export const ADD_VAL_IN_INPUT_SERVICE = 'ADD_VAL_IN_INPUT_SERVICE'
export const ADD_VAL_IN_INPUT_SERVICE_SUCCEEDED = 'ADD_VAL_IN_INPUT_SERVICE_SUCCEEDED'

export const SERVICES_REQUESTED_GET_SERVICE = 'SERVICES_REQUESTED_GET_SERVICE'
export const SERVICES_REQUESTED_GET_SERVICE_SUCCEEDED = 'SERVICES_REQUESTED_GET_SERVICE_SUCCEEDED'
export const SERVICES_REQUESTED_GET_SERVICE_FAILED = 'SERVICES_REQUESTED_GET_SERVICE_FAILED'

export const SERVICES_REQUESTED_GET_LINKER_ANALYTICAL = 'SERVICES_REQUESTED_GET_LINKER_ANALYTICAL'
export const SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED = 'SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED'
export const SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_FAILED = 'SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_FAILED'

export const servicesRequestedClear = () => ({
  type: SERVICES_REQUESTED_CLEAR,
})

export const postServices = () => ({
  type: POST_SERVICES,
})

// ---------------------

export const addServicesToCall = servicesIds => ({
  type: ADD_SERVICES_TO_CALL,
  payload: servicesIds,
})

export const removeServicesToCall = objIds => ({
  type: REMOVE_SERVICES_TO_CALL,
  payload: objIds,
})

export const updateServicesToCall = objIds => ({
  type: UPDATE_SERVICES_TO_CALL,
  payload: objIds,
})

export const addValInInputServices = (objInputs) => ({
  type: ADD_VAL_IN_INPUT_SERVICE,
  payload: objInputs,
})

export const servicesRequestedGetService = ({ dataId }) => ({
  type: SERVICES_REQUESTED_GET_SERVICE,
  payload: { dataId: dataId },
})

export const checkServicesStatus = () => ({
  type: START_BACKGROUND_CHECK_SERVICES_STATUS,
})

export const stopCheckServicesStatus = () => ({
  type: STOP_BACKGROUND_CHECK_SERVICES_STATUS,
})

export const setTypeId = typeId => ({
  type: SET_TYPE_ID,
  payload: typeId,
})

export const setValue = value => ({
  type: SET_VALUE,
  payload: value,
})

export const selectItem = (typeId, docId, value) => ({ // TODO: remove arg value
  type: SELECT_ITEM,
  payload: { typeId: typeId, docId: docId, value: value },
})
