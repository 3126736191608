import { makeStyles } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    background:
      `linear-gradient(0deg, ${fade(theme.palette.primary.main, 0.8)}, ${fade(theme.palette.primary.main, 1)}), url(assets/images/background/bg-check.png)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    width: '40%',
    justifyContent: 'space-between',
    padding: 40,
    color: theme.palette.primary.lighter,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  },
  logo: {
    width: 300,
    borderRadius: 5,

    [theme.breakpoints.down('xs')]: {
      width: 230,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  main: {
    height: '100%',
    width: '60%',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      padding: 10,
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: 400,
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
  },
  buttonContainer: {
     width: 300,

    [theme.breakpoints.down('sm')]: {
       width: '100%',
    },
  },
  button: {
    width: 'inherit',
    height: '50px',
    borderRadius: 30,
    marginTop: '50px',
    marginBottom: 20,
    '&:hover':{
      backgroundColor: theme.palette.primary.hover,
    },
  },
  progress: {
    height: '25px !important',
    margin: theme.spacing(1),
    width: '25px !important',
  },
  noDisplay: {
    display: 'none',
  },
}))

export default styles
