const mappedId = {
  CADASTRAL_DATA: 'Dados Cadastrais',
  CADASTRAL_EXTENSION: 'Cadastro Estendido',
  PEP: 'PEP',
  CERTIFICATES: 'Certidões',
  CERTIFICATES_TRF: 'Certidões do TRF',
  COURT_LAWSUITS: 'Processos Judiciais e Criminais',
  CREDIT_HISTORY: 'Histórico de Credito',
  CRIMINAL_ANTECEDENTS_PC: 'Antecedentes Criminais da Polícia Civil',
  CRIMINAL_ANTECEDENTS_TJ: 'Antecedentes Criminais do Tribunal de Justiça',
  CVM: 'CVM - Comissão de Valores Mobiliários',
  LABOR_COURTS: 'Tribunais do Trabalho',
  NEGATIVE_MEDIA: 'Mídia Negativa',
  PROCESSES_ESAJ: 'Processos no ESAJ',
  PROCESSES_PJE: 'Processos no PJE 1º Instância',
  PROCESSES_PJE_SECOND_INSTANCE: 'Processos no PJE 2º Instância',
  PROTESTS_ACCOUNTS: 'Protestos e Contas',
}

export const translateTemplateBlockId = (key) => {
  return mappedId[key] || key
}
