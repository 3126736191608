import _ from 'lodash'
import constantsBlockServices from 'helpers/constants/blockServices'

export const findTemplateBlock = (blocks, blockId) => {
  return _.find(blocks, { block_id: blockId }) || {}
}

export const isBlockChecked = (blocks, blockId) => {
  return findTemplateBlock(blocks, blockId).checked
}

export const generateObjService = (serviceId, inputs = [], checked = false, show = false) => (
  {
    service_id: serviceId,
    checked: checked,
    inputs: inputs,
    show: show,
  }
)

const generateObjServices = (services, checked, show) => {
  return _.values(services).map((serviceId) => generateObjService(serviceId, [], checked, show))
}

export const blockCriminalAntecedentsPC = {
  block_id: 'CRIMINAL_ANTECEDENTS_PC',
  checked: false,
  checkboxDisabled: false,
  get services() {
    return generateObjServices(constantsBlockServices.criminalAntecedentsPC, this.checked, true)
  },
}

export const blockCriminalAntecedentsTJ = {
  block_id: 'CRIMINAL_ANTECEDENTS_TJ',
  checked: false,
  checkboxDisabled: false,
  get services() {
    return generateObjServices(constantsBlockServices.criminalAntecedentsTJ, this.checked, true)
  },
}

const defaultBlocks = [
  {
    block_id: 'CADASTRAL_DATA',
    checked: true,
    checkboxDisabled: true,
    get services() {
      return generateObjServices(constantsBlockServices.cadastralData, this.checked, true)
    },
  },
  {
    block_id: 'CADASTRAL_EXTENSION',
    checked: true,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.cadastralExtension, this.checked, false)
    },
  },
  {
    block_id: 'CREDIT_HISTORY',
    checked: true,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.creditHistory, this.checked, false)
    },
  },
  {
    block_id: 'PEP',
    checked: true,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.pep, this.checked, false)
    },
  },
  {
    block_id: 'NEGATIVE_MEDIA',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.negativeMedia, this.checked, false)
    },
  },
  {
    block_id: 'PROTESTS_ACCOUNTS',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.protestsAccounts, this.checked, true)
    },
  },
  {
    block_id: 'CVM',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.cvm, this.checked, true)
    },
  },
  {
    block_id: 'CERTIFICATES',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.certificates, this.checked, true)
    },
  },
  {
    block_id: 'CERTIFICATES_TRF',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.certificatesTRF, this.checked, true)
    },
  },
  {
    block_id: 'COURT_LAWSUITS',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.courtLawsuits, this.checked, true)
    },
  },
  {
    block_id: 'LABOR_COURTS',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.laborCourts, this.checked, true)
    },
  },
  {
    block_id: 'PROCESSES_PJE',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.processesPJE, this.checked, true)
    },
  },
  {
    block_id: 'PROCESSES_PJE_SECOND_INSTANCE',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.processesPJESecondInstance, this.checked, true)
    },
  },
  {
    block_id: 'PROCESSES_ESAJ',
    checked: false,
    checkboxDisabled: false,
    get services() {
      return generateObjServices(constantsBlockServices.processesESAJ, this.checked, true)
    },
  },
  // blockCriminalAntecedentsPC,
  // blockCriminalAntecedentsTJ,
]

export default defaultBlocks

export const getDefaultBlockServicesIds = (blockId) => {
  const block = findTemplateBlock(defaultBlocks, blockId)
  return _.map(block.services, (val) => val.service_id)
}

export const getTemplateAllCheckedServicesIds = (blocks) => {
  return _
    .chain(blocks)
    .reduce((result, val, _key) => [...result, ..._.values(val.services)], [])
    .reduce((result, val) => val.checked ? [...result, val.service_id] : result, [])
    .uniq()
    .value()
}

export const getTemplateAllCheckedServices = (blocks) => {
  return _
    .chain(blocks)
    .reduce((result, val, _key) => [...result, ..._.values(val.services)], [])
    .reduce((result, val) => val.checked ? [...result, val ] : result, [])
    .uniq()
    .value()
}
