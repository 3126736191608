import { makeStyles } from '@material-ui/styles'

const styles = makeStyles( () => ({
  column2:{
    paddingLeft: 20,
    overflowWrap: 'break-word',
    '@media(max-width: 600px)': {
      paddingLeft: 'auto',
      marginTop: -15,
    },
  },
}))

export default styles
