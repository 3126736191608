import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  partnersMsgProcessingContainer: {
    display: 'flex',
  },
  partnersMsgProcessing: {
    color: theme.palette.primary.grayLight2,
    fontSize: 18,
  },
}))

export default styles
