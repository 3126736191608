import React from 'react'
import classNames from 'classnames'
import {
  Checkbox,
  CircularProgress,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { useTheme, withStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProcessAccordion from 'components/ProcessAccordion'
import _ from 'lodash'
import TimeCounter from 'components/TimeCounter'

import styles from './styles'

const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid #cecece',
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    paddingLeft: 0,
  },
})(MuiExpansionPanelSummary)

const RowProcessBase = ({
  checkboxChecked,
  checkboxDisabled,
  consists,
  icon = true,
  loading,
  msgErrors,
  msgInfos,
  onChangeCheckbox,
  responseTime,
  showCheckbox = false,
  slowProcessing,
  status,
  title,

  subtitle,
  processes,
  totalProcesses,
  options,
}) => {
  const classes = styles()
  const theme = useTheme()

  function getColor() {
    if (status === 'PROCESSING') {
      return slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
    }
    if (status === 'PROCESSED') {
      if (consists) {
        return theme.palette.primary.red
      }
      if (msgErrors && msgErrors.length > 0) {
        return theme.palette.primary.grayLight2
      }
      if (msgInfos && msgInfos.length > 0) {
        return theme.palette.primary.grayLight2
      }
      return theme.palette.primary.green
    } if (status === 'ERROR') {
      return theme.palette.primary.red
    }

    return theme.palette.primary.grayLight
  }

  function renderLoading() {
    return loading && (
      <CircularProgress
        className={classes.progress}
        style={{
          width: 25,
          height: 25,
          marginTop: 5,
        }}
      />
    )
  }

  function renderSecondary() {
    if (msgErrors && msgErrors.length > 0) {
      return msgErrors.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (msgInfos && msgInfos.length > 0) {
      return msgInfos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (status === 'PROCESSING') {
      if (msgInfos && msgInfos.length > 0) {
        return msgInfos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
      }

      return (
        <TimeCounter responseTime={responseTime} />
      )
    }
    if (consists) {
      if (totalProcesses !== '0' && totalProcesses !== undefined && totalProcesses !== null){
        return (
          <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>{`Existem ${totalProcesses} ocorrências`}</span>
        )
      }
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Existe(m) ocorrência(s)</span>
      )
    }
    if (status === 'PROCESSED' && !consists) {
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Nenhuma ocorrência</span>
      )
    }
    return (
      <span />
    )
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={!_.isEmpty(processes) ? <ExpandMoreIcon /> : null}
        style={{ cursor: !_.isEmpty(processes) ? 'pointer' : 'default' }}
      >
        <ListItem
          classes={{ container: classes.listItemContainer }}
          className={classes.certiLine}
          style={{ color: getColor() }}
        >
          { showCheckbox && (
            <Checkbox
              checked={checkboxChecked}
              onChange={handleChangeCheckbox()}
              onClick={(e) => e.stopPropagation()}
              disabled={checkboxDisabled}
            />
          )}
          {icon === true && (
            <ListItemAvatar>
              <i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={<span style={{fontWeight: 600, fontSize: 17, display: 'block', color: getColor()}}>{title}</span>}
            secondary={renderSecondary()}
          />
          <ListItemSecondaryAction>
            {renderLoading()}
          </ListItemSecondaryAction>
        </ListItem>
      </ExpansionPanelSummary>

      <div style={{ marginLeft: 30 }}>
        {_.map(processes, (item, index) => (
          <ProcessAccordion
            key={index.toString()}
            title={`Processo ${index + 1}`}
            subtitle={subtitle}
          >
            <div style={{ marginLeft: 60 }}>
              {_.map(options.fields, (val, key) => (
                <p key={key}>
                  <strong>{val}</strong>
                  {': '}
                  {item[key]}
                </p>
              ))}
            </div>
          </ProcessAccordion>
        ))}
      </div>
    </ExpansionPanel>
  )
}

export default RowProcessBase
