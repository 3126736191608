import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { formatToCPF } from 'brazilian-values'
import { useTheme } from '@material-ui/styles'
import onlyNumbers from 'helpers/onlyNumbers'

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TablePagination,
} from '@material-ui/core'
import {
  Autorenew as AutorenewIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Keyboard as KeyboardIcon,
} from '@material-ui/icons'

import {
  checkServicesStatus,
  selectItem,
  servicesRequestedClear,
} from 'redux/servicesRequested/actions'

import {
  fetchListItems,
  listItemsClear,
  startBgFetchListItems,
  stopBgFetchListItems,
} from 'redux/listItems/actions'

import { MaskInput } from 'components/MaskedInput'
import ButtonDownloadReport from 'components/ButtonDownloadReport'
import styles from './styles'

const ListCpfCnpj = ({ typeId }) => {
  const classes = styles()
  const theme = useTheme()

  const dispatch = useDispatch()
  const listItems = useSelector(state => state.listItems)
  const docIdSelected = useSelector(state => state.servicesRequested.docIdSelected)

  const [filterText, setFilterText] = useState('')

  const callFilter = (options) => {
    dispatch(listItemsClear())
    dispatch(fetchListItems(typeId, options))
  }
  const [debouncedCallFilter] = useState(() => _.debounce(callFilter, 800))


  const handleLoadServiceInfos = async (docId, value) => {
    if (docId === docIdSelected) return

    await dispatch(servicesRequestedClear())
    await dispatch(selectItem(typeId, docId, value))
    await dispatch(checkServicesStatus())
  }

  useEffect(() => {
    dispatch(listItemsClear())
    dispatch(fetchListItems(typeId, { page: 1 }))
    dispatch(startBgFetchListItems())
  }, [dispatch, typeId])

  // unmount
  useEffect(() => {
    return () => {
      dispatch(stopBgFetchListItems())
    }
  }, [dispatch])

  const handleChangePageListItems = (_event, page) => {
    dispatch(listItemsClear())
    dispatch(fetchListItems(typeId, { page: page + 1, value: filterText }))
  }

  const handleInputFilterText = (event) => {
    const value = onlyNumbers(event.target.value)
    debouncedCallFilter({ value: onlyNumbers(value) })
    setFilterText(value)
  }

  const renderListItems = (docId) => {
    const cpfData = _.find(listItems.items, ['doc_id', docId])
    if (_.isEmpty(cpfData)) return null

    const someNeedInputs = !_.isEmpty(cpfData.service_infos) && _.some(cpfData.service_infos, (val, _key) => val.status === 'NEED_INPUTS')
    const someProcessing = !_.isEmpty(cpfData.service_infos) && _.some(cpfData.service_infos, (val, _key) => val.status === 'PROCESSING')
    const someSlowProcessing = !_.isEmpty(cpfData.service_infos) && _.some(cpfData.service_infos, (val, _key) => val.status === 'PROCESSING' && val.slow_processing === true)
    const someConsists =!_.isEmpty(cpfData.service_infos) && _.some(cpfData.service_infos, (val, _key) => val.consists === true)
    const allProcessed = !_.isEmpty(cpfData.service_infos) && _.every(cpfData.service_infos, (val, _key) => val.status === 'PROCESSED')

    let elemStatus = null
    if (someNeedInputs) {
      elemStatus = <KeyboardIcon fontSize="small" style={{ color: theme.palette.primary.gray }} />
    } else if (someProcessing) {
      elemStatus = <AutorenewIcon fontSize="small" style={{ color: someSlowProcessing ? theme.palette.primary.orange : theme.palette.primary.gray }} />
    } else if (someConsists) {
      elemStatus = <ErrorIcon fontSize="small" style={{ color: theme.palette.primary.red }} />
    } else if (allProcessed) {
      elemStatus = <CheckCircleIcon fontSize="small" style={{ color: theme.palette.primary.green }} />
    }

    return (
      <div className={classes.containerListItem}>
        <div style={{ width: '6.5rem', fontSize: '1.1rem' }}>
          {formatToCPF(cpfData.value)}
        </div>
        {elemStatus}
        <span style={{ marginLeft: 30 }}>{moment(cpfData.created_at).format('DD-MM-YYYY HH:mm')}</span>
      </div>
    )
  }

  const renderInputFilterText = () => (
    <MaskInput
      className={classes.inputFilterText}
      label="Buscar CPF"
      name="cpf"
      onChange={handleInputFilterText}
      value={filterText}
    />
  )

  return (
    <Card className={classes.paper} style={{ marginTop: 20 }}>
      <CardHeader className={classes.header} title={<span className={classes.headerText}>CPFs</span>} />
      <div className={classes.containerInputsFilter}>
        {renderInputFilterText()}
      </div>
      <Divider />
      <CardContent>
        <div className={classes.containerItemData}>
          {listItems.load && (
            <CircularProgress />
          )}
          <div>
            <List>
              {listItems.items.map((item, i) => (
                <ListItem
                  button
                  key={i.toString()}
                  disabled={item.doc_id === docIdSelected}
                  selected={item.doc_id === docIdSelected}
                  onClick={() => handleLoadServiceInfos(item.doc_id, item.value)}
                >
                  <ListItemText>{renderListItems(item.doc_id)}</ListItemText>

                  <ListItemSecondaryAction>
                    <ButtonDownloadReport
                      docId={item.doc_id}
                      value={item.value}
                      createdAt={item.created_at}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <TablePagination
              className={classes.listItemPagination}
              component="nav"
              page={listItems.currentPage - 1}
              rowsPerPage={listItems.perPage}
              rowsPerPageOptions={[]}
              count={listItems.total}
              onChangePage={handleChangePageListItems}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ListCpfCnpj
