import _ from 'lodash'

const mappedNotJoinInputs = {
  criminal_record_federal_police: {
    nationality: true,
    rg_issuing_body: true,
  },
  pc_ms_certificate_cpf: {
    rg_issuing_body: true,
  },
  pc_mt_certificate_cpf: {
    rg_issuing_body: true,
  },
  pc_pe_certificate_cpf: {
    civil_status: true,
    nationality: true,
    rg_issuing_body: true,
  },
  tj_ac_certificate_cpf: {
    certificate_model: true,
    judicial_district: true,
  },
  tj_al_certificate_cpf: {
    certificate_model: true,
    civil_status: true,
    nationality: true,
  },
  tj_am_certificate_cpf: {
    certificate_model: true,
    civil_status: true,
  },
  tj_ap_certificate_cpf: {
    certificate_model: true,
  },
  tj_df_certificate_cpf: {
    certificate_model: true,
    civil_status: true,
    nationality: true,
    rg_issuing_body: true,
  },
  tj_go_certificate_cpf: {
    judicial_district: true,
  },
  tj_ma_certificate_cpf: {
    certificate_model: true,
  },
  tj_ms_certificate_cpf: {
    judicial_district: true,
    certificate_model: true,
  },
  tj_pb_certificate_cpf: {
    certificate_model: true,
  },
  tj_pi_certificate_cpf: {
    certificate_model: true,
    civil_status: true,
    rg_issuing_body: true,
  },
  tj_rn_certificate_cpf: {
    certificate_model: true,
  },
  tj_ro_certificate_cpf: {
    certificate_model: true,
    judicial_district: true,
  },
  tj_rr_certificate_cpf: {
    certificate_model: true,
  },
  tj_rs_certificate_cpf: {
    certificate_model: true,
    rg_issuing_body: true,
  },
  tj_sc_certificate_cpf: {
    certificate_model: true,
    judicial_district: true,
  },
  tj_to_certificate_cpf: {
    certificate_model: true,
  },
  trf1_certificates_cpf: {
    certificate_model: true,
    coverage: true,
    issuing_body: true,
  },
  trf4_certificates_cpf: {
    certificate_model: true,
  },
}

export const canNotJoinInput = (serviceId, inputKey) => {
  return _.has(mappedNotJoinInputs, `${serviceId}.${inputKey}`)
}
