import { all } from 'redux-saga/effects'
import servicesRequestedSagas from './servicesRequested/sagas'
import templateConfigSagas from './templateConfig/sagas'
import listItemsSagas from './listItems/sagas'

export default function* rootSaga(getState) {
  yield all([
    listItemsSagas(),
    servicesRequestedSagas(),
    templateConfigSagas(),
  ])
}
