import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import './styles.css'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
 } from '@material-ui/core'

import { translateKeyName } from 'helpers/translateKeyName'
import { translateEnumName } from 'helpers/translateEnumName'
import styles from './styles'

const SelectCustom = ({ onChange, label, options, className, value }) => {
  const classes = styles()

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = event => {
    event.stopPropagation()
    if (_.isFunction(onChange)) onChange(event)
  }

  const renderOptions = () => {
    const elemOptions = options.map((option, i) => {
      return <option value={option} key={i.toString()}>{translateEnumName(option)}</option>
    })

    return [
      <option value={null} key="nulo" />,
      ...elemOptions,
    ]
  }

  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.formControl, className)}
    >
      <InputLabel ref={inputLabel} htmlFor="outlined-native-simple">
        {translateKeyName(label)}
      </InputLabel>
      <Select
        native
        onChange={handleChange}
        value={value}
        input={(
          <OutlinedInput
            labelWidth={labelWidth}
            id="outlined-native-simple"
          />
        )}
      >
        {Array.isArray(options) && renderOptions()}
      </Select>
    </FormControl>
  )
}

export default SelectCustom
