import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

import { permutationEnums } from 'helpers/functions'
import { translateEnumName } from 'helpers/translateEnumName'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { translateServiceName } from 'helpers/translateServicesInfos'
import servicesDocsConstants from 'helpers/constants/servicesDocs'
import { generateObjService } from 'helpers/constants/defaultTemplateConfigBlocks'

import { updateTemplateConfigBlocks } from 'redux/templateConfig/actions'
import styles from './styles'


const getInputDoc = (servicesDocs, serviceId, inputKey) => {
  return _
    .chain(servicesDocs)
    .get(`${serviceId}.inputs`)
    .pickBy((val) => !_.isEmpty(val.enum) && val.required === true)
    .find((_val, key) => key === inputKey)
    .value()
}

const checkAllServices = (services, checked) => {
  return _.map(services, (service) => ({ ...service, checked: checked }))
}

const generateServices = (services) => {
  return _
  .chain(services)
  .uniqBy('service_id')
  .reduce((result, service) => {
    let inputsEnums = _.map(service.inputs, (input) => {
      const inputDoc = getInputDoc(servicesDocsConstants, service.service_id, input.name)
      if (_.isEmpty(inputDoc.enum)) return {}
      return { name: input.name, values: inputDoc.enum }
    })
    inputsEnums = permutationEnums(inputsEnums)

    if (_.isEmpty(inputsEnums)) {
      return [ ...result, service ]
    }

    const newServices = _.map(inputsEnums, (inputsEnum) => {
      const serviceFind = _.find(
        _.filter(services, { service_id: service.service_id }),
        (serv) => _.isEqual(serv.inputs, inputsEnum)
      )

      if (_.isEmpty(serviceFind)) {
        return generateObjService(service.service_id, inputsEnum, false, true)
      }

      return serviceFind
    })

    return [ ...result, ...newServices ]
  }, [])
  .uniqWith((val, othVal) => val.service_id === othVal.service_id && _.isEqual(val.inputs, othVal.inputs))
  .value()
}

const TemplateConfig = () => {
  const classes = styles()

  const dispatch = useDispatch()
  const template = useSelector(state => state.templateConfig.template)

  const [blocks, setBlocks] = useState(template.blocks)

  const saveBlocks = useCallback((newBlocks) => {
    const clonedBlocks = _.cloneDeep(newBlocks)
    dispatch(updateTemplateConfigBlocks(clonedBlocks))
  }, [dispatch])

  useEffect(() => {
    const newBlocks = _.map(blocks, (block) => {
      const newBlock = { ...block }
      const newServices = generateServices(block.services)

      return { ...newBlock, services: newServices }
    })

    if (_.isEqual(newBlocks, blocks)) return

    setBlocks(newBlocks)
    saveBlocks(newBlocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBlocks])

  const handleCheckboxBlock = (blockId) => (e, checked) => {
    const newBlocks = _.map(blocks, (block) => {
      if (block.block_id !== blockId) return block

      const newBlock = { ...block, checked: checked }
      const newServices = checkAllServices(newBlock.services, checked)

      return { ...newBlock, services: newServices }
    })

    if (_.isEqual(newBlocks, blocks)) return

    setBlocks(newBlocks)
    saveBlocks(newBlocks)
  }

  const handleCheckboxBlockService = (blockId, serviceId, inputs) => (e, checked) => {
    const newBlocks = _.map(blocks, (block) => {
      if (block.block_id !== blockId) return block

      let newServices = null

      const serviceFind = _.find(
        block.services,
        (service) => service.service_id === serviceId && _.isEqual(service.inputs, inputs)
      )

      if (_.isEmpty(serviceFind)) {
        newServices = [ ...block.services, generateObjService(serviceId, inputs, checked, true) ]
      } else {
        newServices = _.map(block.services, (service) => {
          if (service.service_id !== serviceId) return service
          if (!_.isEqual(service.inputs, inputs)) return service

          return { ...service, checked: checked }
        })
      }

      const blockChecked = _.some(newServices, { checked: true })

      return { ...block, checked: blockChecked, services: newServices }
    })

    if (_.isEqual(newBlocks, blocks)) return

    setBlocks(newBlocks)
    saveBlocks(newBlocks)
  }

  const renderBlockService = (blockId, service, inputsEnum, label) => {
    if (service.service_id === 'search_infos_person_complete_v2') return null
    if (service.show !== true) return null

    return (
      <FormControlLabel
        key={label}
        control={(
          <Checkbox
            checked={service.checked}
            onChange={handleCheckboxBlockService(blockId, service.service_id, inputsEnum)}
            className={classes.checkboxService}
          />
        )}
        label={<strong>{label}</strong>}
      />
    )
  }

  const renderBlock = (block) => (
    <Fragment key={block.block_id}>
      <FormControlLabel
        disabled={block.checkboxDisabled}
        control={(
          <Checkbox
            checked={block.checked}
            onChange={handleCheckboxBlock(block.block_id)}
            className={classes.checkboxBlock}
            indeterminate={_.uniqBy(block.services, 'checked').length > 1}
          />
        )}
        label={<strong style={{ fontSize: 19, color: 'initial' }}>{translateTemplateBlockId(block.block_id)}</strong>}
      />
      {
        !_.isEmpty(block.services) && _.some(block.services, { show: true }) &&
        (
          <div className={classes.containerBlockServices}>
            {
              _
              .chain(block.services)
              .map((service) => [
                service,
                translateServiceName(service.service_id) + _.values(service.inputs).reduce((result, val) => `${result} - ${translateEnumName(val.value)}`, ''),
              ])
              .sortBy(([_service, label]) => label)
              .map(([service, label]) => renderBlockService(block.block_id, service, service.inputs, label))
              .value()
            }
          </div>
        )
      }
    </Fragment>
  )

  return (
    <div className={classes.container}>
      {
        _.map(blocks, (block, i) => (
          <div key={i.toString()}>
            {renderBlock(block)}
          </div>
          )
        )
      }
    </div>
  )
}

export default TemplateConfig
