import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { useTheme } from '@material-ui/styles'

import {
  selectObjServicesNegativeMedia,
} from 'redux/servicesRequested/selectors'

import TimeCounter from 'components/TimeCounter'
import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'

import styles from './styles'

const ExpansionPanelNegativeMedia = ({ blockId }) => {
  const theme = useTheme()
  const classes = styles()

  const objServicesRequested = useSelector(selectObjServicesNegativeMedia)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(servicesList.data, (val) => getDefaultBlockServicesIds(blockId).includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const renderNotice = (notice) => {
    return (
      <div className={classes.noticeContainer}>
        <hr className={classes.noticeHrSeparator} />
        <div>
          <strong>Título:</strong>
          {' '}
          <span>{notice.title}</span>
        </div>
        <div>
          <strong>Descrição:</strong>
          {' '}
          <span>{notice.description}</span>
        </div>
        <div>
          <strong>link:</strong>
          {' '}
          <a href={notice.link} target="_blank" rel="noopener noreferrer">Abrir</a>
        </div>
      </div>
    )
  }

  const renderNoticesType = (noticesType) => {
    const titles = {
      crimes_against_property: 'Crimes contra a propriedade',
      crimes_against_person: 'Crimes contra pessoa',
      crimes_against_honor: 'Crimes contra honra',
      crimes_against_public_adm: 'Crimes contra administração pública',
      crimes_against_sexual_dignity: 'Crimes contra a dignidade sexual',
      crimes_against_public_safety: 'Crimes contra a segurança pública',
      crimes_against_historic_property: 'Crimes contra bens históricos',
      crimes_against_justice_adm: 'Crimes contra a administração da justiça',
      economic_crimes: 'Crimes econômicos',
    }

    const noticesElem = _
    .chain(noticesType)
    .pickBy(x => !_.isEmpty(x))
    .map((v, k) => {
      return (
        <div key={k.toString()} className={classes.noticeTypeContainer}>
          <strong className={classes.noticeTypeTitle}>{`${titles[k]}: ${v.length}`}</strong>
          <div>{_.map(v, (x, i) => <div key={i.toString()}>{renderNotice(x)}</div>)}</div>
        </div>
      )
    })
    .value()

    return (<div>{noticesElem}</div>)
  }

  const renderNegativeMedia = () => {
    const service = _.find(servicesList.data, { service_id: 'negative_media' })

    if (_.isEmpty(_.get(service, 'objId'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
    }

    if (_.get(service, 'status') === 'PROCESSING') {
      if (_.get(service, 'slow_processing')) {
        const msgInfos = _.get(service, 'data.msg_infos')
        if (!_.isEmpty(msgInfos)) {
          return msgInfos.map(item => <p style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</p>)
        }
      }

      return <TimeCounter responseTime={_.get(service, 'response_time')} />
    }

    const data = _.get(service, 'data')

    if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
      return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
    }

    const msgErrors = _.get(data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      return msgErrors.map(item => <p key={item.msg}>{item.msg}</p>)
    }
    const msgInfos = _.get(data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      return msgInfos.map(item => <p key={item.msg}>{item.msg}</p>)
    }

    return (
      <div>
        <strong>Total de Notícias:</strong>
        {' '}
        <span>{_.get(data, 'total_notices')}</span>
        {_.get(data, 'total_notices') > 0 && renderNoticesType(data.notices)}
      </div>
    )
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
        {renderNegativeMedia()}
      </div>
    </ExpansionPanel>
  )
}

export default ExpansionPanelNegativeMedia
