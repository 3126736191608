import React from 'react'
import MUIDataTable from 'mui-datatables'

import defaultOptions from './defaultOptions'

const DataTable = (props) => {
  let options = props.options || {}
  options = { ...options, ...defaultOptions }

  return (
    <MUIDataTable
      {...props}
      options={options}
    />
  )
}

export default DataTable
