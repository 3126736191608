import React from 'react'
import * as Sentry from '@sentry/browser'
import { formatToCEP } from 'brazilian-values'

 export const columnsAddress = [
  {
   name: 'address',
   label: 'Endereço',
   options: {
    filter: true,
    sort: true,
   },
  },
  {
   name: 'neighborhood',
   label: 'Bairro',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
   name: 'city',
   label: 'Cidade/Estado',
   options: {
    filter: true,
    sort: false,
   },
  },
  {
   name: 'zipcode',
   label: 'CEP',
   options: {
    filter: true,
    sort: false,
    customBodyRender: (value) => {
      try {
        return value ? <div style={{ width: 60 }}>{formatToCEP(value)}</div> : null
      } catch (err) {
        Sentry.captureException(err)
        return false
      }
    },
   },
  },
 ]

 export const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  elevation: 0,
   responsive: 'scroll',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}
