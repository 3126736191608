import React from 'react'
import _ from 'lodash'

import TimeCounter from './TimeCounter'

export const renderCheckService = (service, theme) => {
  // TODO: deletar quando o projeto for refatorado
  if (_.isEmpty(_.get(service, 'objId'))) {
    return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
  }

  if (_.isEmpty(service) || _.isEmpty(_.get(service, 'data_id'))) {
    return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
  }

  // TODO: deletar quando o projeto for refatorado
  if (_.get(service, 'status') === 'NEED_INPUTS') {
    return <span style={{ color: theme.palette.primary.grayLight2 }}>Forneça os campos ao lado para solicitar</span>
  }

  if (_.get(service, 'status') === 'PROCESSING') {
    if (_.get(service, 'slow_processing')) {
      const msgInfos = _.get(service, 'data.msg_infos')
      if (!_.isEmpty(msgInfos)) {
        return msgInfos.map(item => (
          <span style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</span>
        ))
      }
    }

    return <TimeCounter responseTime={_.get(service, 'response_time')} />
  }

  const data = _.get(service, 'data')

  // TODO: deletar quando o projeto for refatorado
  if (_.isEmpty(data) && ['PROCESSED', 'ERROR'].includes(_.get(service, 'status'))) {
    return <span style={{ color: theme.palette.primary.grayLight2 }}>Carregando dados...</span>
  }

  const msgErrors = _.get(data, 'msg_errors')
  if (!_.isEmpty(msgErrors)) {
    return msgErrors.map(item => <span key={item.msg}>{item.msg}</span>)
  }
  const msgInfos = _.get(data, 'msg_infos')
  if (!_.isEmpty(msgInfos)) {
    return msgInfos.map(item => <span key={item.msg}>{item.msg}</span>)
  }

  return null
}
