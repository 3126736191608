const mappedId = {
  a: 'Artigos',
  civel: 'Civel',
  civel_1_grau: 'Civel do 1º grau',
  civel_2_grau: 'Civel do 2º grau',
  civel_e_criminal: 'Civel e Criminal',
  civel_e_criminal_1_grau: 'Civel e criminal do 1º grau',
  civel_e_criminal_2_grau: 'Civel e criminal do 2º grau',
  criminal: 'Criminal',
  criminal_1_grau: 'Criminal do 1º grau',
  criminal_2_grau: 'Criminal do 2º grau',
  d: 'Diario Oficial',
  distribution: 'Distribuição',
  electoral: 'Eleitoral',
  eleitoral: 'Eleitoral',
  eleitoral_1_grau: 'Eleitoral do 1º grau',
  eleitoral_2_grau: 'Eleitoral do 2º grau',
  en: 'Parte em Processo',
  i: 'Empresas',
  ms: 'MS',
  p: 'Pessoas',
  pa: 'Patentes',
  sp: 'SP',
  trf_3: 'TRF3',
}

export const translateEnumName = (key) => {
  return mappedId[key.toLowerCase()] || key
}
