/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import {
  Checkbox,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import TimeCounter from 'components/TimeCounter'
import ProcessAccordion from 'components/ProcessAccordion'
import DataTable from 'components/DataTable'
import moment from 'moment'
import { s3PresignerUrlOpen } from 'helpers/functions'

import styles from './styles'

const CertidaoRow = ({
  title,
  consists,
  docUrl,
  status,
  slowProcessing,
  loading,
  icon,
  otherInfo,
  options,
  columns,
  onChangeCheckbox,
  checkboxChecked,
  checkboxDisabled,
  showCheckbox = false,
  msg_errors,
  msg_infos,
  response_time,
}) => {
  const classes = styles()
  const theme = useTheme()

  function getColor() {
    if (status === 'PROCESSING') {
      return slowProcessing === true ? theme.palette.primary.orange : theme.palette.primary.grayDark
    }
    if (status === 'PROCESSED') {
      if (consists) {
        return theme.palette.primary.red
      }
      if (msg_errors && msg_errors.length > 0) {
        return theme.palette.primary.grayLight2
      }
      if (msg_infos && msg_infos.length > 0) {
        return theme.palette.primary.grayLight2
      }
      return theme.palette.primary.green
    } if (status === 'ERROR') {
      return theme.palette.primary.red
    }

    return theme.palette.primary.grayLight
  }

  function renderLoading() {
    return loading && (
      <CircularProgress
        className={classes.progress}
        style={{
          width: 25,
          height: 25,
          marginTop: 5,
        }}
      />
    )
  }

  function renderSecondary() {
    if (msg_errors && msg_errors.length > 0) {
      return msg_errors.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (msg_infos && msg_infos.length > 0) {
      return msg_infos.map(msg => <span key={msg.msg}>{msg.msg}</span>)
    }
    if (status === 'PROCESSING') {
      return (
        <TimeCounter responseTime={response_time} />
      )
    }
    if (consists) {
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Existe(m) ocorrência(s)</span>
      )
    }
    if (status === 'PROCESSED' && !consists) {
      return (
        <span style={{ color: getColor(), fontSize: 17, display: 'block' }}>Nenhuma ocorrência</span>
      )
    }
    return (
      <span />
    )
  }


  function downloadCertificate(url) {
    return window.open(url, '_blank')
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  return (
    <>
      <ListItem className={classes.certiLine} style={{color: getColor(), fontSize: 17}}>
        { showCheckbox && (
          <Checkbox
            checked={checkboxChecked}
            onChange={handleChangeCheckbox()}
            onClick={(e) => e.stopPropagation()}
            disabled={checkboxDisabled}
          />
        )}

        {icon !== false && (
          <ListItemAvatar>
            <i className={classNames(classes.icon, 'far', 'fa-file-pdf')} />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={<span style={{fontWeight: 600, fontSize: 17, display: 'block', color: getColor() }}>{title}</span>}
          secondary={renderSecondary()}
        />
        <ListItemSecondaryAction>
          {renderLoading()}

          {(docUrl && docUrl !== null && docUrl !== undefined) && (
            <IconButton
              edge="end"
              disabled={loading || !docUrl}
              onClick={() => s3PresignerUrlOpen(docUrl)}
            >
              <i
                className="fas fa-download"
                style={{ color: getColor() }}
              />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {!_.isEmpty(otherInfo) && (
      <ProcessAccordion
        title="Descrição da pendência"
      >
        <DataTable
          data={otherInfo}
          columns={columns}
          options={options}
        />

      </ProcessAccordion>
      )}
    </>
  )
}

export default CertidaoRow
