import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import {
  selectObjServicesCvm,
} from 'redux/servicesRequested/selectors'

import RowProcessBase from 'components/RowProcessBase'
import ExpansionPanel from 'components/ExpansionPanel'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'

const ExpansionPanelCvm = ({ blockId }) => {
  const objServicesRequested = useSelector(selectObjServicesCvm)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(servicesList.data, (val) => getDefaultBlockServicesIds(blockId).includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  const renderCvmAdmProcess = () => {
    const serviceId = 'cvm_administrative_process'
    const service = _.find(servicesList.data, { service_id: serviceId })
    if (_.isEmpty(service)) return null

    const serviceData = service.data || {}

    return (
      <RowProcessBase
        key={(service.data_id || serviceId).toString()}
        consists={serviceData.consists}
        loading={service.load}
        msgErrors={serviceData.msg_errors}
        msgInfos={serviceData.msg_infos}
        responseTime={service.response_time || '59'}
        slowProcessing={service.slow_processing}
        status={service.status}
        title={service.name}
        icon={false}
        totalProcesses={serviceData.total_processes}
        processes={serviceData.child_physical_process}
        options={{
          fields: {
            subject: 'Assunto',
            number: 'Número',
            applicant: 'Requerente',
            interested: 'Interessado',
          },
        }}
      />
    )
  }

  const renderCvmSanctioningAdmProcess = () => {
    const serviceId = 'cvm_sanctioning_administrative_process'
    const service = _.find(servicesList.data, { service_id: serviceId })
    if (_.isEmpty(service)) return null

    const serviceData = service.data || {}

    return (
      <RowProcessBase
        key={(service.data_id || serviceId).toString()}
        consists={serviceData.consists}
        loading={service.load}
        msgErrors={serviceData.msg_errors}
        msgInfos={serviceData.msg_infos}
        responseTime={service.response_time || '59'}
        slowProcessing={service.slow_processing}
        status={service.status}
        title={service.name}
        icon={false}
        totalProcesses={serviceData.total_processes}
        processes={serviceData.child_process}
        options={{
          fields: {
            subject: 'Assunto',
            number: 'Número',
            name: 'Acusado',
            menu: 'Ementa',
          },
        }}
      />
    )
  }

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
        {renderCvmAdmProcess()}
        {renderCvmSanctioningAdmProcess()}
      </div>
    </ExpansionPanel>
  )
}

export default ExpansionPanelCvm
