import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import {
  selectObjServicesProtestsAccounts,
} from 'redux/servicesRequested/selectors'

import ExpansionPanel from 'components/ExpansionPanel'
import CertidaoRow from 'components/CertidaoRow'
import { translateTemplateBlockId } from 'helpers/translateTemplateBlockId'
import { findTemplateBlock, getDefaultBlockServicesIds } from 'helpers/constants/defaultTemplateConfigBlocks'
import { translateService } from 'helpers/translateServicesInfos'
import { translateEnumName } from 'helpers/translateEnumName'
import { options, columns } from './configDataTable'

const ExpansionPanelProtestsAccounts = ({ blockId }) => {
  const objServicesRequested = useSelector(selectObjServicesProtestsAccounts)
  const servicesReqLoading = useSelector(state => state.servicesRequested.load)
  const template = useSelector(state => state.templateConfig.template)

  const block = findTemplateBlock(template.blocks, blockId)
  const blockServices = _
    .chain(block.services)
    .filter((service) => service.checked || _.find(objServicesRequested, (val) => val.service_id === service.service_id) )
    .map((val) => ({ ...translateService(val.service_id), service_id: val.service_id }))
    .value()

  const servicesList = { data: [] }

  servicesList.data = _.map(objServicesRequested, (val) => {
    const blockService = _.find(blockServices, { service_id: val.service_id })
    return { ...blockService, ...val }
  })
  _.forEach(blockServices, (val) => {
    const service = _.find(servicesList.data, { service_id: val.service_id })
    if (_.isEmpty(service)) servicesList.data.push(val)
  })
  servicesList.data = _.filter(servicesList.data, (val) => getDefaultBlockServicesIds(blockId).includes(val.service_id))

  // add enums in name
  servicesList.data = _.map(servicesList.data, (val) => {
    const compEnums = _
    .chain(val.inputs)
    .filter((input) => _.has(input, 'enum') && _.get(input, 'value'))
    .map((input) => translateEnumName(input.value))
    .join(', ')
    .value()

    return { ...val, name: `${val.name}${compEnums ? ` - ${compEnums}` : '' }` }
  })

  const consists = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.consists === true )
  const allProcessed = !_.isEmpty(servicesList.data) && _.every(servicesList.data, (val) => val.status === 'PROCESSED' )
  const someProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING')
  const someSlowProcessing = !_.isEmpty(servicesList.data) && _.some(servicesList.data, (val) => val.status === 'PROCESSING' && val.slow_processing === true)

  return (
    <ExpansionPanel
      title={translateTemplateBlockId(blockId)}
      loading={servicesReqLoading}
      consists={consists}
      processed={allProcessed}
      processing={someProcessing}
      slowProcessing={someSlowProcessing}
    >
      <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
        {_
          .chain(servicesList.data)
          .sortBy(['name'])
          .map((service) => {
            const serviceId = service.service_id
            const serviceData = service.data || {}
            let otherInfo = null

            if (serviceId === 'ieptb_protests_registry_cpf') {
              otherInfo = serviceData.registries
            } else {
              otherInfo = serviceData.processes || serviceData.infos || []
            }

            return (
              <CertidaoRow
                key={(service.data_id || serviceId).toString()}
                title={service.name}
                consists={serviceData.consists}
                docUrl={serviceData.document_url}
                msg_errors={serviceData.msg_errors}
                msg_infos={serviceData.msg_infos}
                status={service.status}
                slowProcessing={service.slow_processing}
                loading={service.load}
                response_time={service.response_time || '59'}
                otherInfo={otherInfo}
                options={options}
                columns={columns[serviceId]}
              />
            )
          }).value()
        }
      </ul>
    </ExpansionPanel>
  )
}

export default ExpansionPanelProtestsAccounts
