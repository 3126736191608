import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'

import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'
import { getCpfTemplate, postCpfTemplate, putCpfTemplate } from 'services/apiBgc'

import * as actions from './actions'
import { selectTemplate, selectFetched } from './saga.selectors'

function* fetchTemplateConfig() {
  yield takeEvery(actions.FETCH_TEMPLATE_CONFIG, function* sg(action) {
    const templateName = action.payload
    if (_.isEmpty(templateName)) return

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let resTemplate = null
    try {
      resTemplate = yield call(getCpfTemplate, apiKey, userId, templateName)
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao oter a configuração'
      yield put({ type: actions.FETCH_TEMPLATE_CONFIG_FAILED, payload: messageError })
      return
    }

    yield put({ type: actions.FETCH_TEMPLATE_CONFIG_SUCCEEDED, payload: resTemplate.data })
  })
}

function* saveTemplateConfig() {
  yield takeEvery(actions.SAVE_TEMPLATE_CONFIG, function* sg() {
    const template = yield selectTemplate()
    if (_.isEmpty(template) || _.isEmpty(template.blocks)) {
      yield put({ type: actions.SAVE_TEMPLATE_CONFIG_FAILED, payload: 'Não foi possível salvar a configuração' })
      return
    }

    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    try {
      // TODO: em vez de usar o fetched, checar se na lista myTemplateNames existe o template_name
      const fetched = yield selectFetched()
      if (fetched === true) {
        yield call(putCpfTemplate, apiKey, userId, template)
      } else {
        yield call(postCpfTemplate, apiKey, userId, template)
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao salvar a configuração'
      yield put({ type: actions.SAVE_TEMPLATE_CONFIG_FAILED, payload: messageError })
      return
    }

    yield put({ type: actions.SAVE_TEMPLATE_CONFIG_SUCCEEDED })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchTemplateConfig),
    fork(saveTemplateConfig),
  ])
}
