import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 20,
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}))
