import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import Dashboard from 'pages/Dashboard'
import Login from 'pages/Login'
import PasswordConfirmRecover from 'pages/PasswordConfirmRecover'
import PasswordRecover from 'pages/PasswordRecover'
import PasswordChange from 'pages/PasswordChange'

import PrivateRoute from './PrivateRoute'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/password-change" exact component={PasswordChange} />
      <Route path="/password-confirm-recover" exact component={PasswordConfirmRecover} />
      <Route path="/password-recover" exact component={PasswordRecover} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route path="*" component={() => <h1>Esta página não existe!</h1>} />
    </Switch>
  </BrowserRouter>
)

export default Routes
