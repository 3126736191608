import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import jwtDecode from 'jwt-decode'

import { reqS3PresignerUrl } from 'services/apiFilesDownloader'
import { getTemplateAllCheckedServices } from 'helpers/constants/defaultTemplateConfigBlocks'

function mergeCustomizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return _.union(objValue, srcValue)
  }
}

export const interssectObjects = (obj1, obj2) => {
  const obj2Keys = Object.keys(obj2)
  return Object.fromEntries( Object.entries(obj1).filter(([key, _val]) => obj2Keys.includes(key)) )
}

export const mergeComplex = (obj1, obj2) => {
  return _.mergeWith(obj1, obj2, mergeCustomizer)
}

export const pickByDeep = (obj, predicate) => {
  return Object.entries(obj).reduce((result, [key, val]) => {
    const newResult = result
    let newVal = val
    if (_.isPlainObject(newVal)) {
      newVal = pickByDeep(newVal, predicate)
    }
    if (predicate(newVal)) {
      newResult[key] = newVal
    }

    return newResult
  }, {})
}

export const s3PresignerUrlOpen = async doc_url => {
  if (!doc_url) return

  try {
    const response = await reqS3PresignerUrl(doc_url)

    window.open(response.data.url, '_blank')
  } catch (err) {
    Sentry.captureException(err)

    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }
  }
}

export const inputsValid = (inputs) => {
  if (_.isEmpty(inputs)) return false

  return Object.values(inputs).every((input) => {
    if (_.isEmpty(input.properties)) return input.valid === true

    return Object.values(input.properties).every((input) => input.valid === true)
  })
}

export const servicesStatusCounter = (services) => {
  return _.transform(services, (result, val, _key) => {
    const newResult = result
    if (val.status === 'PROCESSED') newResult.processed += 1
    else if (val.status === 'PROCESSING') newResult.processing += 1
    else if (false) newResult.consists += 1 // TODO: implementar a condição do if
  }, { processed: 0, processing: 0, consists: 0 })
}

// inputs: [ { name: input_name, values: [...] } ]
// return: [ { name: input_name, value: value } ]
export const permutationEnums = (inputs, currIndex = 0, result = []) => {
  if (inputs.length === 0) return []
  const { values, name } = inputs[currIndex]

  let newResult = []
  if (_.isEmpty(values)) {
    newResult = result
  } else if (_.isEmpty(result)) {
    newResult = values.map((value) => [ { name: name, value: value } ])
  } else {
    _.forEach(values, (value) => {
      _.forEach(result, (item) => {
        newResult.push([ ...item, { name: name, value: value } ])
      })
    })
  }

  if (currIndex === inputs.length - 1) return newResult
  return permutationEnums(inputs, currIndex + 1, newResult)
}

export const templateServicesNotInObjServices = (blocks, objServices) => {
  let servicesCheckeds = getTemplateAllCheckedServices(blocks)

  let objServicesInputs = _
  .chain(objServices)
  .map((val) => _.pick(val, ['service_id', 'inputs']))
  .value()

  objServicesInputs = _
  .chain(objServicesInputs)
  .map((val) => {
    const newInputs = _
    .chain(val.inputs)
    .pickBy(input => _.has(input, 'enum'))
    .map((input, key) => ({ name: key, value: input.value }))
    .value()

    return { ...val, inputs: newInputs }
  })
  .value()

  // remove v2 if v1 already exists
  const hasSearchInfoV1 = !_
    .chain(objServicesInputs)
    .find({ service_id: 'search_infos_person_complete' })
    .isEmpty()
    .value()

  if (hasSearchInfoV1) {
    servicesCheckeds = _.reject(servicesCheckeds, { service_id: 'search_infos_person_complete_v2' })
  }

  return _.filter(servicesCheckeds, (val) => {
    return !_.some(objServicesInputs, (objService) => {
      return _.isEqual(
        _.pick(val, ['service_id', 'inputs']),
        objService,
      )
    })
  })
}

export const getExtensionFileName = (fileName) => {
  const ext = fileName.split('.').pop()
  return ext.split(/[^0-9a-z-_]/i)[0]
}

export const sleep = m => new Promise(r => setTimeout(r, m))

const maskString = (value, pattern) => {
  const v = _.isEmpty(value) ? '' : value.toString()
  const count = (pattern.match(/#/g) || []).length
  if (count !== v.length) return v

  let i = 0
  return pattern.replace(/#/g, _x => v[i++])
}

export const maskProcess = (value) => maskString(value, '#######-##.####.#.##.####')

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const extractOnlyNumbers = (str) => {
  return _.isString(str) ? str.replace(/[^\d]/g, '') : ''
}
