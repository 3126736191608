import { combineReducers } from 'redux'
import { authReducer } from './auth/reducer'
import { listItemsReducer } from './listItems/reducer'
import { servicesRequestedReducer } from './servicesRequested/reducer'
import { templateConfigReducer } from './templateConfig/reducer'

export const Reducers = combineReducers({
  auth: authReducer,
  listItems: listItemsReducer,
  servicesRequested: servicesRequestedReducer,
  templateConfig: templateConfigReducer,
})
