import _ from 'lodash'
import moment from 'moment'
import onlyNumbers from 'helpers/onlyNumbers'
import { inputValid } from 'helpers/validateInputs'

import {
  POST_SERVICES,
  POST_SERVICES_FAILED,
  POST_SERVICES_SUCCEEDED,
  SERVICES_REQUESTED_CLEAR_SUCCEEDED,

  ADD_INPUTS_IN_SERVICES,
  ADD_SERVICES_TO_CALL,
  ADD_VAL_IN_INPUT_SERVICE_SUCCEEDED,
  REMOVE_SERVICES_TO_CALL,
  SELECT_ITEM,
  SERVICES_REQUESTED_GET_LINKER_ANALYTICAL,
  SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_FAILED,
  SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED,
  SERVICES_REQUESTED_GET_SERVICE,
  SERVICES_REQUESTED_GET_SERVICE_FAILED,
  SERVICES_REQUESTED_GET_SERVICE_SUCCEEDED,
  SET_TYPE_ID,
  SET_VALUE_SUCCEEDED,
  UPDATE_SERVICES_TO_CALL,
} from './actions'

const generateObjId = () => ((Math.random() * 10**20).toString())

// eslint-disable-next-line no-unused-vars
const initialStateService = {
  name: null,
  response_time: null,
  actived: false,
  service_type: {},
  inputs: {},
  token: null,
  status: null,
  load: false,
  fail: false,
  toCall: false,
}

const initialState = {
  fail: false,
  load: false,
  objServices: [],
  typeId: '',
  value: '',
  valueValid: false,
  docIdSelected: '',
  showing: false,
  messageError: '',
}

export const servicesRequestedReducer = (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case SERVICES_REQUESTED_CLEAR_SUCCEEDED: {
      return _.cloneDeep(initialState)
    }

    case SET_TYPE_ID: {
      return {
        ...state,
        typeId: action.payload,
      }
    }

    case SET_VALUE_SUCCEEDED: {
      return {
        ...state,
        value: onlyNumbers(action.payload.value),
        valueValid: action.payload.valueValid,
      }
    }

    case SELECT_ITEM: {
      const { typeId, docId, value } = action.payload // TODO: remover typeId e value

      return {
        ...state,
        fail: false,
        load: true,
        typeId: typeId,
        docIdSelected: docId,
        value: value,
        valueValid: true,
        showing: true,
      }
    }

    case POST_SERVICES: {
      return {
        ...state,
        fail: false,
        load: true,
        showing: true,
      }
    }
    case POST_SERVICES_SUCCEEDED: {
      return {
        ...state,
        fail: false,
        load: false,
        docIdSelected: action.payload.docId,
      }
    }
    case POST_SERVICES_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
        showing: false,
      }
    }

    case ADD_SERVICES_TO_CALL: {
      const servicesIds = action.payload

      const newObjServices = _.cloneDeep(state.objServices)
      servicesIds.forEach((serviceId) => {
        newObjServices.push({
          service_id: serviceId,
          toCall: true,
          objId: generateObjId(),
        })
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case UPDATE_SERVICES_TO_CALL: {
      const objIds = action.payload

      const newObjServices = _.cloneDeep(state.objServices)
      objIds.forEach((objId) => {
        const objService = _.find(newObjServices, { objId: objId })
        if (_.isEmpty(objService)) return

        objService.toCall = true
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case REMOVE_SERVICES_TO_CALL: {
      const objIds = action.payload

      const newObjServices = _.cloneDeep(state.objServices)
      objIds.forEach((objId) => {
        const objService = _.find(newObjServices, { objId: objId })
        if (_.isEmpty(objService)) return

        objService.toCall = false
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case ADD_INPUTS_IN_SERVICES: {
      const inputServices = action.payload

      const newObjServices = _.cloneDeep(state.objServices)

      _.forEach(inputServices, (val) => {
        const service = _.find(newObjServices, { objId: val.objId })
        if (_.isEmpty(service)) return

        service.inputs = _.merge(service.inputs, val.inputs)
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case ADD_VAL_IN_INPUT_SERVICE_SUCCEEDED: {
      const objInputs = action.payload

      const newObjServices = _.cloneDeep(state.objServices)

      _.forEach(objInputs, (objInput) => {
        const { objId, inputKey, value } = objInput

        const objService = _.find(newObjServices, { objId: objId })

        const inputNestedKey = inputKey.split('.').join('.properties.')
        const input = inputNestedKey.split('.').reduce((o , i) => o[i], objService.inputs)

        let valid = false
        if (_.isEmpty(value) && input.required !== true) {
          valid = true
        } else if (value) {
          valid = inputValid(inputKey, value)
        }

        input.value = value
        input.valid = valid
        input.updatedAt = moment()
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case SERVICES_REQUESTED_GET_SERVICE: {
      const { dataId } = action.payload

      const newObjServices = _.map(state.objServices, (val) => {
        if (val.data_id !== dataId) {
          return val
        }

        return {
          ...val,
          fail: false,
          load: true,
        }
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case SERVICES_REQUESTED_GET_SERVICE_SUCCEEDED: {
      const { dataId, data, status } = action.payload

      const newObjServices = _.map(state.objServices, (val) => {
        if (val.data_id !== dataId) {
          return val
        }

        return {
          ...val,
          data: data,
          fail: false,
          load: false,
          status: status,
        }
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }
    case SERVICES_REQUESTED_GET_SERVICE_FAILED: {
      const { dataId } = action.payload

      // TODO: melhorar implementação
      const newObjServices = _.map(state.objServices, (val) => {
        if (val.data_id !== dataId) {
          return val
        }

        return {
          ...val,
          fail: true,
          load: false,
        }
      })

      return {
        ...state,
        objServices: newObjServices,
      }
    }

    case SERVICES_REQUESTED_GET_LINKER_ANALYTICAL: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_SUCCEEDED: {
      const { objServices } = action.payload

      let newObjServices = _.values(
        _.merge(_.keyBy(state.objServices, 'data_id'), _.keyBy(objServices, 'data_id'))
      )

      newObjServices = _.filter(newObjServices, (objService) => {
        if (objService.toCall === true && _.map(objServices, 'service_id').includes(objService.service_id)) {
          return false
        }

        return true
      })

      // add objId
      newObjServices = _.map(newObjServices, (val) => {
        if (!_.isEmpty(val.objId)) return val

        return { ...val, objId: generateObjId() }
      })

      return {
        ...state,
        fail: false,
        load: false,
        objServices: newObjServices,
      }
    }
    case SERVICES_REQUESTED_GET_LINKER_ANALYTICAL_FAILED: {
      // TODO: implementar
      return {
        ...state,
        fail: true,
        load: false,
      }
    }

    default:
      return state
  }
}

export default servicesRequestedReducer
