import _ from 'lodash'

const mappedId = {
  cadastral_situation_cpf: {
    actived: true,
    name: 'Comprovante de Situação Cadastral',
    response_time: '40',
  },
  ceis_infos_government_base_cpf: {
    actived: true,
    name: 'Informações de Pessoas Físicas no Cadastro de Empresas Inidôneas e Suspensas',
    response_time: '180',
  },
  certificate_tax_debts_federal_cpf: {
    actived: true,
    name: 'Certidão de Débitos Tributários Federais e da Dívida Ativa da União de CPF',
    response_time: '180',
  },
  certificate_tax_debts_sp_cpf: {
    actived: true,
    name: 'Certidão Negativa de Débitos Tributários de SP',
    response_time: '180',
  },
  cnep_infos_government_base_cpf: {
    actived: true,
    name: 'Informações de Pessoa Física no Cadastro Nacional de Empresas Punidas',
    response_time: '180',
  },
  cnpj_infos_federal_revenue: {
    actived: true,
    name: 'Informações do CNPJ na Receita Federal',
    response_time: '180',
  },
  membership_board: {
    actived: true,
    name: 'Consulta Quadro Societário de um CNPJ e retorna o CPF dos membros quando encontrado',
    response_time: '120',
  },
  court_warrant_bnmp: {
    actived: true,
    name: 'Mandados judiciais do BNMP (pode haver homônimos)',
    response_time: '180',
  },
  court_warrant_bnmp_cpf: {
    actived: true,
    name: 'Mandados judiciais do BNMP',
    response_time: '180',
  },
  criminal_record_federal_police: {
    actived: true,
    name: 'Certidão de antecedentes criminais da PF',
    response_time: '180',
  },
  electoralsituation_by_name: {
    actived: true,
    name: 'Consulta da Situação eleitoral pelo nome (pode haver homônimos)',
    response_time: '180',
  },
  ieptb_protests_registry_cpf: {
    actived: true,
    name: 'Protestos encontrados no IEPTB',
    response_time: '180',
  },
  irpf_refund_cpf: {
    actived: true,
    name: 'Consulta Restituições IRPF',
    response_time: '180',
  },
  irregular_accounts_cpf: {
    actived: true,
    name: 'Contas Irregulares de Pessoa Física',
    response_time: '60',
  },
  irregular_bidders_cpf: {
    actived: true,
    name: 'Licitantes Inidôneos Pessoa Física',
    response_time: '60',
  },
  irregular_electoral_accounts_cpf: {
    actived: true,
    name: 'Contas Irregulares Eleitorais de Pessos Física',
    response_time: '60',
  },
  military_action_stm: {
    actived: true,
    name: 'Certidão de ações criminais militar do STM',
    response_time: '180',
  },
  mpf_extrajudicial_procedures_cpf: {
    actived: true,
    name: 'Certidão negativa de procedimentos extrajudiciais do MPF',
    response_time: '180',
  },
  negative_certificate_irregular_accounts_cpf: {
    actived: true,
    name: 'Certidão negativa de contas irregulares',
    response_time: '180',
  },
  patr_calc_cpf: {
    actived: true,
    name: 'Cálculo de Patrimônio',
    response_time: '70',
  },
  pc_ba_certificate_cpf: {
    actived: true,
    name: 'BA (Polícia Cívil)',
    response_time: '180',
  },
  pc_es_certificate_cpf: {
    actived: true,
    name: 'ES (Polícia Cívil)',
    response_time: '40',
  },
  pc_mg_certificate_cpf: {
    actived: true,
    name: 'MG (Polícia Cívil)',
    response_time: '40',
  },
  pc_ms_certificate_cpf: {
    actived: true,
    name: 'MS (Polícia Cívil)',
    response_time: '40',
  },
  pc_mt_certificate_cpf: {
    actived: false,
    name: 'MT (Polícia Cívil)',
    response_time: '40',
  },
  pc_pa_certificate_cpf: {
    actived: true,
    name: 'PA (Polícia Cívil)',
    response_time: '40',
  },
  pc_pe_certificate_cpf: {
    actived: true,
    name: 'PE (Polícia Cívil)',
    response_time: '40',
  },
  pc_pr_certificate_cpf: {
    actived: true,
    name: 'PR (Polícia Cívil)',
    response_time: '86400',
  },
  pc_rj_certificate_cpf: {
    actived: true,
    name: 'RJ (Polícia Cívil)',
    response_time: '40',
  },
  pc_rs_certificate_cpf: {
    actived: true,
    name: 'RS (Polícia Cívil)',
    response_time: '180',
  },
  pc_se_certificate_cpf: {
    actived: true,
    name: 'SE (Polícia Cívil)',
    response_time: '40',
  },
  pc_sp_certificate_cpf: {
    actived: true,
    name: 'SP (Polícia Cívil)',
    response_time: '40',
  },
  pep_infos_government_base: {
    actived: true,
    name: 'PEP',
    response_time: '10',
  },
  pep_search_v2: {
    actived: true,
    name: 'PEP',
    response_time: '120',
  },
  sanctions_list: {
    actived: true,
    name: 'OFAC (pode haver homônimos)',
    response_time: '30',
  },
  search_infos_person_complete: {
    actived: true,
    name: 'Informações completas de pessoa física',
    response_time: '10',
  },
  search_infos_person_complete_v2: {
    actived: true,
    name: 'Informações completas de pessoa física',
    response_time: '10',
  },
  sit_certificate_cpf: {
    actived: true,
    name: 'Certidão de Débitos Trabalhistas do SIT',
    response_time: '180',
  },
  social_assistance_person: {
    actived: true,
    name: 'Benefícios e Assistência Social',
    response_time: '10',
  },
  summary_of_processes: {
    actived: true,
    name: 'Processos em',
    response_time: '180',
  },
  tj_ac_certificate_cpf: {
    actived: true,
    name: 'AC (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_al_certificate_cpf: {
    actived: true,
    name: 'AL (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_am_certificate_cpf: {
    actived: true,
    name: 'AM (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_ap_certificate_cpf: {
    actived: true,
    name: 'AP (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_ba_certificate_cpf: {
    actived: true,
    name: 'BA (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_ce_certificate_cpf: {
    actived: true,
    name: 'CE (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_df_certificate_cpf: {
    actived: true,
    name: 'DF (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_go_certificate_cpf: {
    actived: true,
    name: 'GO (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_ma_certificate_cpf: {
    actived: true,
    name: 'MA (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_ms_certificate_cpf: {
    actived: true,
    name: 'MS (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_pb_certificate_cpf: {
    actived: true,
    name: 'PB (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_pi_certificate_cpf: {
    actived: true,
    name: 'PI (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_rn_certificate_cpf: {
    actived: true,
    name: 'RN (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_ro_certificate_cpf: {
    actived: true,
    name: 'RO (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_rr_certificate_cpf: {
    actived: true,
    name: 'RR (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_rs_certificate_cpf: {
    actived: true,
    name: 'RS (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_sc_certificate_cpf: {
    actived: true,
    name: 'SC (Tribunal de Justiça)',
    response_time: '180',
  },
  tj_se_certificate_cpf: {
    actived: true,
    name: 'SE (Tribunal de Justiça)',
    response_time: '40',
  },
  tj_to_certificate_cpf: {
    actived: true,
    name: 'TO (Tribunal de Justiça)',
    response_time: '40',
  },
  trf1_certificates_cpf: {
    actived: true,
    name: 'Certidão TRF1',
    response_time: '180',
  },
  trf2_certificates_cpf: {
    actived: true,
    name: 'Certidão TRF2',
    response_time: '180',
  },
  trf2_certificates_sjes_cpf: {
    actived: true,
    name: 'Certidão TRF2 - SJ Espírito Santo',
    response_time: '180',
  },
  trf2_certificates_sjrj_cpf: {
    actived: true,
    name: 'Certidão TRF2 - SJ Rio de Janeiro',
    response_time: '180',
  },
  trf3_certificates_cpf: {
    actived: true,
    name: 'Certidão TRF3',
    response_time: '180',
  },
  trf4_certificates_cpf: {
    actived: true,
    name: 'Certidão TRF4',
    response_time: '90',
  },
  trf5_certificates_criminal_cpf: {
    actived: true,
    name: 'Certidão TRF5 - Criminal',
    response_time: '180',
  },
  trf5_certificates_electoral_cpf: {
    actived: true,
    name: 'Certidão TRF5 - Eleitoral',
    response_time: '180',
  },
  trf5_certificates_distribution_cpf: {
    actived: true,
    name: 'Certidão TRF5 - Distribuição',
    response_time: '180',
  },
  trt2_sp_certificate_cpf: {
    actived: true,
    name: 'Certidão de ações trabalhistas do TRT2 SP',
    response_time: '180',
  },
  tst_certificate_cpf: {
    actived: true,
    name: 'Certidão de débito trabalhista do TST',
    response_time: '180',
  },
  unabled_people_cpf: {
    actived: true,
    name: 'Relação de Inabilitados',
    response_time: '60',
  },
  unemployment_insurance_by_pis: {
    actived: true,
    name: 'Consulta do Seguro-Desemprego',
    response_time: '180',
  },
  work_info_rais_base: {
    actived: true,
    name: 'Vinculos empregativos formais.',
    response_time: '180',
  },
  pje_ba_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-BA 1º instância',
    response_time: '180',
  },
  pje_df_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-DF 1º instância',
    response_time: '180',
  },
  pje_ma_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-MA 1º instância',
    response_time: '180',
  },
  pje_mg_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-MG 1º instância',
    response_time: '180',
  },
  pje_mt_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-MT 1º instância',
    response_time: '180',
  },
  pje_pb_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-PB 1º instância',
    response_time: '180',
  },
  pje_pe_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-PE 1º instância',
    response_time: '180',
  },
  pje_pi_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-PI 1º instância',
    response_time: '180',
  },
  pje_pr_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-PR 1º instância',
    response_time: '180',
  },
  pje_ro_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-RO 1º instância',
    response_time: '180',
  },
  pje_rs_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-RS 1º instância',
    response_time: '180',
  },
  pje_se_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-SE 1º instância',
    response_time: '180',
  },
  pje_ce_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-CE 1º instância',
    response_time: '180',
  },
  pje_es_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-ES 1º instância',
    response_time: '180',
  },
  pje_rr_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-RR 1º instância',
    response_time: '180',
  },
  pje_rn_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-RN 1º instância',
    response_time: '180',
  },
  pje_pa_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-PA 1º instância',
    response_time: '180',
  },
  pje_rj_process_first_instance_cpf: {
    actived: true,
    name: 'PJE-RJ 1º instância',
    response_time: '180',
  },
  pje_ba_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-BA 2º instância',
    response_time: '180',
  },
  pje_ce_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-CE 2º instância',
    response_time: '180',
  },
  pje_df_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-DF 2º instância',
    response_time: '180',
  },
  pje_es_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-ES 2º instância',
    response_time: '180',
  },
  pje_ma_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-MA 2º instância',
    response_time: '180',
  },
  pje_mg_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-MG 2º instância',
    response_time: '180',
  },
  pje_mt_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-MT 2º instância',
    response_time: '180',
  },
  pje_pb_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-PB 2º instância',
    response_time: '180',
  },
  pje_pe_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-PE 2º instância',
    response_time: '180',
  },
  pje_pi_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-PI 2º instância',
    response_time: '180',
  },
  pje_rj_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-RJ 2º instância',
    response_time: '180',
  },
  pje_rn_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-RN 2º instância',
    response_time: '180',
  },
  pje_ro_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-RO 2º instância',
    response_time: '180',
  },
  pje_rr_process_second_instance_cpf: {
    actived: true,
    name: 'PJE-RR 2º instância',
    response_time: '180',
  },
  tj_sp_process_name: {
    actived: true,
    name: 'ESAJ-SP (pode haver homônimos)',
    response_time: '180',
  },
  onu_sanction_lists: {
    actived: true,
    name: 'Consta na ONU (pode haver homônimos)',
    response_time: '10',
  },
  ceaf_infos_government_base: {
    actived: true,
    name: 'CEAF',
    response_time: '10',
  },
  red_notice_interpol: {
    actived: true,
    name: 'Foragido da Interpol (pode haver homônimos)',
    response_time: '10',
  },
  bacen_qgi_cpf: {
    actived: true,
    name: 'Bacen QGI',
    response_time: '10',
  },
  eu_sanction_list: {
    actived: true,
    name: 'Sanções da UE (pode haver homônimos)',
    response_time: '10',
  },
  uk_sanction_list: {
    actived: true,
    name: 'Sanções da UK (pode haver homônimos)',
    response_time: '10',
  },
  fr_sanction_list: {
    actived: true,
    name: 'Sanções da França (pode haver homônimos)',
    response_time: '10',
  },
  us_nonproliferation_sanction_list: {
    actived: true,
    name: 'Sanções de não-proliferação dos EUA (pode haver homônimos)',
    response_time: '10',
  },
  world_bank_ineligible_sanction_list: {
    actived: true,
    name: 'Inelegível no Banco Mundial (pode haver homônimos)',
    response_time: '10',
  },
  work_slave_cpf: {
    actived: true,
    name: 'Submetido trabalhadores a condições análogas à de escravo MTE',
    response_time: '10',
  },
  stf_process_name: {
    actived: true,
    name: 'Processos no STF (pode haver homônimos)',
    response_time: '90',
  },
  stj_process_name: {
    actived: true,
    name: 'Processos no STJ (pode haver homônimos)',
    response_time: '30',
  },
  administrative_improbity_cpf: {
    actived: true,
    name: 'Certidão negativa de ato de improbidade e inelegibilidade CNJ',
    response_time: '40',
  },
  negative_media: {
    actived: true,
    name: 'Busca por noticias negativas relacionadas ao nome de uma pessoa (pode haver homônimos)',
    response_time: '20',
  },
  cvm_administrative_process: {
    actived: true,
    name: 'Processo Administrativo - CVM (pode haver homônimos)',
    response_time: '10',
  },
  cvm_sanctioning_administrative_process: {
    actived: true,
    name: 'Processo Administrativo Sancionador - CVM (pode haver homônimos)',
    response_time: '10',
  },
  pis_number: {
    actived: true,
    name: 'Obtem o número do PIS',
    response_time: '180',
  },
}

export const translateService = (serviceId) => {
  return _.get(mappedId, `${serviceId}`) || {}
}

export const translateServiceName = (serviceId) => {
  return _.get(mappedId, `${serviceId}.name`) || serviceId
}

export const translateServiceActived = (serviceId) => {
  return _.get(mappedId, `${serviceId}.actived`) || true
}

export const translateServiceResponseTime = (serviceId) => {
  return _.get(mappedId, `${serviceId}.response_time`) || '180'
}

export const getAllServices = () => {
  return mappedId
}
