import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  icon: {
    fontSize: 30,
    marginRight: 15,
  },
  certiLine: {
    fontSize: 17,
    borderTop: `1px solid ${theme.palette.primary.grayLight}`,
    paddingTop: 10,
  },
  progress: {
    margin: theme.spacing(1),
  },
}))

export default styles
