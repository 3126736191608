import _ from 'lodash'
import { dateValid, fullnameValid, pisValid } from 'helpers/validators'
import onlyNumbers from 'helpers/onlyNumbers'

const searchInfosInputs = {
  birth_date: { mappedKey: 'birth_date', sanitizer: null, validator: dateValid },
  filiation: { mappedKey: 'mother_name', sanitizer: null, validator: fullnameValid },
  name: { mappedKey: 'name', sanitizer: null, validator: fullnameValid },
  pis_number: { mappedKey: 'pis_identifier', sanitizer: onlyNumbers, validator: pisValid },
}

const populateInputs = {
  search_infos_person_complete: searchInfosInputs,
  search_infos_person_complete_v2: searchInfosInputs,
}

export const populateInput = (objServices, inputKey) => {
  const [ serviceId, originalKey ] = _.transform(populateInputs, (result, val, servId) => {
    const origKey = _.findKey(val, (v, _k) => v.mappedKey === inputKey)
    if (origKey) {
      result.push(servId)
      result.push(origKey)
      result.push(val)
      return false
    }

    return null
  }, [])

  let value = _.get(_.find(objServices, { service_id: serviceId }), `data.person.${originalKey}`)

  const objKey = _.get(populateInputs, `${serviceId}.${originalKey}`)
  if (_.isEmpty(objKey)) return null

  if (_.isFunction(objKey.sanitizer)) value = objKey.sanitizer(value)
  if (_.isFunction(objKey.validator) && !objKey.validator(value)) return null

  return value
}
