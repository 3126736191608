import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    padding: 20,
    background: theme.palette.primary.bg,
    height: '100%',
  },
  paper:{
    padding: 15,
    borderRadius: 15,
    border: `solid 1px ${theme.palette.primary.grayLight}`,
    backgroundColor: theme.palette.primary.grayPaper,
  },
  expansion: {
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  subtitle:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chip: {
    margin: theme.spacing(1),
  },
  progress:{
    margin: theme.spacing(1),
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    marginBottom: 30,
  },
  download: {
    marginLeft: 10,
  },
  LineAddress:{
    minWidth: 200,
  },
  LineVehicle:{
    minWidth: 150,
  },
  icon:{
    fontSize: 30,
    marginRight: 15,
  },
  certiLine:{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'green',
    fontSize: 17,
    borderTop: `1px solid ${theme.palette.primary.grayLight}`,
    paddingTop: 10,
  },
  certiTitle:{
    fontSize: 17,
    fontWeight: 600,
  },
  button:{
    backgroundColor: 'transparent',
    fontSize: 20,
    color: 'green',
    border: 'none',
  },
  buttonAction: {
    fontSize: 18,
    color: theme.palette.primary.black,
    border: 'none',
    marginBottom: 10,
  },
}))

export default styles
